/* tslint:disable:member-ordering */

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    EMPTY,
    throwError,
} from 'rxjs';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    NymcardCmsWalletInternal,
    NymcardsWalletInternalService,
    NymcardWalletCreateRequest,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    Texts,
    Wallet,
} from '@nymos/accounts/core';

import {
    FailFromApi,
    LoadWalletsFromAgentDetailsPage,
    LoadWalletsFromUserDetailsPage,
    RefreshWalletsFromAgentDetailsPage,
    RefreshWalletsFromUserDetailsPage,
    CreateIqdWallet,
    CreateIqdAgentWallet
} from './wallets.actions';

import {
    Notifications,
} from './wallets.notifications';

import {
    WalletBuilder,
} from '../../builders/wallet.builder';


export interface WalletsStateModel {
    items: { [id: string]: Wallet };
    loading: boolean;
    saving: boolean;
    problem: Problem;
    next: SearchCriteria;
}

const stateDefaults: WalletsStateModel = {
    items: {},
    loading: undefined,
    saving: undefined,
    problem: undefined,
    next: undefined,
};

@State<WalletsStateModel>({
    name: 'wallets',
    defaults: stateDefaults,
})
export class WalletsState {

    @Selector()
    public static wallets(state: WalletsStateModel): Wallet[] {
        const result = Object.keys(state.items).map(((id) => state.items[id]));
        return result;
    }

    @Selector()
    // Wallet Information
    public static mainWallet(state: WalletsStateModel): Wallet {
        const result = Object.keys(state.items).map(((id) => state.items[id]));
        const usdWallet = result.filter((walletType)=>{ return walletType.balances[0].currency.toUpperCase() == 'USD' && (walletType.type =='user' || walletType.type =='agent')})
        const iqdWallet =  result.filter((walletType)=>{ return walletType.balances[0].currency.toUpperCase() == 'IQD' && (walletType.type =='user' || walletType.type =='agent')})
        usdWallet[0]['IqdBallance'] = iqdWallet[0];
        return usdWallet[0];
    }

    public static wallet(id: string): any {
        return createSelector([WalletsState], (state: WalletsStateModel) => {
            return state.items[id];
        });
    }

    @Selector()
    public static problem(state: WalletsStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: WalletsStateModel): boolean {
        return state.loading;
    }

    constructor(
        private _nc: NotificationCenter,
        private _walletService: NymcardsWalletInternalService,
    ) { }

    @Action(SignOut)
    public reset(ctx: StateContext<WalletsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(LoadWalletsFromUserDetailsPage)
    @Action(LoadWalletsFromAgentDetailsPage)
    public loadWallets(ctx: StateContext<WalletsStateModel>, { accountId }: any): any {
        ctx.setState({ ...stateDefaults, loading: true });
        return this._loadWallets(ctx, accountId).pipe(

            catchError(() => {
                // this._nc.show(Notifications.Failure);
                return EMPTY;
            }),

            finalize(() => { ctx.patchState({ loading: false }); }),
        );
    }

    @Action(RefreshWalletsFromUserDetailsPage)
    @Action(RefreshWalletsFromAgentDetailsPage)
    public refreshWallets(ctx: StateContext<WalletsStateModel>, { accountId }: any): any {
        return this._loadWallets(ctx, accountId).pipe(
            catchError(() => {
                return EMPTY;
            }),
        );
    }

    @Action(FailFromApi)
    public fail(ctx: StateContext<WalletsStateModel>, { payload: { problem } }: FailFromApi): void {
        problem.detail = Texts.WalletAction.LoadingFailure;
        ctx.patchState({ problem: problem });
    }

    private _loadWallets(ctx: StateContext<WalletsStateModel>, accountId: string): any {
        return this._walletService.loadWallets(accountId).pipe(
            tap((cards: NymcardCmsWalletInternal[]) => {
                ctx.setState(produce((draft) => {
                    draft.items = {};
                    cards.forEach((internal) =>
                        draft.items[internal.internalId] = new WalletBuilder().withInternal(internal).build(),
                    );
                }));
            }),

            catchError((problem) => {
                ctx.dispatch(new FailFromApi({ problem: problem }));
                return throwError(problem);
            }),
        );
    }
    @Action(CreateIqdWallet)
    public walletIqd(ctx: StateContext<WalletsStateModel>, { accountId }: any, ): any {
        let payload:any = {
            "currency": "iqd"
        }
        return this._walletService.createWallet(accountId, new NymcardWalletCreateRequest(payload)).pipe(
            catchError((problem) => {
                ctx.dispatch(new FailFromApi({ problem: problem }));
                return throwError(problem);
            }),
        );
    }
}
