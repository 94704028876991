import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  Observable, Subscription,
} from 'rxjs';

import {
  Select,
} from '@ngxs/store';

import {
  Dialog,
  DIALOG_DATA,
} from '@nymos/theme';

import {
  Account,
  Texts,
  Card,
  Wallet,
} from '@nymos/accounts/core';

import {
  CardsState,
} from '../../../core/store/cards/cards.state';

import {
  ActivateFromCardSelectionPage,
  ResumeFromCardSelectionPage,
  SuspendFromCardSelectionPage,
  TerminateFromCardSelectionPage,
  LoadCardFromUserDetailsPage,
 } from '../../../core/store/cards/cards.actions';

 import {
    DryRunTransferBetweenWalletFromUserDetailsPage,
    TransferBetweenWalletFromUserDetailsPage,
 } from '../../../core/store/wallet-activities/wallet-activities.actions';


import {
  CardHandler,
} from '../../../services/card-handler.service';

import {
  CardDetailsComponent,
} from '../card-details/card-details.component';

import {
  CardToCardTransferComponent,
} from '../card-to-card-transfer/card-to-card-transfer.component';
import { IssueCardComponent } from '../issue-card/issue-card.component';
import { WalletsState } from '../../../core/store/wallets/wallets.state';

@Component({
  selector: 'nym-card-selection',
  templateUrl: './card-selection.component.html',
  styleUrls: ['./card-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardSelectionComponent implements OnInit {

  @HostBinding('class.nym-card-selection')
  protected get classes(): boolean { return true; }

  @Output('manageCard') // tslint:disable-line:no-output-rename
  protected manageCardEmitter: EventEmitter<any> = new EventEmitter();

  @Output('viewIssueCard')
  protected viewIssueCardEmitter: EventEmitter<any> = new EventEmitter();

  @Select(CardsState.cards)
  public cards$: Observable<Card[]>;

  @Select(WalletsState.mainWallet)
  public wallet$: Observable<Wallet>;

  public account: Account;
  private _wallet: Wallet;
  private _subscription: Subscription = new Subscription();

  constructor(
    private _dialog: Dialog,
    private _cardHandler: CardHandler,
    @Inject(DIALOG_DATA) data: Account,
  ) {
    this.account = data;
  }

  public ngOnInit(): void {
    this._subscription.add(this.wallet$.subscribe((wallet) => this._wallet = wallet));
   }

  protected viewCard(card: Card): void {
    this._dialog.openAsFullwidth(CardDetailsComponent, { data: card });
  }

  protected cardToCardTransfer(card:any): void {
      this.account['cardId'] = card.id;
      const text = Texts.TopupDialog.TransferCardtoCardTitle('Transfer Money');
      let account = this.account;
      const ref = this._dialog.openAsModal(CardToCardTransferComponent, {
       width: '400px',
       data: {
        text,
        account,
        cancel: { label: 'Cancel' },
        confirm:{ label: 'Transfer' },
      },
    });
  }

  protected manageCard({card, action}: any): any {
    // tslint:disable-next-line: switch-default
     switch (action) {
      case 'activate':
        return this._cardHandler.activate(card, ActivateFromCardSelectionPage);

      case 'resume':
        return this._cardHandler.resume(card, ResumeFromCardSelectionPage);

      case 'suspend':
        return this._cardHandler.suspend(card, SuspendFromCardSelectionPage);

      case 'terminate':
        return this._cardHandler.terminate(card,this._wallet,DryRunTransferBetweenWalletFromUserDetailsPage,
        TransferBetweenWalletFromUserDetailsPage,TerminateFromCardSelectionPage);

      case 'refund':   
       return this._cardHandler.refund(card,this._wallet,DryRunTransferBetweenWalletFromUserDetailsPage,TransferBetweenWalletFromUserDetailsPage);

       case 'transfer':   
        this.cardToCardTransfer(card)
    }
  }

  protected viewIssueCard(): void {
    this._dialog.openAsModal(IssueCardComponent, {
      width: '400px',
      data: {
        text: 'Issue Card',
        cancel: { label: 'Cancel' },
        confirm:{ label: 'Issue' },
      },
    });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
