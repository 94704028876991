import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';

import {
  interval,
  Observable,
  Subscription,
} from 'rxjs';

import {
  filter,
} from 'rxjs/operators';

import {
  Dialog,
} from '@nymos/theme';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  UserAccountUpdateRequest,
  IdvChecks,
  IdvDocumentsDownload,
  IdvVerificationReports,
  IdvVerificationReportsDocuments,
} from '@michel.freiha/ng-sdk';

import {
  Account,
  AccountsUsersState,
  UpdateAccountFromUserEditDetailsPage
} from '@nymos/accounts/core';

import {MatSnackBar} from '@angular/material/snack-bar';

import {
  AccountsProvincesState,
} from '../../../core/store/accounts-provinces/accounts-provinces.state';

import {
  AccountsProvincesNamingsState,
} from '../../../core/store/accounts-provinces-namings/accounts-provinces-namings.state';

import {
  AccountsProvincesNewState,
} from '../../../core/store/accounts-provinces-new/accounts-provinces-new.state';

import {
  AccountsCitiesNamingsState,
} from '../../../core/store/accounts-cities-namings/accounts-cities-namings.state';

import {
  AccountsCountriesState,
} from '../../../core/store/accounts-countries/accounts-countries.state';

import {
  UserMessageComponent,
} from '../../containers/user-message/user-message.component';

import {
  Navigate,
} from '@ngxs/router-plugin';

import { GetIdvReport, DownloadUserIdvDocument, GetUserIdvCheck, GetUserIdvDocumentStatus } from '../../../core/store/accounts-users/accounts-users.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'nym-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEditComponent implements OnInit, OnDestroy {

  private _subscription: Subscription = new Subscription();

  protected _account: Account;
  protected _idvChecks: IdvChecks;
  protected provinces$: Observable<string[]>;
  protected provinceNamings$: Observable<string[]>;
  protected provinceNew$: Observable<string[]>;
  protected cityNamings$: Observable<string[]>;
  protected cities$: Observable<string[]>;
  protected newcities$: Observable<string[]>;
  protected countries$: Observable<string[]>;
  protected reportId: string;
  public isSuccess:boolean = false;
  public isReport: string;
  protected idvCheckResult: IdvChecks
  protected idvReportResult: IdvVerificationReports;
  protected downloadUserIdvDocumentResult: IdvDocumentsDownload[] = [];

  @Output('getReportId')
  protected getReportIdEmitter: EventEmitter<any> = new EventEmitter();

  @HostBinding('class.nym-user-edit')
  protected get classes(): boolean { return true; }

  protected account$: Observable<Account>;
  protected idvChecks$: Observable<IdvChecks>;

  @Select(AccountsUsersState.loading)
  public accountLoading$: Observable<boolean>;

  protected accountId: string;
  protected showIdvReport: boolean = false;

  constructor(
    private _store: Store,
    private _dialog: Dialog,
    private _snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private _router: Router,
  ) {
    const router = this._store.selectSnapshot((state) => state.router.state);
    this.provinces$ = this._store.select(AccountsProvincesState.provinces);
    this.provinceNew$ = this._store.select(AccountsProvincesNewState.newprovinces);
    this.countries$ = this._store.select(AccountsCountriesState.countries);
    this.provinceNamings$ = this._store.select(AccountsProvincesNamingsState.AccountsProvincesNamings);
    this.cityNamings$ = this._store.select(AccountsCitiesNamingsState.AccountsCitiesNamings);
    this.accountId = router.params.id;   
    this.account$ = this._store.select(AccountsUsersState.account(this.accountId));
    this.idvChecks$ = this._store.select(AccountsUsersState.AccountIdvChecks);
  }

  public editUser(account:UserAccountUpdateRequest):void{
    if(account){
     this.isSuccess=true;
     this._store.dispatch(new UpdateAccountFromUserEditDetailsPage(  this.accountId,account)).subscribe((res)=>{
          this.isSuccess = false;;
          this.cd.markForCheck();
        if(res.problem && res.users.problem){
          this.openSnackBar(false)}
          else{
          let snackBarRef = this.openSnackBar(true)
               snackBarRef.afterDismissed().subscribe(() => {
                   this._store.dispatch(new Navigate(['accounts/'+this.accountId+'/user/details']));
             });
            }
      });  
  }
  /*setTimeout(() => {
                this._store.dispatch(new Navigate(['accounts/'+this.accountId+'/user/details']));
            }, 2500);*/
  }

 public openSnackBar(status:boolean):any {
       this.isSuccess=false;
        return this._snackBar.openFromComponent(UserMessageComponent, {
             duration:2500,
             verticalPosition: 'bottom',
             horizontalPosition: 'center',
             data:{"isSuccess":status},
          });
 }
 

  public ngOnInit(): void {
    if(this._router.url.includes('report')) this.showIdvReport = true;
    this._subscription.add(this.account$.subscribe((account) => {this._account = account}));
    this._subscription.add(this.idvChecks$.subscribe((idvChecks) => {
      if(idvChecks)this._idvChecks = idvChecks
      else this.getDocumentStatus()
    }));
    this.cd.markForCheck();
    
  }

  protected getDocumentStatus(){
    this._store.dispatch([new GetUserIdvDocumentStatus(this.accountId)]).subscribe((res)=>{
      if(res[0].users.idvDocument && res[0].users.idvDocument.length>0){
       let arr = res[0].users.idvDocument;
            let idvDoc = arr.map(function(obj) { return obj.uploadedDocument; });
            idvDoc = idvDoc.filter(function(v,i) { return idvDoc.indexOf(v) == i; });
            this.getIdvCheck()
       }
    })
  }
  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected provinceChange$$(province: string): void {
    this.cities$ = this._store.select(AccountsProvincesState.cities(province));
  }

  protected provinceNewChange$$(newprovince: string): void {
    this.newcities$ = this._store.select(AccountsProvincesNewState.newcities(newprovince));
  }

   protected viewAttachments$$(event: any): void {
    const urls = event.attachments;
    const index = event.index;

    this._dialog.openPreview({ urls, index });
  }

  protected showReportResult(event: string){
    let reportId =  event;
    this.isReport = event;
    this._store.dispatch([new GetIdvReport(reportId,this.accountId)]).subscribe((res)=>{
     this.idvReportResult = res[0].users.idvReportResult;
     this.cd.markForCheck();
     this.downloadUserIdvDocumentResult = [];
     this.downloadUserIdvDocument(this.idvReportResult.documents);
   })
 }

 protected getIdvCheck(): void {
  let isAgent = false;
  this._store.dispatch([new GetUserIdvCheck(this.accountId,isAgent)]).subscribe((res)=>{
    this._idvChecks = res[0].users.idvCheck;
    this.cd.markForCheck();
    //this.idvCheckResult = res[0].users.idvCheck[0];
  })
}

  protected downloadUserIdvDocument(documentIds: IdvVerificationReportsDocuments[]): void {
    documentIds.forEach(({id}) => {
      this._store.dispatch([new DownloadUserIdvDocument(this.accountId, id)]).subscribe((res)=>{
        this.downloadUserIdvDocumentResult.push(res[0].users.downloadUserIdvDocumentResult);
        this.cd.markForCheck();
      })
    })
  }
}
