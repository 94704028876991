/* tslint:disable:member-ordering */

import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';

import {
  produce,
} from 'immer';

import {
  catchError,
  finalize,
  tap,
} from 'rxjs/operators';

import {
  EMPTY,
  Observable,
} from 'rxjs';

import {
  NymcardCardBulkIssuanceInternal,
  NymcardCardBulkIssuanceInternalCollection,
  NymcardsBulkInternalService,
  AccountchannelsSearchService,
  Paging,
  Problem,
  SearchCriteria,
  SearchOperator,
  SearchFilter,
} from '@michel.freiha/ng-sdk';

import {
  SignOut,
} from '@nymos/auth';

import {
  NotificationCenter,
} from '@nymos/dashboard/shared';

import {
  Texts,
} from '../../texts/bulkcards.texts';

import {
  BulkIssuance,
} from '../../models/bulk-issuance.model';

import {
  AccountProfile,
} from '../../models/account-profile.model';

import {
  BulkIssuanceBuilder,
} from '../../builders/bulk-issuance.builder';

import {
  FailFromApi,
  IssueFromHomePage,
  GetAgentListForKycApproval,
  LoadFromHomePage,
  LoadMoreFromHomePage,
} from './issuances.actions';

import {
  Notifications,
} from './issuances.notifications';

const LIMIT = 50;

export interface IssuancesStateModel {
  items: { [id: string]: NymcardCardBulkIssuanceInternal};
  ids: string[];
  loading: boolean;
  saving: boolean;
  problem: Problem;
  paging: Paging;
}

const stateDefaults: IssuancesStateModel = {
  items: {},
  ids: [],
  loading: undefined,
  saving: undefined,
  problem: undefined,
  paging: undefined,
};

@State<IssuancesStateModel>({
  name: 'issuances',
  defaults: stateDefaults,
})
export class IssuancesState {


  @Selector()
  public static issuances(state: IssuancesStateModel): BulkIssuance[] {
    let result = state.ids.map((id) => {
      return new BulkIssuanceBuilder()
        .withInternal(state.items[id])
        .build();
    });
    result = result.sort((b, a) => new Date(a.created).getTime() - new Date(b.created).getTime());
    return result;
  }

  public static issuance(id: string): any {
    return createSelector([IssuancesState], (state: IssuancesStateModel) => {
      return new BulkIssuanceBuilder()
        .withInternal(state.items[id])
        .build();
    });
  }

  @Selector()
  public static agents(state: any): AccountProfile[] {
    return state;
  }

  @Selector()
  public static problem(state: IssuancesStateModel): Problem {
    return state.problem;
  }

  @Selector()
  public static loading(state: IssuancesStateModel): boolean {
    return state.loading;
  }

  @Selector()
  public static hasMore(state: IssuancesStateModel): boolean {
    return !!(state.paging && state.paging.cursors && state.paging.cursors.after);
  }

  constructor(
    private _nc: NotificationCenter,
    private _issuanceService: NymcardsBulkInternalService,
    private channelService: AccountchannelsSearchService,
  ) { }

  @Action(SignOut)
  public reset(ctx: StateContext<IssuancesStateModel>): any {
    ctx.setState(stateDefaults);
  }

  @Action(LoadFromHomePage, { cancelUncompleted: true })
  public loadBulkIssuances(ctx: StateContext<IssuancesStateModel>): any {
    this._nc.show(Notifications.Loading);

    const paging = undefined;
    ctx.patchState({ ...stateDefaults, paging: paging, loading: true });

    return this._loadBulkIssuances(ctx, paging, true).pipe(

      finalize(() => {
        this._nc.dismiss();
        ctx.patchState({ loading: false });
      }),
    );
  }

  @Action(LoadMoreFromHomePage)
  public loadMoreBulkIssuances(ctx: StateContext<IssuancesStateModel>): any {

    const state = ctx.getState();
    const paging = state.paging;

    if (!paging)
      throw new Problem({
        title: Texts.Action.UnableToLoadMoreTitle,
        detail: Texts.Action.UnableToLoadMoreDetail,
      });

    return this._loadBulkIssuances(ctx, paging, false);
  }

  @Action(IssueFromHomePage)
  public issueBulk(
    ctx: StateContext<IssuancesStateModel>,
    { details }: any,
  ): any {
    details.dryRun = false;
    this._nc.show(Notifications.Issuing);
    return this._issuanceService.createCardsInBulk(details).pipe(

      tap((item: NymcardCardBulkIssuanceInternal) => {
        ctx.setState(produce((draft) => {
          draft.ids.push(item.token);
          draft.items[item.token] = item;
          this._nc.show(Notifications.Issued);
        }));
      }),

      catchError((problem) => {
        return ctx.dispatch(new FailFromApi({ problem: problem }));
      }),
    );
  }

  @Action(FailFromApi)
  public fail(ctx: StateContext<IssuancesStateModel>, { payload: { problem } }: FailFromApi): void {
    ctx.patchState({ problem: problem });
    this._nc.show(Notifications.Failure);
  }

  private _loadBulkIssuances(ctx: StateContext<IssuancesStateModel>, paging: Paging, reset: boolean = true): any {

    const after = paging && paging.cursors && paging.cursors.after;

   
   return this._issuanceService.loadCardBulkIssuances(LIMIT, after).pipe(

      tap((collection: NymcardCardBulkIssuanceInternalCollection) => {
        ctx.setState(produce((draft) => {
          if (reset)
            draft.ids = [];
            collection.data.forEach((item) => {
            draft.ids.push(item.token);
            draft.items[item.token] = item;
          });
           draft.paging = collection.paging;
        }));
      }),

      catchError((problem) => {
        return ctx.dispatch(new FailFromApi({ problem: problem }));
      }),
    );
  }

  @Action(GetAgentListForKycApproval)
  public getAgentListForKycApproval(ctx: StateContext<any>, {searchString}:any ): any { 
    const state = ctx.getState();
    // const agent = new AgentAccount({ ...state.agent.agentAccount});
    const cri= new SearchCriteria({filters: [new SearchFilter({ field: 'channel', operator: SearchOperator.AnyOf, values: ['active'] }),
                      new SearchFilter({ field: 'account_type', operator: SearchOperator.AnyOf, values: ['agent'] })],query: searchString});

    return this.channelService.search(cri).pipe(
      tap((internal) => {
        ctx.setState({ ...state, agents: internal.data });
      }),
      catchError((problem) => {
        ctx.dispatch(new FailFromApi(problem));
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ saving: false });
      }),
    );
  }
}
