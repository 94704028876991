import * as faker from 'faker';

import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  AbstractControl,FormControl, FormBuilder, FormGroup, Validators,
} from '@angular/forms';


import {
  CustomValidators,
} from 'ngx-custom-validators';

import {
  Actions,
  ofActionSuccessful,
  Store,
} from '@ngxs/store';

import {
  NymcardCardCreateBulkDetails as BulkDetails,
} from '@michel.freiha/ng-sdk';

import { state } from '@angular/animations';



import {
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  IssueFromHomePage,
  GetAgentListForKycApproval,
  IssuancesState,
  AccountProfile,
} from '@nymos/bulkcards/core';

//import { AccountProfile } from '../../../core/core';

        //import { AccountsAgentKycState } from '../../../core/store/accounts-agent-kyc/
        //accounts-agent-kyc.state';

import { Observable,Subject, BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'nym-bulk-issuance',
  templateUrl: './bulk-issuance.component.html',
  styleUrls: ['./bulk-issuance.component.scss'],
})
export class BulkIssuanceComponent extends ModalComponent implements OnInit, OnDestroy {

  private _id: string;

  @HostBinding('class.nym-bulk-issuance')
  protected get classes(): boolean { return true; }

  protected form: FormGroup;

  protected agents$: Observable<AccountProfile[]>;

  public get type(): AbstractControl { return this.form.get('type'); }
  public get quantity(): AbstractControl { return this.form.get('quantity'); }
  public get currency(): AbstractControl { return this.form.get('currency'); }
  public get amount(): AbstractControl { return this.form.get('amount'); }
  public get agent(): AbstractControl { return this.form.get('agent'); }

  public searchAgent: FormControl = new FormControl('');
  public selectAmount: FormControl = new FormControl('');
  public filteredAgents: any;

  constructor(
    fb: FormBuilder,
    ref: DialogRef<any, any>,
    cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) locale: string,
    @Inject(DIALOG_DATA) data: any,
    protected _store: Store,
    protected _actions$: Actions,
  ) {
    super(fb, ref, cd, locale, data);

    this._id = faker.random.uuid();
    this.initForm(data);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._actions$.pipe(ofActionSuccessful(IssueFromHomePage)).subscribe(() => this._ref.close());
    this.filterAgents();
  }


 public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.type.setValidators([Validators.required]);
    this.amount.setValidators([Validators.required]);
    this.agent.setValidators([Validators.required]);
    this.quantity.setValidators([Validators.required, CustomValidators.gt(0),
        CustomValidators.lte(500)]);
     this.type.updateValueAndValidity();
     this.agent.updateValueAndValidity();
     this.quantity.updateValueAndValidity();
     this.amount.updateValueAndValidity();
   }

  // @Override
  protected initForm(data: any): void {

    this.form = this._fb.group({

      type: ['', [
        Validators.required,
      ]],
      
      agent:['', [
        Validators.required,
      ]],

      quantity: ['', [
        Validators.required,
        CustomValidators.gt(0),
        CustomValidators.lte(500),
      ]],

      currency: [{ value: 'usd', disabled: false }, [
        Validators.required,
      ]],

      amount: [{ value: '0', disabled: false }, [
        Validators.required,
      ]],

    });
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }



  // @Override
  protected submit(): void {
    let agentId ='';
    if (!this.form.valid)
      return;
    if(this.type.value!=='005'){
       agentId = this.agent.value.accountId
     }else{
       agentId=''
    }
    const details = new BulkDetails({
      token: this._id,
      cardProductId: this.type.value,
      numberOfCards: +this.quantity.value,
      currency: this.currency.value,
      amount: +this.amount.value,
      agentId:agentId,
    });
    this._store.dispatch(new IssueFromHomePage(details));
  }


  protected filterAgents():void {
     this.resolveAgents(this.searchAgent.value); 
    }

  public cardChange$$(card: string): void {
    if(card=='006' || card =='007'){
      this.amount.patchValue('0')
      this.currency.patchValue('usd')
      this.form.get('agent').setValidators([Validators.required])
      this.form.get('agent').updateValueAndValidity()
      this.form.get('amount').updateValueAndValidity()
      this.form.get('currency').updateValueAndValidity()
    }
    if(card=='008'){
      this.amount.patchValue('0')
      this.currency.patchValue('iqd')
      this.form.get('agent').setValidators([Validators.required])
      this.form.get('agent').updateValueAndValidity()
      this.form.get('amount').updateValueAndValidity()
      this.form.get('currency').updateValueAndValidity()
    }
    if(card=='005'){
      this.amount.patchValue('')
      this.currency.patchValue('usd')
      this.form.get('amount').setValidators([Validators.required])
      this.form.get('agent').setValidators([])
      this.form.get('agent').updateValueAndValidity()
      this.form.get('amount').updateValueAndValidity()
      this.form.get('currency').updateValueAndValidity()
    }

  }


  public resolveAgents(searchString):void {

    this._store.dispatch(new GetAgentListForKycApproval(state,searchString));
    this.agents$ = this._store.select(IssuancesState.agents);

    this.agents$.subscribe((data: any) => {
       this.filteredAgents= data.agents;
       for(var i=0;i<this.filteredAgents.length;i++){
         if(this.filteredAgents[i].fullName==this.agent.value.fullName){
            this.agent.patchValue(this.filteredAgents[i])
            this.form.get('agent').updateValueAndValidity();
            break;
          }
        }
    });
  }

}
