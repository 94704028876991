type Status = 'settled' | 'authorized' | 'declined' | 'cancelled' | undefined;

export class CardActivity {

    public id: string;
    public accountId: string;
    public cardId: string;
    public created: Date;
    public modified: Date;
    public category: string;
    public merchant: string;
    public amount: number;
    public currency: string;
    public name: string;
    public last4: string;
    public status: Status;
    public networkName:string;
    public location: string;
    public reason: string;
    public providerType:string;
    public transactionType:string;
    public transactionDate: Date;
    public chargebackStatus: string;

    constructor(ob?: Partial<CardActivity>) {

        this.id = undefined;
        this.accountId = undefined;
        this.cardId = undefined;
        this.created = undefined;
        this.modified = undefined;
        this.category = undefined;
        this.merchant = undefined;
        this.amount = undefined;
        this.currency = undefined;
        this.name = undefined;
        this.last4 = undefined;
        this.status = undefined;
        this.networkName=undefined;
        this.location = undefined;
        this.reason = undefined;
        this.providerType=undefined;
        this.transactionType = undefined;
        this.transactionDate = undefined;
        this.chargebackStatus = undefined;

        if (!ob) { return; }

        if (ob.id !== undefined) { this.id = ob.id; }
        if (ob.accountId !== undefined) { this.accountId = ob.accountId; }
        if (ob.cardId !== undefined) { this.cardId = ob.cardId; }
        if (ob.created !== undefined) { this.created = new Date(ob.created); }
        if (ob.modified !== undefined) { this.modified = new Date(ob.modified); }
        if (ob.category !== undefined) { this.category = ob.category; }
        if (ob.merchant !== undefined) { this.merchant = ob.merchant; }
        if (ob.amount !== undefined) { this.amount = ob.amount; }
        if (ob.currency !== undefined) { this.currency = ob.currency; }
        if (ob.name !== undefined) { this.name = ob.name; }
        if (ob.last4 !== undefined) { this.last4 = ob.last4; }
        if (ob.status !== undefined) { this.status = ob.status; }
        if (ob.networkName !== undefined) { this.networkName = ob.networkName; }
        if (ob.location !== undefined) { this.location = ob.location; }
        if (ob.reason !== undefined) { this.reason = ob.reason; }
        if (ob.providerType !== undefined) { this.providerType = ob.providerType; }
        if (ob.providerType !== undefined) { 
            this.transactionType = this.getTransactionType(ob.providerType)  }
        if (ob.transactionDate !== undefined) { this.transactionDate = new Date(ob.transactionDate); }
        if (ob.chargebackStatus) this.chargebackStatus = ob.chargebackStatus;
    }
    

    getTransactionType(providerType:string){
      const trxType = providerType.split('.').pop();
       switch(trxType){
         case '00' :
           return 'Purchase';
         
         case '01' :
           return 'Cash Withdrawal';

         case '11' :
          return 'Quasi Cash';

         case '20' :
          return 'Refund';

         case '30' :
          return 'Balance Inquiry'

         case '39' :
          return 'Account Verification';

         case '40' :
          return 'Transfer';

         case '50' :
          return 'Bill Payment';

         case '100' :
          return 'Authorization';

         case '200' :
          return 'Financial'; 

         case '220' :
          return 'Advice'; 

         case '420' :
           return 'Reversal'; 
         
         case '29' :
            return 'VISA Credit';   
         
       }

    }

}

