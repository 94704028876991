import {
    AccountLimit as Limit,
    ExpirationDate,
} from '@michel.freiha/ng-sdk';

export type CardStatus = 'active' | 'inactive' | 'suspended' | 'terminated' | 'blocked'| undefined;
export type CardAction = 'activate' | 'resume' | 'suspend' | 'terminate' |'refund'| undefined;

export class Card {

    public id: string;
    public accountId: string;
    public created: Date;
    public modified: Date;
    public name: string;
    public productId: string;
    public status: CardStatus;
    public first6: string;
    public last4: string;
    public network: string;
    public balance:number;
    public limit: Limit;
    public expiration: ExpirationDate;
    

    constructor(ob?: Partial<Card>) {

        this.id = undefined;
        this.accountId = undefined;
        this.created = undefined;
        this.modified = undefined;
        this.name = undefined;
        this.productId = undefined;
        this.status = undefined;
        this.first6 = undefined;
        this.last4 = undefined;
        this.network = undefined;
        this.balance =undefined;
        this.limit = undefined;
        this.expiration = undefined;
       

        if (!ob) { return; }

        if (ob.id !== undefined) { this.id = ob.id; }
        if (ob.accountId !== undefined) { this.accountId = ob.accountId; }
        if (ob.created !== undefined) { this.created = new Date(ob.created); }
        if (ob.modified !== undefined) { this.modified = new Date(ob.modified); }
        if (ob.name !== undefined) { this.name = ob.name; }
        if (ob.productId !== undefined) { this.productId = ob.productId; }
        if (ob.status !== undefined) { this.status = ob.status; }
        if (ob.first6 !== undefined) { this.first6 = ob.first6; }
        if (ob.last4 !== undefined) { this.last4 = ob.last4; }
        if (ob.network !== undefined) { this.network = ob.network; }
        if (ob.balance !== undefined) { this.balance = ob.balance; }
        if (ob.limit !== undefined) { this.limit = new Limit(ob.limit); }
        if (ob.expiration !== undefined) { this.expiration = new ExpirationDate(ob.expiration); }

    }
}

