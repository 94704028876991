import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatDividerModule,
} from '@angular/material/divider';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatInputModule,
} from '@angular/material/input';

import {
  MatProgressBarModule,
} from '@angular/material/progress-bar';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  MatElevationModule,
} from '@michel.freiha/material';

import {
  ThemeModule,
} from '@nymos/theme';

import {
  AuthRoutingModule,
} from './auth.routing';

import {
  AuthGuard,
} from './guards/auth.guard';

import {
  ScopesGuard,
} from './guards/scope.guard';

import {
  SessionGuard,
} from './guards/session.guard';

import {
  AuthState,
} from './store/auth/auth.state';

import {
  ProfileState,
} from './store/profile/profile.state';

import {
  AuthComponent,
} from './views/layouts/auth/auth.component';

import {
  SignInComponent,
} from './views/containers/sign-in/sign-in.component';

import {
  MobileVerificationComponent,
} from './views/containers/mobile-verification/mobile-verification.component';

import {
  PasswordChangeComponent,
} from './views/containers/password-change/password-change.component';

import {
  ScopesDirective,
} from './directives/scopes.directive';

import {
  ScopesChecker,
} from './services/scopes.service';

import {
  accountMeProvider,
} from './auth.provider';


export const MATERIAL_MODULES = [
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressBarModule,
  MatElevationModule,
];

@NgModule({
  imports: [
    NgxsModule.forFeature([AuthState, ProfileState]),
  ],
})
export class NymAuthRootModule {
  constructor(
    @Optional() @SkipSelf() parentModule: NymAuthRootModule,
  ) {
    if (parentModule) throw new Error('NymAuthRootModule is already loaded. Import it in the AppModule only');
  }
}

@NgModule({
  declarations: [
    AuthComponent,
    SignInComponent,
    MobileVerificationComponent,
    PasswordChangeComponent,
    ScopesDirective,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    AuthRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ThemeModule,
  ],

  exports: [
    ScopesDirective,
  ],
})
export class NymAuthModule {

  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: NymAuthRootModule,
      providers: [
        AuthGuard,
        ScopesGuard,
        SessionGuard,
        ScopesChecker,
        accountMeProvider,
      ],
    };
  }
}


