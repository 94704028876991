import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  CanColor,
  CanColorCtor,
  mixinColor,
  ThemePalette,
} from '@angular/material/core';

import {
  Card,
  CardStatusColorPipe,
} from '@nymos/accounts/core';


export class CardInfoBase {
  constructor(public _elementRef: ElementRef) { }
}

export const _CardInfoMixinBase: CanColorCtor & typeof CardInfoBase =
  mixinColor(CardInfoBase);


@Component({
  selector: 'nym-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardInfoComponent extends _CardInfoMixinBase
  implements OnInit, OnDestroy, CanColor {

  private _card: Card;
  private cardType: any;

  public get cardtype(): any {return this.cardType}

  @HostBinding('class.nym-card-info')
  protected get classes(): boolean { return true; }

  @Input()
  public get card(): Card { return this._card; }
  public set card(value: Card) { this._setCard(value); }

  @Input()
  public loading: boolean;

  protected get progress(): number { return this._getProgress(); }

  protected get added(): number {
    if (!this._card || !this._card.limit)
      return undefined;

    return (this._card.limit.spentAmount + this._card.limit.reservedAmount);
  }

  protected get remaining(): number {
    if (!this._card || !this._card.limit)
      return undefined;

    return this._card.limit.totalAmount - (this._card.limit.spentAmount + this._card.limit.reservedAmount);
  }

  constructor(
    elementRef: ElementRef,
  ) {
    super(elementRef);
  }

  public ngOnInit(): void { 
    if(this.card.productId === '006') {
      this.cardType = 'travelcard:default';
    }
    if(this.card.productId === '007') {
      this.cardType = 'businesscard:default';
    }
    if(this.card.productId === '008') {
      this.cardType = 'dinarcard:default';
    }
    if(this.card.productId === '005') {
      this.cardType = 'giftcard:default';
    }
    if(this.card.productId === '004'){
      this.cardType = 'creditcard:default';
    } 
  }

  public ngOnDestroy(): void { }

  private _setCard(value: Card): void {
    this._card = value;
    this.color = new CardStatusColorPipe().transform(value.status) as ThemePalette;
  }

  private _getProgress(): number {
    if (!this._card || !this._card.limit)
      return 0;

    return 100 * (this.added / this._card.limit.totalAmount);
  }
}
