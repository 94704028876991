import {
    Injectable,
} from '@angular/core';

import {
    DomSanitizer,
} from '@angular/platform-browser';

import {
    MatIconRegistry,
} from '@angular/material/icon';


const ASSETS_FOLDER = 'assets/console/';

@Injectable({
    providedIn: 'root',
})
export class ConsoleAssets {

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        iconRegistry.addSvgIconInNamespace('home', 'welcome', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'welcome.svg'));
    }

}
