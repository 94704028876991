import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';

import {
  CardActivity,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-card-details-content',
  templateUrl: './card-details-content.component.html',
  styleUrls: ['./card-details-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDetailsContentComponent implements OnInit {

  @HostBinding('class.nym-card-details-content')
  protected get classes(): boolean { return true; }

  @Output()
  protected loadMore: EventEmitter<any> = new EventEmitter();

  @Output()
  protected dispute: EventEmitter<CardActivity> = new EventEmitter();

  @Input()
  public activities: CardActivity[];

  @Input()
  public loading: boolean;

  @Input()
  public hasMore: boolean;

  constructor() { }

  public ngOnInit(): void { }

    protected loadMore$(): void {
    this.loadMore.emit();
  }

  protected dispute$$(transaction$:CardActivity): void {
    this.dispute.emit(transaction$);
  }
}
