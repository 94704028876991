/* tslint:disable:member-ordering */

import {
    Inject,
} from '@angular/core';

import {
    StateContext,
    Store,
} from '@ngxs/store';

import {
    produce,
} from 'immer';

import {
    MonoTypeOperatorFunction,
    Observable,
    throwError,
    timer,
} from 'rxjs';

import {
    catchError,
    concatMap,
    filter,
    finalize,
    map,
    take,
    tap,
} from 'rxjs/operators';

import {
    AccountChannelIndex,
    AccountChannelIndexCollection,
    AccountsInternalService,
    AgentsInternalService,
    AgentsSearchService,
    ClientOptions,
    IdvChecks,
    IdvDocuments,
    IdvDocumentsDownload,
    IdvVerificationReports,
    NymcardsLimitInternalService,
    PasscodesInternalService,
    Problem,
    SearchCriteria,
    SearchFilter,
    SearchOperator,
    UPLOADS_OPTIONS,
    UsersInternalService,
} from '@michel.freiha/ng-sdk';

import {
    AccountchannelsSearchService,
} from '@michel.freiha/ng-sdk';

import {
    FailFromApi,
    GetAccountFromApi,
} from './accounts/accounts.actions';

import {
    Notification,
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    ChannelStatus,
} from '../models/account-channel.model';

export class AccountsOperations<T> {

    protected static uploadOptions: ClientOptions;

    constructor(
        protected store: Store,
        protected nc: NotificationCenter,
        protected agentService: AgentsInternalService,
        protected agentSearchService: AgentsSearchService,
        protected accountService: AccountsInternalService,
        protected userService: UsersInternalService,
        protected passcodeService: PasscodesInternalService,
        protected channelService: AccountchannelsSearchService,
        protected limitsService: NymcardsLimitInternalService,
        @Inject(UPLOADS_OPTIONS) options: ClientOptions,
    ) {
        AccountsOperations.uploadOptions = options;
    }

    protected loadChannelIndex(ctx: StateContext<T>, id: any): any {
        const criteria = new SearchCriteria({
            filters: [new SearchFilter({ field: 'account_id', operator: SearchOperator.AnyOf, values: [id] })],
        });

        return this.channelService.search(criteria).pipe(
            map((collection: AccountChannelIndexCollection) => {
                let data = collection.data;
                if (data.length > 1) {
                    data = data.filter((o) => o.accountId === id);
                }

                if (data.length !== 1) {
                    throw new Problem({ detail: `Found ${data.length} account channels for ${id}.` });
                }

                const index = data[0];
                return index;
            }),
        );
    }

    protected getUserIdvDocument(ctx: StateContext<T>, id: any): any {

        return this.userService.getUserIdvDocuments(id).pipe(
            map((idvDocuments: IdvDocuments) => {
                let data = idvDocuments.documents;
                if(idvDocuments.documents && idvDocuments.documents.length>0){
                    data = idvDocuments.documents;
                    //let filterRejectedIdvDocument = idvDocuments.documents.filter((status_reason_code)=>{return status_reason_code.statusReasonCode=='R0' || status_reason_code.statusReasonCode=='A0'})
                    //   if(filterRejectedIdvDocument.length>0){
                    //     data = [];
                    //   }else{
                    //     data = idvDocuments.documents;
                    //   }
                 }
                 else if(idvDocuments.documents && idvDocuments.documents.length==0){
                    data = []; 
                      
                  }
                return data;
            }),
        );
    }


    protected getUserIdvReport(ctx: StateContext<T>, id: any,accountId:any): any {

        return this.userService.getIdvReportsById(accountId,id).pipe(
            map((idvReport: IdvVerificationReports) => {
                let data = idvReport;
                if(idvReport){
                   data = idvReport;
                 }
                 else {
                    data = undefined; 
                  }
            
                return data;
            }),
        );
    }
    
    protected getIdvCheck(ctx: StateContext<T>, id: any,isAgent:boolean): any {
    let checkId;
    let agentId;
        return this.userService.getIdvChecks(checkId,id,agentId,isAgent).pipe(
            map((idvCheck: IdvChecks[]) => {
                let data = idvCheck;
                 if(idvCheck.length>0){
                      data = idvCheck   
                      }
                      else{
                        data = [];
                      }
                return data;
            }),
        );
    }

    protected downloadUserIdvDoc(ctx: StateContext<T>, id: string, documentId: string): Observable<IdvDocumentsDownload> {
            return this.userService.downloadUserIdvDocument(id,documentId).pipe(
                map((idvDocumentsDownload: IdvDocumentsDownload) => {
                    let data = idvDocumentsDownload;
                    return data;
                }),
            );
        }

    protected loadLimitInternal(ctx: StateContext<T>, id: any): any {
        return this.limitsService.loadLimits(id);
    }

    protected loadCardsLimitInternal(ctx: StateContext<T>, id: any): any {
        return this.limitsService.loadCardsLimits(id);
    }
    protected loadCardsMultiLimitInternal(ctx: StateContext<T>, id: any): any {
        return this.limitsService.loadCreditsLimits(id);
    }

    protected updateItem(
        ctx: StateContext<T>,
        id: string,
        notification: Notification,
    ): MonoTypeOperatorFunction<any> {

        if(notification)
        this.nc.show(notification);

        const idx = this.store.selectSnapshot((state) => state.accounts.indexes[id]);

        if (idx) {
            ctx.setState(produce((draft) => { draft.indexes[id] = idx; }));
        }

        return (source) =>
            source.pipe(
                tap(({ internal, index, limits, cardLimit, cardMultiLimit }: any) => {
                    ctx.setState(produce((draft) => {
                        draft.indexes[id] = index;
                        draft.internals[id] = internal;
                        draft.limits[id] = limits;
                        draft.cardLimits[id]= cardLimit;
                        draft.cardMultiLimits[id] = cardMultiLimit
                    }));
                }),

                catchError((problem) => {
                    // Let global handler catch it
                    return throwError(problem);
                }),

                finalize(() => {
                    this.nc.dismiss();
                }),
            );
    }

    protected refreshItem(
        ctx: StateContext<T>,
        id: string,
    ): MonoTypeOperatorFunction<any> {

        return (source) =>
            source.pipe(
                tap(({ internal, index, limits, cardLimit, cardMultiLimit }: any) => {
                    ctx.setState(produce((draft) => {
                        draft.indexes[id] = index;
                        draft.internals[id] = internal;
                        draft.limits[id] = limits;
                        draft.cardLimits[id]= cardLimit;
                        draft.cardMultiLimits[id] = cardMultiLimit
                    }));
                }),

                catchError((problem) => {
                    // Let global handler catch it
                    return throwError(problem);
                }),
            );
    }

    protected updateChannel(
        ctx: StateContext<T>,
        id: string,
        predicate: (index: AccountChannelIndex) => boolean,
        action: new (id: string) => any,
    ): MonoTypeOperatorFunction<any> {

        return (source) =>
            source.pipe(
                tap(() => {
                    this.pollChannelUntil(ctx, id, predicate, action);
                }),

                catchError((problem) => {
                    return ctx.dispatch(new FailFromApi({ problem: problem }));
                }),
            );
    }

    protected pollChannelUntil(
        ctx: StateContext<T>,
        id: string,
        predicate: (index: AccountChannelIndex) => boolean,
        action: new (id: string) => any,
    ): any {
        return timer(0, 500)
            .pipe(
                concatMap(() => this.loadChannelIndex(ctx, id)),
                take(10),
                filter((index: AccountChannelIndex) => predicate(index)),
                take(1),
            )
            .subscribe(() => ctx.dispatch(new action(id)));
    }

}
