import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Select, Store,
} from '@ngxs/store';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Credentials,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  PasswordVisibility,
} from '../../../models/password-visibility.model';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  Auth,
  Init,
} from '../../../store/auth/auth.actions';

import {
  AuthState,
} from '../../../store/auth/auth.state';


@Component({
  selector: 'nym-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  // animations: ANIMATIONS,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class SignInComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();

  @Select(AuthState.problem)
  public _problem$: Observable<Problem>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  public passwordMode: PasswordVisibility = PasswordVisibility.Hidden;

  public form: FormGroup;

  public get username(): AbstractControl {
    return this.form.get('username');
  }
  public get password(): AbstractControl {
    return this.form.get('password');
  }

  public get problem$(): Observable<Problem> {
    return this._problem$;
  }

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _ph: ProblemHandler,
  ) {
    this._store.dispatch(new Init());

    this.form = this._fb.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    this._subscription.add(this._problem$.subscribe((problem) => {
      this._ph.handle(problem, this.form);
    }));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public submit(): void {
    this.form.setErrors(null);
    if (!this.form.valid)
      return;

    const credentials = new Credentials({
      username: this.form.value['username'],
      password: this.form.value['password'],
    });

    this._store.dispatch(new Auth({ credentials: credentials }));
  }

  public togglePassword(): void {
    this.passwordMode.toggle();
  }
}
