import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  NymcardAccountLimit as AccountLimit,
  NymcardAccountCreditsLimit as AccountCreditsLimit
} from '@michel.freiha/ng-sdk';

import {
  Account,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-user-limit-info-iqd',
  templateUrl: './user-limit-info-iqd.component.html',
  styleUrls: ['./user-limit-info-iqd.component.scss']
})
export class UserLimitInfoIqdComponent implements OnInit {

  @HostBinding('class.nym-user-limit-info')
  protected get classes(): boolean { return true; }

  @Input()
  public account: Account;

  @Input()
  public loading: boolean;

  @Input()
  public amount: number = 0;

  @Input()
  public mode: string = 'determinate';

  public currency:string;

  protected get limit(): AccountCreditsLimit { return this.multiYearly; }

  protected get addedPercent(): number { return (this.added / this.total) * 100; }
  protected get reservedPercent(): number { return (this.reserved / this.total) * 100; }

  protected get yearly(): AccountLimit {
    return this.account && this.account.limits && this.account.limits.yearly;
  }

  protected get multiYearly(): AccountCreditsLimit {
         return this.walletInfo && this.walletInfo.limits && this.walletInfo.limits.yearly;
  }


  protected get walletInfo(): any {
    this.currency = 'iqd';
    let copyAccount = this.account;
     if(copyAccount && copyAccount.cardMultiLimits)
        return copyAccount.cardMultiLimits.filter((multiWallet)=>{
         multiWallet["limits"]["yearly"]["currency"]= multiWallet["currency"];
         return multiWallet["currency"] == this.currency.toLowerCase()
     })[0]
  }

  constructor() { }

  public ngOnInit(): void { }

  public get added(): number {
    if (!this.limit)
      return 0;

    return (this.limit.credits.used + this.limit.credits.reserved);
  }

  public get reserved(): number {
    if (!this.limit)
      return 0;

    return +this.amount + this.added;
  }

  public get remaining(): number {
    if (!this.limit)
      return NaN;

    return this.limit.credits.total - this.added;
  }

  public get total(): number {
    if (!this.limit)
      return NaN;

    return this.limit.credits.total;
  }

}
