import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  AdminSession,
  AdminSessionFlow,
} from '@michel.freiha/ng-sdk';

import {
  AuthState,
} from '../../../store/auth/auth.state';



@Component({
  selector: 'nym-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;

  @HostBinding('class.auth')
  protected get classes(): boolean { return true; }

  @Select(AuthState.session)
  public session$: Observable<AdminSession>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  constructor(
    private _store: Store,
  ) { }

  public ngOnInit(): void {
    this._subscription = this.session$.subscribe((session) => {
      if (!session)
        return;

      switch (session.flow) {
        case AdminSessionFlow.PasswordChange:
          this._store.dispatch(new Navigate(['/account/password/change']));
          return;

        case AdminSessionFlow.MobileVerification:
          this._store.dispatch(new Navigate(['/account/challenge/ipp']));
          return;

        default:
      }
    });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
