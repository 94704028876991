import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import * as faker from 'faker';

import {
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';

import {
  ScopesChecker,
} from '@nymos/auth';
import { Select, Store } from '@ngxs/store';
import { UserKyc3Approval } from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.actions';
import { Observable,Subject, BehaviorSubject, Subscription } from 'rxjs';
import { AccountProfile, Wallet } from '../../../core/core';
import { UserAccountVerificationStatus } from '@michel.freiha/ng-sdk';
import { WalletsState } from '../../../core/store/wallets/wallets.state';

@Component({
  selector: 'nym-kyc3',
  templateUrl: './kyc3.component.html',
  styleUrls: ['./kyc3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class Kyc3Component implements OnInit, OnChanges, OnDestroy {

  @HostBinding('class.nym-kyc3')
  protected get classes(): boolean { return true; }

  @Output()
  protected next: EventEmitter<string> = new EventEmitter();
  protected agents$: Observable<AccountProfile[]>;
  protected _onDestroy = new Subject<void>();
  protected walletState$: Observable<boolean>;
  protected lastCardIdState$: Observable<boolean>;
  protected cardState$: Observable<boolean>;
  protected approveKycState$: Observable<boolean>;
  @Input()
  public account: any;

  @Select(WalletsState.mainWallet)
  public wallet$: Observable<Wallet>;

  @Input()
  public approvalStatus: any;
  public walletState: boolean;
  public cardState: any;
  public approveKycState: any;
  public disableToggle: boolean;
  public verificationStatus$: Subject<any>;
  public verificationStatus: any;
  private kycDone:boolean;
  private greyOut:boolean;
  public selected:boolean = false;


  constructor(
    private _store: Store,
  ) {
  this.verificationStatus$ = new BehaviorSubject('');
  this.kycDone=false;
  this.greyOut=false;
}

  public ngOnInit(): void {
    //this.disableToggle = true;

    this.verificationStatus$.subscribe((verificationStatus) => {
      this.verificationStatus = verificationStatus;
    })
   }


   public approvekyc3(event: any): void {
     this.kycDone=true;
     this.greyOut=true;
     let userId = this.account.id;
     this._store.dispatch(new UserKyc3Approval(userId));
  }

 
  public ngOnChanges(changes: SimpleChanges): void {
    this.verificationStatus$.next(this.account.verificationStatus);
  }

   public disableField(): boolean {
    return this.verificationStatus === UserAccountVerificationStatus.Kyc3Approved;
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
