import {
    AccountChannelIndex,
    AgentAccountInternal,
    ClientOptions,
    Gender,
    UserAccountInternal,
} from '@michel.freiha/ng-sdk';

import {
    AccountProfile,
} from '../models/account-profile.model';
import { Attachment } from '../models/attachment.model';


export class AccountProfileBuilder {

    private _user: UserAccountInternal;
    private _agent: AgentAccountInternal;
    private _index: AccountChannelIndex;

    constructor(private _options: ClientOptions) { }

    public withUser(value: UserAccountInternal): AccountProfileBuilder {
        this._user = value;
        return this;
    }

    public withAgent(value: AgentAccountInternal): AccountProfileBuilder {
        this._agent = value;
        return this;
    }

    public withIndex(value: AccountChannelIndex): AccountProfileBuilder {
        this._index = value;
        return this;
    }

    public build(): AccountProfile {

        let fullName: string;
        let motherName: string;
        let motherNameArabic :string;
        let email: string;
        let mobile: string;
        let arabicName: string;
        let gender: Gender;
        let nationality: string;
        let dateOfBirth: Date;
        let placeOfBirth: string;
        let phoneNumber: string;
        let selfie:any;
        let documents:any;

        if (!this._index && !this._user && !this._agent)
            return undefined;

        if (this._index) {
            const profile = this._index;
            fullName = profile.fullName;
            email = profile.email;
            mobile = profile.mobile;
            arabicName = profile.arabicName;
        }

        if (this._user) {
            const profile = this._user.userAccount.profile;
            if (profile) {
                fullName = profile.fullName;
                motherName = profile.motherName;
                motherNameArabic = profile.motherNameArabic;
                selfie = profile.selfie;
                email = profile.email;
                mobile = profile.mobile;
                arabicName = profile.arabicName;
                gender = profile.gender;
                nationality = profile.nationality;
                dateOfBirth = profile.dateOfBirth;
                placeOfBirth = profile.placeOfBirth;
                documents = this._getDocumentAttachments(selfie, this._user.id);
            }

        } else if (this._agent) {
            const profile = this._agent.agentAccount.profile;
            if (profile) {
                fullName = profile.fullName;
                email = profile.email;
                mobile = profile.mobile;
            }
        }


        return new AccountProfile({
            fullName: fullName,
            motherName:motherName,
            motherNameArabic:motherNameArabic,
            selfie:documents,
            email: email,
            mobile: mobile,
            arabicName: arabicName,
            gender: gender,
            nationality: nationality,
            dateOfBirth: dateOfBirth,
            placeOfBirth: placeOfBirth,
            phoneNumber: phoneNumber,
        });
    }

    private _getDocumentAttachments(info: any, accountId: string): Attachment[] {

        let attachments: Attachment[] = [];

        if (info)
            attachments.push(this._createAttachment(info, accountId));


        return attachments;
    }

    private _createAttachment(file: any, accountId: string): Attachment {
        const baseUrl = this._options.basePathFactory.make();
        const attachment = new Attachment({
            id: file.id,
            name: file.fileName,
            contentType: file.contentType,
            size: file.size,
            url: `${baseUrl}/uploads/v1/accounts/${accountId}/files/${file.id}/browse`,
        });

        return attachment;
    }

}
