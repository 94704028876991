import {
  NgModule,
} from '@angular/core';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  MatNativeDateModule,
  MatOptionModule,
  MatRippleModule,
} from '@angular/material/core';

import {
  MatButtonModule,
} from '@angular/material/button';

import { 
      TitleCasePipe
} from '@angular/common';

import {
  MatCardModule,
} from '@angular/material/card';

import {
  MatCheckboxModule,
} from '@angular/material/checkbox';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import {
  MatDatepickerModule,
} from '@angular/material/datepicker';

import {
  MatDividerModule,
} from '@angular/material/divider';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatInputModule,
} from '@angular/material/input';

import {
  MatMenuModule,
} from '@angular/material/menu';

import {
  MatProgressBarModule,
} from '@angular/material/progress-bar';

import {
  MatSelectModule,
} from '@angular/material/select';

import {
  MatStepperModule,
} from '@angular/material/stepper';

import {
  MatTableModule,
} from '@angular/material/table';

import {
  MatTabsModule,
} from '@angular/material/tabs';

import {
  MatTooltipModule,
} from '@angular/material/tooltip';

import {
  MatSnackBarModule,
} from '@angular/material/snack-bar';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule,
} from '@angular/material-moment-adapter';

import {
  NgxsModule,
} from '@ngxs/store';

import {
  MatElevationModule,
} from '@michel.freiha/material';

import {
  SharedModule,
} from '@nymos/dashboard/shared';

import {
  AccountsAgentsState,
  AccountsCoreModule,
  AccountsUsersState,
} from '@nymos/accounts/core';

import {
  NymNotesModule,
} from '@nymos/notes';

import {
  AccountsRoutingModule,
} from './accounts.routing';

import {
  AccountsState,
} from './core/store/accounts/accounts.state';

import {
  AccountsAgentKycState,
} from './core/store/accounts-agent-kyc/accounts-agent-kyc.state';

import {
  AccountsChannelsState,
} from './core/store/accounts-channels/accounts-channels.state';

import {
  AccountsTypesState,
} from './core/store/accounts-types/accounts-types.state';

import {
  CardsState,
} from './core/store/cards/cards.state';

import {
  CardActivitiesState,
} from './core/store/card-activities/card-activities.state';

import {
  WalletsState,
} from './core/store/wallets/wallets.state';

import {
  WalletActivitiesState,
} from './core/store/wallet-activities/wallet-activities.state';




// LAYOUT

import {
  ChannelComponent,
} from './ui/layout/channel/channel.component';

import {
  ContentComponent,
} from './ui/layout/content/content.component';



// CONTAINERS

import {
  AgentCreationComponent,
} from './ui/containers/agent-creation/agent-creation.component';

import {
  AgentDetailsComponent,
} from './ui/containers/agent-details/agent-details.component';

import {
  UserEditComponent,
} from './ui/containers/user-edit/user-edit.component';

import {
  AgentDetailsAsideComponent,
} from './ui/containers/agent-details/agent-details-aside/agent-details-aside.component';

import {
  AgentDetailsContentComponent,
} from './ui/containers/agent-details/agent-details-content/agent-details-content.component';

import {
  AgentCreationConfirmComponent,
} from './ui/containers/agent-creation-confirm/agent-creation-confirm.component';

import {
  CardDetailsComponent,
} from './ui/containers/card-details/card-details.component';

import {
  CardDetailsContentComponent,
} from './ui/containers/card-details/card-details-content/card-details-content.component';

import {
  CardDetailsAsideComponent,
} from './ui/containers/card-details/card-details-aside/card-details-aside.component';

import {
  CardSelectionComponent,
} from './ui/containers/card-selection/card-selection.component';

import {
  ChannelResultsComponent,
} from './ui/containers/channel-results/channel-results.component';

import {
  SearchHomepageComponent,
} from './ui/containers/search-homepage/search-homepage.component';

import {
  SearchResultsComponent,
} from './ui/containers/search-results/search-results.component';

import {
  UserCardActivityComponent,
} from './ui/containers/user-card-activity/user-card-activity.component';

import {
  UserWalletActivityComponent,
} from './ui/containers/user-wallet-activity/user-wallet-activity.component';

import {
  UserDetailsComponent,
} from './ui/containers/user-details/user-details.component';

import {
  UserDetailsAsideComponent,
} from './ui/containers/user-details/user-details-aside/user-details-aside.component';

import {
  UserDetailsContentComponent,
} from './ui/containers/user-details/user-details-content/user-details-content.component';


// COMPONENTS

import {
  AccountInfoComponent,
} from './ui/components/account-info/account-info.component';

import {
  AccountsProvincesState,
} from './core/store/accounts-provinces/accounts-provinces.state';

import {
  AccountsProvincesNewState,
} from './core/store/accounts-provinces-new/accounts-provinces-new.state';

import {
    AccountsProvincesNamingsState,
} from './core/store/accounts-provinces-namings/accounts-provinces-namings.state';

import {
    AccountsCitiesNamingsState,
} from './core/store/accounts-cities-namings/accounts-cities-namings.state';

import {
  AccountsCountriesState,
} from './core/store/accounts-countries/accounts-countries.state';

import {
  AgentAddressEditComponent,
} from './ui/components/agent-address-edit/agent-address-edit.component';

import {
  AgentAddressInfoComponent,
} from './ui/components/agent-address-info/agent-address-info.component';

import {
  AgentBusinessEditComponent,
} from './ui/components/agent-business-edit/agent-business-edit.component';

import {
  AgentBusinessInfoComponent,
} from './ui/components/agent-business-info/agent-business-info.component';

import {
  AgentDocumentsEditComponent,
} from './ui/components/agent-documents-edit/agent-documents-edit.component';

import {
  AgentDocumentsInfoComponent,
} from './ui/components/agent-documents-info/agent-documents-info.component';

import {
  AgentPersonalEditComponent,
} from './ui/components/agent-personal-edit/agent-personal-edit.component';

import {
  KycAgentInfoComponent,
} from './ui/components/kyc-agent-info/kyc-agent-info.component';

import {
  AgentPersonalInfoComponent,
} from './ui/components/agent-personal-info/agent-personal-info.component';



import {
  CardActivityListingComponent,
} from './ui/components/card-activity-listing/card-activity-listing.component';

import {
  CardActivityListingTableComponent,
} from './ui/components/card-activity-listing-table/card-activity-listing-table.component';

import {
  CardActivityListingHeaderComponent,
} from './ui/components/card-activity-listing-header/card-activity-listing-header.component';

import {
  CardActivityListingNoResultsComponent,
} from './ui/components/card-activity-listing-no-results/card-activity-listing-no-results.component';

import {
  CardInfoComponent,
} from './ui/components/card-info/card-info.component';

import {
  CardListingComponent,
} from './ui/components/card-listing/card-listing.component';

import {
  CardListingHeaderComponent,
} from './ui/components/card-listing-header/card-listing-header.component';

import {
  ChannelListingComponent,
} from './ui/components/channel-listing/channel-listing.component';

import {
  ChannelListingItemComponent,
} from './ui/components/channel-listing-item/channel-listing-item.component';

import {
  StatusMenuComponent,
} from './ui/components/status-menu/status-menu.component';

import {
  KYC2Component,
} from './ui/components/kyc-2/kyc-2.component';

import {
  UserAddressInfoComponent,
} from './ui/components/user-address-info/user-address-info.component';

import {
  UserPersonalInfoComponent,
} from './ui/components/user-personal-info/user-personal-info.component';

import {
  UserEditDetailsComponent,
} from './ui/components/user-edit-details/user-edit-details.component';

import {
  UserDocumentsInfoComponent,
} from './ui/components/user-documents-info/user-documents-info.component';

import {
  UserListingComponent,
} from './ui/components/user-listing/user-listing.component';

import {
  UserListingTableComponent,
} from './ui/components/user-listing-table/user-listing-table.component';

import {
  UserListingHeaderComponent,
} from './ui/components/user-listing-header/user-listing-header.component';

import {
  UserListingNoResultsComponent,
} from './ui/components/user-listing-no-results/user-listing-no-results.component';

import {
  UserListingLoadingComponent,
} from './ui/components/user-listing-loading/user-listing-loading.component';

import {
  UserLimitInfoComponent,
} from './ui/components/user-limit-info/user-limit-info.component';

import {
  UserWalletInfoComponent,
} from './ui/components/user-wallet-info/user-wallet-info.component';

import {
  WalletActivityListingComponent,
} from './ui/components/wallet-activity-listing/wallet-activity-listing.component';

import {
  WalletActivityListingTableComponent,
} from './ui/components/wallet-activity-listing-table/wallet-activity-listing-table.component';

import {
  WalletActivityListingHeaderComponent,
} from './ui/components/wallet-activity-listing-header/wallet-activity-listing-header.component';

import {
  WalletActivityListingNoResultsComponent,
} from './ui/components/wallet-activity-listing-no-results/wallet-activity-listing-no-results.component';

import {
  WalletTopupConfirmComponent,
} from './ui/containers/wallet-topup-confirm/wallet-topup-confirm.component';

import {
   UserMessageComponent,
} from './ui/containers/user-message/user-message.component';

import {
  WalletTopupRefundComponent,
} from './ui/containers/wallet-topup-refund/wallet-topup-refund.component';

import {
  WalletTopupFeesInfoComponent,
} from './ui/components/wallet-topup-fees-info/wallet-topup-fees-info.component';
import { CardToCardTransferComponent } from './ui/containers/card-to-card-transfer/card-to-card-transfer.component';
import { ChargebackCreateComponent } from './ui/containers/chargeback-create/chargeback-create.component';
import { IssueCardComponent } from './ui/containers/issue-card/issue-card.component';
import { UserWalletIqdInfoComponent } from './ui/components/user-wallet-iqd-info/user-wallet-iqd-info.component';
import { UserLimitInfoIqdComponent } from './ui/components/user-limit-info-iqd/user-limit-info-iqd.component';
import { Kyc3Component } from './ui/components/kyc3/kyc3.component';
import { UserIdvSdkResultComponent } from './ui/components/user-idv-sdk-result/user-idv-sdk-result.component';
import { UserIdvReportResultComponent } from './ui/components/user-idv-report-result/user-idv-report-result.component';
import { AgentIdvSdkResultComponent } from './ui/components/agent-idv-sdk-result/agent-idv-sdk-result.component';
import { AgentIdvReportResultComponent } from './ui/components/agent-idv-report-result/agent-idv-report-result.component';
import { UserIdvDocumentsInfoComponent } from './ui/components/user-idv-documents-info/user-idv-documents-info.component';
import { AgentIdvDocumentsInfoComponent } from './ui/components/agent-idv-documents-info/agent-idv-documents-info.component';
import { UserViewReportComponent } from './ui/containers/user-view-report/user-view-report.component';

export const MATERIAL_MODULES = [
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatSelectModule,
  NgxMatSelectSearchModule,
  MatNativeDateModule,
  MatDividerModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatOptionModule,
  MatProgressBarModule,
  MatRippleModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatElevationModule,
  MatMomentDateModule,
];



@NgModule({
  declarations: [
    AccountInfoComponent,
    AgentAddressInfoComponent,
    AgentAddressEditComponent,
    AgentBusinessEditComponent,
    AgentBusinessInfoComponent,
    AgentCreationComponent,
    AgentDetailsAsideComponent,
    AgentDetailsComponent,
    AgentDetailsContentComponent,
    AgentDocumentsEditComponent,
    AgentDocumentsInfoComponent,
    AgentPersonalEditComponent,
    KycAgentInfoComponent,
    AgentPersonalInfoComponent,
    AgentCreationConfirmComponent,
    CardActivityListingComponent,
    CardActivityListingHeaderComponent,
    CardActivityListingNoResultsComponent,
    CardActivityListingTableComponent,
    CardDetailsAsideComponent,
    CardDetailsComponent,
    CardDetailsContentComponent,
    CardInfoComponent,
    CardListingComponent,
    CardListingHeaderComponent,
    CardSelectionComponent,
    ChannelComponent,
    ChannelListingComponent,
    ChannelListingItemComponent,
    ChannelResultsComponent,
    ContentComponent,
    SearchHomepageComponent,
    SearchResultsComponent,
    StatusMenuComponent,
    KYC2Component,
    UserAddressInfoComponent,
    UserEditDetailsComponent,
    UserCardActivityComponent,
    UserDetailsAsideComponent,
    UserDetailsComponent,
    UserDetailsContentComponent,
    UserDocumentsInfoComponent,
    UserEditComponent,
    UserLimitInfoComponent,
    UserListingComponent,
    UserListingHeaderComponent,
    UserListingLoadingComponent,
    UserListingNoResultsComponent,
    UserListingTableComponent,
    UserPersonalInfoComponent,
    UserWalletActivityComponent,
    UserWalletInfoComponent,
    WalletActivityListingComponent,
    WalletActivityListingHeaderComponent,
    WalletActivityListingNoResultsComponent,
    WalletActivityListingTableComponent,
    WalletTopupConfirmComponent,
    UserMessageComponent,
    WalletTopupRefundComponent,
    WalletTopupFeesInfoComponent,
    CardToCardTransferComponent,
    ChargebackCreateComponent,
    IssueCardComponent,
    UserWalletIqdInfoComponent,
    UserLimitInfoIqdComponent,
    Kyc3Component,
    UserIdvSdkResultComponent,
    UserIdvReportResultComponent,
    AgentIdvSdkResultComponent,
    AgentIdvReportResultComponent,
    UserIdvDocumentsInfoComponent,
    AgentIdvDocumentsInfoComponent,
    UserViewReportComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    SharedModule,
    ReactiveFormsModule,
    AccountsCoreModule,
    AccountsRoutingModule,
    NymNotesModule,
    NgxsModule.forFeature([
      AccountsState,
      AccountsAgentsState,
      AccountsAgentKycState,
      AccountsChannelsState,
      AccountsProvincesState,
      AccountsProvincesNewState,
      AccountsCountriesState,
      AccountsTypesState,
      AccountsUsersState,
      CardsState,
      CardActivitiesState,
      AccountsProvincesNamingsState,
      AccountsCitiesNamingsState,
      WalletsState,
      WalletActivitiesState,
    ]),
  ],

  entryComponents: [
    AgentCreationConfirmComponent,
    CardDetailsComponent,
    CardSelectionComponent,
    UserCardActivityComponent,
    UserWalletActivityComponent,
    WalletTopupConfirmComponent,
    UserMessageComponent,
    WalletTopupRefundComponent,
    CardToCardTransferComponent,
    ChargebackCreateComponent,
    IssueCardComponent
  ],

  providers: [
      TitleCasePipe,
      { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
  ],
})
export class AccountsModule { }
