import {
    Inject, Injectable, INJECTOR,
} from '@angular/core';

import {
    filter,
} from 'rxjs/operators';

import {
    Select,
    Store,
} from '@ngxs/store';

import {
    CoreNote,
    SearchCriteria,
    SearchFilter,
    Problem,
    SearchOperator
} from '@michel.freiha/ng-sdk';

import {
    ConfirmComponent,
    Dialog,
    DialogRef,
} from '@nymos/theme';

import {
    ACCOUNT_ME,
    AccountMe,
} from '@nymos/auth';

import {
    NoteConfirmComponent,
    LoadUserNotesFromPage,
    NotesState
} from '@nymos/notes';

import {
    Notification,
    NotificationCenter,
} from '@nymos/dashboard/shared';

import {
    Account,
    AccountsUsersState,
    GetAccountUserLimit,
    Texts,
    Wallet,
} from '@nymos/accounts/core';


import { CheckAgentWalletForKycApproval, CheckTravelCardIssuanceForKycApproval } from '../core/store/accounts-agent-kyc/accounts-agent-kyc.actions';

import { SetStatusLoadingFromStatusMenu } from '../core/store/accounts-users/accounts-users.actions';

import * as faker from 'faker';
import { Observable} from 'rxjs';
import { LoadWalletsFromAgentDetailsPage } from '../core/store/wallets/wallets.actions';
import { WalletsState } from '../core/store/wallets/wallets.state';



@Injectable({
    providedIn: 'root',
})
export class ChannelHandler {

    private _ref: DialogRef<any, any>;

    protected account$: Observable<Account>;
    protected accountId: string;
    private _account: Account;
    
    @Select(WalletsState.mainWallet)
    public wallet$: Observable<Wallet>;

    constructor(
        private _nc: NotificationCenter,
        private _dialog: Dialog,
        private _store: Store,
        @Inject(ACCOUNT_ME) private _me: AccountMe,
    )

    {
        const router = this._store.selectSnapshot((state) => state.router.state);
        this.accountId = router.params.id;

    }

    public create(account: Account, action: new (account: Account) => any): any {

        this._ref = this._dialog.openConfirm(ConfirmComponent, {
            text: Texts.ChannelDialog.CreateTitle,
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe(() => {
            return this._store.dispatch(new action(account));
        });
    }

    public reject(account: Account, action: new (id: string, note: CoreNote,docType:string) => any): any {

        const createdById = account.createdBy && account.createdBy.id;
        if (this._me.id() === createdById) {
            this._nc.show(new Notification(Texts.UserAction.UnableToReview));
            return;
        }

        this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.ChannelDialog.RejectTitle(account.profile.fullName),
            hint: Texts.ChannelDialog.RejectHint,
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
            return this._store.dispatch(new action(account.id, note,account.channel.documentType)).subscribe((res)=>{
             setTimeout(()=>{
                     this.isSync(account.id)
                    }, 2000);
            });
        });
    }

    public verify(account: Account, wallet:Wallet, action: new (id: string,docType:string) => any): any {
        const createdById = account.createdBy && account.createdBy.id;
        let transactionId = faker.random.uuid();
        let documentType = account.channel.documentType;
        let _wallet:Wallet;

        if (this._me.id() === createdById) {
            this._nc.show(new Notification(Texts.UserAction.UnableToReview));
            return;
        }

        this._ref = this._dialog.openConfirm(ConfirmComponent, {
            text: Texts.ChannelDialog.VerifyTitle(account.profile.fullName),
        });

        this._ref.afterClosed().subscribe((d: boolean) => {
          if(d !== undefined){
            let idvStatusReasonCode = account.idvStatusReasonCode;
             if(documentType.toString()=='KYC2' && idvStatusReasonCode!='B0'){
                  let agentId = account.kyc2Agent.id;
                  let secondUUID= faker.random.uuid();
                  this.wallet$.subscribe((wallet_list) => wallet = wallet_list)
                  let walletId = account.kyc2_initial_card_product=='dinar' ? wallet.IqdBallance.internalId : wallet.internalId;
                   this._store.dispatch(new LoadWalletsFromAgentDetailsPage(agentId)).subscribe((agentWallet:Wallet)=>{
                     this.wallet$.subscribe((wallet) => _wallet = wallet)
                      let agent_wallet_id = account.kyc2_initial_card_product=='dinar' ? _wallet.IqdBallance.internalId : _wallet.internalId;
                      this._store.dispatch([new GetAccountUserLimit(account.id)])
                      return this._store.dispatch(new CheckTravelCardIssuanceForKycApproval(account.id, walletId, secondUUID, true,account.kyc2_initial_card_product)).subscribe((issues_card_res_dry)=>{
                        return this._store.dispatch(new CheckAgentWalletForKycApproval(agentId,account.id,  agent_wallet_id, transactionId, secondUUID,true,account.kyc2_initial_card_product)).subscribe((debitcard_res_dry)=>{
                          return this._store.dispatch(new action(account.id,account.channel.documentType)).subscribe((approve_kyc_res)=>{
                                this.checkCardLimit(account.id,this._store).then((res)=>{
                                    if(res=='done'){
                                            return this._store.dispatch(new CheckAgentWalletForKycApproval(agentId,account.id, agent_wallet_id, transactionId,secondUUID, false,account.kyc2_initial_card_product)).subscribe((debitcard_res)=>{
                                            return this._store.dispatch(new CheckTravelCardIssuanceForKycApproval(account.id, walletId, secondUUID, false,account.kyc2_initial_card_product)).subscribe((res)=>{
                                            return this._store.dispatch(new SetStatusLoadingFromStatusMenu(true))
                                          })
                                       })
                                     }
                                }).catch((e)=> {
                                      this._nc.open(e)
                                });
                             })
                         })
                      })
                  })
               }else{
                 return this._store.dispatch(new action(account.id,account.channel.documentType))
               }
            }
            else{
                 return this._store.dispatch(new SetStatusLoadingFromStatusMenu(false))
             }
         });
    }


    checkCardLimit(account_id,store) {
        let i = 0;
       return new Promise(function (resolve,reject) {
          var intervalId = setInterval(function () {
                   store.dispatch([new GetAccountUserLimit(account_id)])
                   let accountLimit= store.select(AccountsUsersState.account(account_id));
                       i++;
                       accountLimit.subscribe((account) => {
                       let total = account.limits.yearly.cards.total;
                        let used = account.limits.yearly.cards.used;
                         if(total > used){
                              clearInterval(intervalId);
                              resolve('done');
                           }
                        })
                  if(i>15){
                        clearInterval(intervalId);
                        reject("Failed to issues travel card");
                     }
              }, 1000);
         });

   }



    public block(account: Account, action: new (id: string, note: CoreNote) => any): any {

        this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.ChannelDialog.BlockTitle(account.profile.fullName),
            hint: Texts.ChannelDialog.BlockHint,
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
            return this._store.dispatch(new action(account.id, note)).subscribe((res)=>{
               if(res){
                   setTimeout(()=>{
                      this.isSync(account.id)
                    }, 3000);
                 }
            });
        });
    }

    public unblock(account: Account, action: new (id: string,note: CoreNote) => any): any {

        this._ref = this._dialog.openConfirm(NoteConfirmComponent, {
            text: Texts.ChannelDialog.UnblockTitle(account.profile.fullName),
            hint: Texts.ChannelDialog.UnblockHint,
        });

        return this._ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
            return this._store.dispatch(new action(account.id,note)).subscribe((res)=>{
                if(res){
                    setTimeout(()=>{
                       this.isSync(account.id)
                     }, 3000);
                  }
             });
        });
    }

    public isSync(accountId:string){
     const criteria = new SearchCriteria({
      filters: [new SearchFilter({ field: 'account_id', operator: SearchOperator.AnyOf, values: [accountId] })],
     });

    this._store.dispatch([
       new LoadUserNotesFromPage(criteria),
    ]);
     this._store.select(NotesState.userNotes(accountId));
    }

    //  public getUserAccountLimit(accountId){
    //      this._store.dispatch([new GetAccountUserLimit(accountId)])
    //      this.account$ = this._store.select(AccountsUsersState.account(this.accountId));
    //      this.account$.subscribe((account) => {
    //          return this._account = account
    //         })

    //  }

}
