import * as faker from 'faker';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  CurrencyPipe,
} from '@angular/common';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  StepperSelectionEvent,
} from '@angular/cdk/stepper';

import {
  MatHorizontalStepper,
} from '@angular/material/stepper';

import {
  Observable,
} from 'rxjs';

import {
  Actions,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';

import {
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';

import {
  InitKycFromResultsPage,
  LoadAccountFromMobile,
} from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.actions';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  NymcardCmsWalletTransactionDetails as TransactionDetails,
  NymcardCmsWalletTransactionTransferToCardDetails as TransactionTransferToCardDetails,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  NotificationCenter,
} from '@nymos/dashboard/shared';

import {
  RefreshWalletsFromUserDetailsPage,
} from '../../../core/store/wallets/wallets.actions';

import {
  Account,
  Texts,
  WalletActivity,
} from '@nymos/accounts/core';

import {
  CreditWalletFromUserDetailsPage,
  DebitWalletFromUserDetailsPage,
  TransferBetweenWalletAgentFromUserDetailsPage,
  DryRunTransferBetweenWalletAgentFromUserDetailsPage,
  DryRunWalletFromUserDetailsPage,
  DryRunDebitWalletFromUserDetailsPage,
  DryRunTransferToCardFromUserDetailsPage,
  TransferToCardFromUserDetailsPage
} from '../../../core/store/wallet-activities/wallet-activities.actions';

import {
  WalletActivitiesState,
} from '../../../core/store/wallet-activities/wallet-activities.state';

import {
  UserLimitInfoComponent,
} from '../../components/user-limit-info/user-limit-info.component';


type State = 'limits' | 'fees';

const IRAQ_NUMBER_PATTERN = /^\+?964|\D+$/g;

@Component({
  selector: 'nym-card-to-card-transfer',
  templateUrl: './card-to-card-transfer.component.html',
  styleUrls: ['./card-to-card-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
 // encapsulation: ViewEncapsulation.None,
  providers: [ProblemHandler],
})
export class CardToCardTransferComponent extends ModalComponent implements OnInit, OnDestroy, AfterViewInit {

  private _id: string;
  private _state: State = 'limits';
  private _valid: boolean = false;

   //protected get form1(): FormGroup { return this._form; }
   public get valid(): boolean { return this._form.valid; }
  
   private _form: FormGroup;

  @HostBinding('class.nym-card-to-card-transfer')
  protected get classes(): boolean { return true; }

  @Select(WalletActivitiesState.loading)
  public acting$: Observable<boolean>;

  @Select(WalletActivitiesState.problem)
  public problem$: Observable<Problem>;

  protected topupActivity$: Observable<WalletActivity>;

  @ViewChild('limit', { static: true })
  protected limit: UserLimitInfoComponent;

  @ViewChild('stepper', { static: true })
  protected stepper: MatHorizontalStepper;


  protected get account(): Account { return this.data && this.data.account; }
  protected get currency(): string { return this.account.limits.yearly.currency; }
  protected get amount(): number { return this.topuprefund.valid ? +this.topuprefund.value : 0; }
  protected get topuprefund(): AbstractControl { return this.form.get('topuprefund'); }
  protected get mobile(): any { return this.form.get('mobile'); }
  protected get digits(): any { return this.form.get('digits'); }
  protected get walletDestination(): AbstractControl { return this.form.get('walletDestination'); } 
  
  public errorMessage:boolean;
  public walletStatus:string;

  //protected get topup(): AbstractControl { return this.form.get('topup'); }

  constructor(
    protected _fb: FormBuilder,
    protected _ref: DialogRef<any, any>,
    protected _cd: ChangeDetectorRef,
    private _nc: NotificationCenter,
    protected _ph: ProblemHandler,
    protected _store: Store,
    protected _actions$: Actions,
    @Inject(LOCALE_ID) protected locale: string,
    @Inject(DIALOG_DATA) protected data: any,
  ) {
    super(_fb, _ref, _cd, locale, data);

    this._id = faker.random.uuid();
    this.topupActivity$ = this._store.select(WalletActivitiesState.topupActivity(this._id));

    this.initForm(data);
  }

    public ngOnInit(): void {
    super.ngOnInit();
    this._actions$.pipe(ofActionSuccessful(TransferToCardFromUserDetailsPage)).subscribe(() => this._ref.close());
    //this._actions$.pipe(ofActionSuccessful(DryRunTransferToCardFromUserDetailsPage)).subscribe(() => this._valid = true);
    this.topupActivity$.subscribe((data)=>{
     if(data && data.status=='declined' && data.category === 'card_to_card'){
       this._valid = false;
       this._nc.open('Amount to be transfered exceeds current card balance')
      }else if(data && data.status!=='declined'){
        this.stepper.next();
        this._valid = true;
      }
      else{
        this._valid = true;
      }
    })
    this.subscription.add(this.problem$.subscribe((problem) => {
        if(problem){
           this._valid = false;
           this.stepper.previous();
          }
           this._ph.handle(problem, this.form);

    }));
 }
 


   public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if(this.data.card.name == 'Dinar Card'){
      this.topuprefund.setValidators([
        Validators.required,
        Validators.min(0),
        Validators.pattern('^(?=.*[1-9])[0-9]*[.,]?[0-9]{1,3}$'),
      ]);  
    }else{
      this.topuprefund.setValidators([
        Validators.required,
        Validators.min(0),
        Validators.pattern('^(?=.*[1-9])[0-9]*[.,]?[0-9]{1,2}$'),
      ]);
    }
    this.topuprefund.updateValueAndValidity();
    this.digits.setValidators([Validators.required,Validators.maxLength(4),Validators.pattern(/^[0-9]/)]);
    this.mobile.setValidators([Validators.required,Validators.pattern(/^(7[1-9])[0-9]{8}$/)]);
    //this.walletDestination.setValidators([Validators.required]);
    this.mobile.updateValueAndValidity();
    this.digits.updateValueAndValidity();
     //this.walletDestination.updateValueAndValidity();
     this._store.dispatch(new RefreshWalletsFromUserDetailsPage(this.account.id)).subscribe((res) => {
      //let card_info = res.cards.items[this.account['cardId']].limit;
    })    

  }



    // @Override
  protected initForm(data: any): void {
       this.form = this._fb.group({
       topuprefund: ['', [Validators.required]],
       mobile: ['', [Validators.required,
                     Validators.pattern(/^(7[1-9])[0-9]{8}$/)
         ]],
         digits: ['', [Validators.required,
                     Validators.pattern(/^[0-9]/)
         ]],  
       // walletDestination:['', [Validators.required]],
    });
  }


    // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    if (!this.form.valid)
    return;

  if (this._state === 'limits')
      this._dryRunCardTransfer()
  else if(this._valid) 
       this._cardTransfer();
  }

 

  // // @Override
  // protected submit(): void {
  //   if (!this.form.valid)
  //     return;

  //   if (this._state === 'limits')
  //     this.stepper.next();
  //   else if (this._valid)
  //     this._creditWallet();
  // }

  protected topupTouched(): void {
    this.topuprefund.markAsTouched();
  }

  protected stateChange(event: StepperSelectionEvent): void {
    this._state = event.selectedStep.state as State;
    this._valid = this._valid && this._state === 'fees';
    if (this._state === 'fees')
        this._dryRunCardTransfer();
  }

  protected mobileTouched():void {
  let index : number;
  if(this.mobile.status ==='VALID'){
     const mobile = `+964${this.mobile.value.replace(IRAQ_NUMBER_PATTERN, '')}`;
      this._store.dispatch(new LoadAccountFromMobile(mobile)).subscribe((res)=>{
         if(!res.accounts.agent.problem){
            index = res.accounts.agent.agents.findIndex(
                  agent=> agent.accountId ===res.accounts.agent.agent.id);
           }
         if(res.accounts.agent.problem ||
           res.accounts.agent.agent.approvalStatus=='pending' ||
           res.accounts.agent.agent.approvalStatus=='rejected' ||
           res.accounts.agent.agent.approvalStatus=='none' || index <= -1){
           //this._form.patchValue({mobile:this.mobile.value+' '})
          }
      })
   }
}  

   private _cardTransfer(): void {
    const money = new CurrencyPipe(this.locale).transform(this.amount, this.data.card.limit.currency);
    const walletId = this.account.id;
    const cardId = this.account['cardId'];
    const transactionId = this._id;
    
     const transaction = new TransactionTransferToCardDetails({
      receiverMobile: '964'+this.mobile.value,
      receiverCardLast4:this.digits.value,
      amount: this.amount,
      // currency: this.account.limits.yearly.currency.toLowerCase(),
      currency: this.data.card.limit.currency.toLowerCase(),
      description: 'Wallet debit' //Texts.TopupDialog.DebitWallet(money),
    });
     this._store.dispatch(new TransferToCardFromUserDetailsPage(walletId,cardId, transactionId,transaction, money));
  }


  private _dryRunCardTransfer():void{
    const money = new CurrencyPipe(this.locale).transform(this.amount, this.data.card.limit.currency);
    const walletId = this.account.id;
    const cardId = this.account['cardId'];
    const transactionId = this._id;
    
     const transaction = new TransactionTransferToCardDetails({
      receiverMobile: '964'+this.mobile.value,
      receiverCardLast4:this.digits.value,
      amount: this.amount,
      // currency: this.account.limits.yearly.currency.toLowerCase(),
      currency: this.data.card.limit.currency.toLowerCase(),
      description: 'Wallet debit' //Texts.TopupDialog.DebitWallet(money),
    });
    
     //this._ref.close();
     this._store.dispatch(new DryRunTransferToCardFromUserDetailsPage(walletId,cardId, transactionId,transaction, money)).subscribe((testt)=>{
     });
  }

  private _dryRunWallet(): void {
    const money = new CurrencyPipe(this.locale).transform(this.amount, this.data.card.limit.currency);
    const walletId = this.account.id;
    const accountId = this.account.id;
    const transactionId = this._id;
    const transaction = new TransactionDetails({
      amount: this.amount,
      // currency: this.account.limits.yearly.currency.toLowerCase(),
      currency: this.data.card.limit.currency.toLowerCase(),
      description: Texts.TopupDialog.CreditWallet(money),
    });

    this._store.dispatch(new DryRunWalletFromUserDetailsPage(walletId,accountId, transactionId, transaction));
  }

  private _creditWallet(): void {
    const money = new CurrencyPipe(this.locale).transform(this.amount, this.data.card.limit.currency);

    const walletId = this.account.id;
    const accountId = this.account.id;
    const transactionId = this._id;
    const transaction = new TransactionDetails({
      amount: this.amount,
      // currency: this.account.limits.yearly.currency.toLowerCase(),
      currency: this.data.card.limit.currency.toLowerCase(),
      description: Texts.TopupDialog.CreditWallet(money),
    });

    this._store.dispatch(new CreditWalletFromUserDetailsPage(walletId, accountId,transactionId, transaction, money));
  }

}
