import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  FileUploadRef,
  UserProfile,
} from '@michel.freiha/ng-sdk';

import {
  Account, Attachment,
} from '@nymos/accounts/core';
import { ImageService } from '../../../services/image.service';


@Component({
  selector: 'nym-user-personal-info',
  templateUrl: './user-personal-info.component.html',
  styleUrls: ['./user-personal-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPersonalInfoComponent {

  @HostBinding('class.nym-user-personal-info')
  protected get classes(): boolean { return true; }

  @Output('viewAttachments') // tslint:disable-line:no-output-rename
  protected viewAttachments: EventEmitter<any> = new EventEmitter();

  @Input()
  public account: Account;

  protected selfie: FileUploadRef;
  protected selfieImageUrl :string
  protected isIdvUser: boolean = false;

  constructor(private _cd: ChangeDetectorRef,
    private _imageService: ImageService,) { }

  protected get profile(): UserProfile { return this.account && this.account.profile; }

  public ngOnChanges(changes: SimpleChanges): void {
    !this.account.document ? this.isIdvUser = true : this.isIdvUser = false;
    this._cd.markForCheck();

    if(this.profile){
      const selfieImage = this.profile.selfie
  
      if(selfieImage){
         this._setSelfieUrl(selfieImage[0])
       }
     } 
  }


  
   private _setSelfieUrl(ref: any): void {
    if (!this.account || !ref)
      return;
    ref.fileName=ref.fileName?ref.fileName: ref.name ;
    this.selfie = ref;
    this.selfieImageUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  } 

  protected viewSelfie$$(): void {
    if(this.selfie){
    let attachments: Attachment[] = [{
                                  id: this.selfie.id,
                                 name: this.selfie.fileName,
                                 size: this.selfie.size,
                                 contentType: this.selfie.contentType,
                                 url:this.selfieImageUrl
                                  }];
      let index:number=1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

}
