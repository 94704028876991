import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input, Output, EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';

import { TitleCasePipe } from '@angular/common';

import {
  Store,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  UserProfile,
  Problem,
  CoreUploadsService,
  IdvChecks
} from '@michel.freiha/ng-sdk';

import {
  AbstractControl,
  FormBuilder,
} from '@angular/forms';

import {
  Account
} from '@nymos/accounts/core';

import {
  IdvDocumentsDownload,
  IdvVerificationReports,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  ImageService,
} from '../../../services/image.service';

import { FormControl } from '@angular/forms';
import { Dialog } from '@nymos/theme';

@Component({
  selector: 'nym-user-view-report',
  templateUrl: './user-view-report.component.html',
  styleUrls: ['./user-view-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class UserViewReportComponent {

  @HostBinding('class.nym-user-edit-details')
  protected get classes(): boolean { return true; }

  @Input()
  public account: Account;

  @Input()
  public problem: Problem;

  @Input()
  public idvChecks: IdvChecks[];

  @Input()
  public reportId: string;

  @Input()
  public accountLoading: boolean;

  @Input()
  public downloadUserIdvDocumentResult: IdvDocumentsDownload[];

  @Input()
  protected idvReportResult: IdvVerificationReports;

  @Output('viewAttachments') // tslint:disable-line:no-output-rename
  protected viewAttachments: EventEmitter<any> = new EventEmitter();

  @Output() showReportResult = new EventEmitter<any>();
  protected isIdvUser: boolean;
  protected facialReport = [];
  protected documentReport = [];

  public selectedFacialDocument: FormControl = new FormControl('');
  public selectedDocumentReport: FormControl = new FormControl('');

  protected get profile(): UserProfile { return this.account && this.account.profile; }

  constructor(
    private _store: Store,
    private _dialog: Dialog,
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.idvChecks && this.idvChecks.length>0)this.isIdvUser = true;

  }
  noWhitespaceValidator(control: AbstractControl): any {
    var regex = /^(?!\s)(?!.*\s$)/;
    return !regex.test(control.value) ? { 'whitespace': true } : null;
  }

  protected back(): void {
    this._store.dispatch(new Navigate(['accounts/' + this.account.id + '/user/details']));
  }

  protected viewAttachmentsIdv(event: any): void {
    const urls = event.attachments;
    const index = event.index;
    this._dialog.openPreview({ urls, index });
  }

  protected showReportResultEmit(event: string): void {
    this.showReportResult.emit(event);
  }
}
