// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';
import { Texts } from '@nymos/accounts/core';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Failure: Notification = new Notification(Texts.Action.Failure);
    public static readonly FailureInvalidRRN: Notification = new Notification(Texts.Action.FailureInvalidRRN);
    public static readonly FailureChargebackAlreadyCreated: Notification = new Notification(Texts.Action.FailureChargebackAlreadyCreated);
    public static readonly ChargebackSuccessfulCreated: Notification = new Notification(Texts.Action.ChargebackSuccessfulCreated);
}
