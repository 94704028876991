// tslint:disable:variable-name

export type StringFn = (...args: string[]) => string;

export namespace Texts {

    export class Action {
        public static readonly Failure: string = `Something went wrong`;
        public static readonly FailureInvalidRRN = `Invalid RRN for this transaction`;
        public static readonly FailureChargebackAlreadyCreated = `Chargeback already created for transaction with this id`;
        public static readonly TerminateCardTransfer: string = `Money cannot be transferred to a terminated card`;
        public static readonly GiftCardTransfer: string = `Card to card transfer not applicable to Gift Card`;
        public static readonly SameCardTransfer: string = `Destination card should be different`;
        public static readonly InvalidCardTransfer: string = `Invalid Card`;
        public static readonly ExceedCardTransfer: string = `Amount is greater than maximum amount allowed of $300 USD`;
        public static readonly ExceedVirtualCardTransfer: string = `Card balance will exceed limit of $650 USD`;
        public static readonly ExceedTravelCardTransfer: string = `Card balance will exceed limit of $10000 USD`;
        public static readonly ActiveCardTransfer: string = `Card to card transfer is just applicable to Active Cards`;
        public static readonly LimitExceedCardTransfer: string = `Card limit reached`;
        public static readonly NotFoundCardTransfer: string = `Mobile number not registered  `;
        public static readonly UnableToLoadMoreTitle: string = `Could not load more entries`;
        public static readonly UnableToLoadMoreDetail: string = `Load more action was called before search method: no criteria defined`;
        public static readonly ChargebackSuccessfulCreated: string = `Chargeback created successfully`;
    }

    export class UserAction {
        public static readonly Searching: string = `Searching for user accounts`;
        public static readonly Sorting: string = `Loading...`;
        public static readonly Loading: string = `Loading user details`;
        public static readonly LoadingMore: string = `Loading more accounts..`;
        public static readonly ResettingPasscode: string = `Resetting passcode for the user`;
        public static readonly PasscodeReseted: string = `The passcode was reseted`;
        public static readonly ResetPasscode: StringFn = ((user, phone) => `A 4-digits OTP will be sent to ${user} ${phone}`);
        public static readonly UnableToSearch: string = `The minimum number of characters for searching account is 3`;
        public static readonly UnableToReview: string = `You cannot review the account you created yourself`;
    }

    export class AgentAction {
        public static readonly ActiveAccountTitle: string = ` Agent account is active`;
        public static readonly ActiveAccountDetail: string = `Agent KYC for the mobile number already exists`;
        public static readonly PendingAccountTitle: string = ` Agent KYC is in progress`;
        public static readonly PendingAccountDetail: string = `Agent KYC for the mobile number is in progess. Cannot create new account for same number`;
        public static readonly NotEnoughFunds: string = `There is no enough cash in this agent\'s wallet`;
    }

    export class ResultPage {
        public static readonly SearchTitle: StringFn = ((query) => `Showing results for: '${query}'`);
        public static readonly ActiveTitle: string = `Active accounts`;
        public static readonly VerifiedTitle: string = `Verified identity`;
        public static readonly PendingTitle: string = `Pending approval`;
        public static readonly IncompleteTitle: string = `Incomplete documents`;
        public static readonly RejectedTitle: string = `Rejected documents`;
        public static readonly BlockedTitle: string = `Blocked`;
    }

    export class ChannelDialog {
        public static readonly CreateTitle: string = `Are you sure you want to create a new account?`;
        public static readonly BlockTitle: StringFn = ((name) => `Block ${name}`);
        public static readonly BlockHint: string = `Say why you want to block this account. It will appear in this user’s notes section.`;
        public static readonly RejectTitle: StringFn = ((name) => `Reject ${name}`);
        public static readonly RejectHint: string = `Say why you want to reject this account. It will appear in this user’s notes section.`;
        public static readonly VerifyTitle: StringFn = ((name) => `Are you sure you want to verify ${name}’s profile?`);
        public static readonly UnblockTitle: StringFn = ((name) => `Are you sure you want to unblock ${name}?`);
        public static readonly UnblockHint: string = `Say why you want to unblock this account. It will appear in this user’s notes section.`;
    }

    export class CardDialog {
        public static readonly ActivateTitle: StringFn = ((last4) => `Are you sure you want to activate card ${last4}?`);
        public static readonly ResumeTitle: StringFn = ((last4) => `Are you sure you want to resume card ${last4}?`);
        public static readonly ResumeHint: string =  `Say why you want to resume this card. It will appear in this user’s notes section.`;
        public static readonly SuspendTitle: StringFn = ((last4) => `Are you sure you want to suspend card ${last4}?`);
        public static readonly SuspendHint: string =  `Say why you want to suspend this card. It will appear in this user’s notes section.`;
        public static readonly TerminateTitle: StringFn = ((last4) => `Are you sure you want to terminate card ${last4}?`);
        public static readonly TerminateHint: string = `Say why you want to terminate this card. It will appear in this user’s notes section.`;
        public static readonly RefundTitle: StringFn = ((last4) => `Refund card ${last4}`);
        public static readonly RefundHint: string = `By refunding the money from the card, the money will be added back to user's wallet.`;
    }

    export class CardAction {
        public static readonly Activating: StringFn = ((last4) => `Activating card ${last4}`);
        public static readonly Resuming: StringFn = ((last4) => `Resuming card ${last4}`);
        public static readonly Suspending: StringFn = ((last4) => `Suspending card ${last4}`);
        public static readonly Terminating: StringFn = ((last4) => `Terminating card ${last4}`);
    }

    export class TopupDialog {
        public static readonly TopupTitle: StringFn = ((name) => `Top-up ${name}'s wallet?`);
        public static readonly RefundTitle: StringFn = ((name) => `Refund ${name}'s wallet?`);
        public static readonly TransferCardtoCardTitle: StringFn = ((name) => `${name}`);
        public static readonly CreditWallet: StringFn = ((money) => `Add ${money} to wallet.`);
        public static readonly DebitWallet: StringFn = ((money) => `Withdraw ${money} to INC wallet.`);
        public static readonly DebitTransferWallet: StringFn = ((money) => `Withdraw ${money} to Agent wallet.`);
        public static readonly TransferBetweenWallets: StringFn = ((money) => `Transfer ${money} from card to user due to card termination.`);
        public static readonly TransferBetweenWalletsOnRefund: StringFn = ((money) => `Transfer ${money} from card to user due to card refund.`);
        public static readonly ApproveTitle: string = `Are you sure you want to approve this top-up?`;
        public static readonly RejectTitle: string = `Decline top-up`;
        public static readonly RejectHint: StringFn = ((name) => `Say why you want to decline this ${name}’s top -up. It will appear in this user’s notes section.`);
        public static readonly CancelTitle: string = `Cancel top-up`;
        public static readonly CancelHint: StringFn = ((name) => `Say why you want to cancel this ${name}’s top -up. It will appear in this user’s notes section.`);
        public static readonly RetryTitle: string = `Retry top-up`;
    }


    export class TopupAction {
        public static readonly Crediting: StringFn = ((money) => `Adding ${money} to wallet`);
        public static readonly Debiting: StringFn = ((money) => `Withdraw ${money} to INC wallet`);
        public static readonly DebitingWallet: StringFn = ((money) => `Withdraw ${money} to Agent wallet`);
        public static readonly Transfering: StringFn = ((money) => `Transfer ${money} from card to user due to card termination`);
        public static readonly CardTransfer: StringFn = ((money) => `Transfer ${money} from card `);
        public static readonly Refunding: StringFn = ((money) => `Transfer ${money} from card to user wallet.`);
        public static readonly Approving: string = `Approving wallet activity`;
        public static readonly Declining: string = `Declining wallet activity`;
        public static readonly Cancelling: string = `Cancelling wallet activity`;
        public static readonly Retrying: string = `Retrying wallet activity`;
    }

    export class WalletAction {
        public static readonly LoadingFailure: string = `Could not load wallet`;
    }

    export class WalletActivity {
        public static readonly CreditTitle: string = `Wallet topup`;
        public static readonly DebitTitle: string = `Wallet refund`;
        public static readonly CardIssuanceTitle: string = `Card Issuance`;
        public static readonly CardReloadTitle: string = `Card Reload`;
        public static readonly TransferTitle: string = `Transfer`;
        public static readonly TravelCardIssuanceFeeTitle: string = `Travel card issuance fee`;
        public static readonly CardIssuanceFeeTitle: string = `card issuance fee`;

        public static readonly CashPaymentMethod: string = `Cash`;
        public static readonly WalletPaymentMethod: StringFn = ((currency) => `${currency} Wallet`);
    }

    export class Notes {
        public static readonly AddNew: string = `Add new note`;
    }

    export class LegalForm {
        public static readonly RentalAgreement: string = `Rental Agreement`;
        public static readonly RegistrationForm: string = `Registration Form`;
    }

    export class AgentDialog {
        public static readonly SubmitTitle: string = `Confirm submission?`;
    }
}


