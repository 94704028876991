import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import * as faker from 'faker';

import {
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';

import {
  ScopesChecker,
} from '@nymos/auth';
import { Select, Store } from '@ngxs/store';
import { CheckAgentWalletForKycApproval, CheckTravelCardIssuanceForKycApproval, GetAgentListForKycApproval, UserKycApproval } from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.actions';
import { state } from '@angular/animations';
import { Observable,Subject, BehaviorSubject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AccountsAgentKycState } from '../../../core/store/accounts-agent-kyc/accounts-agent-kyc.state';
import { AgentAccount} from '@michel.freiha/ng-sdk';
import { AccountProfile, Wallet } from '../../../core/core';
import { FormControl } from '@angular/forms';
import { UserAccountVerificationStatus } from '@michel.freiha/ng-sdk';
import { MatSpinner } from '@angular/material';
import { LoadWalletsFromAgentDetailsPage, LoadWalletsFromUserDetailsPage } from '../../../core/store/wallets/wallets.actions';
import { WalletsState } from '../../../core/store/wallets/wallets.state';
import { NotificationCenter } from 'libs/dashboard/shared/src/lib/notification/services/notification.service';

@Component({
  selector: 'nym-kyc-2',
  templateUrl: './kyc-2.component.html',
  styleUrls: ['./kyc-2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KYC2Component implements OnInit, OnChanges, OnDestroy {

  @HostBinding('class.nym-kyc-2')
  protected get classes(): boolean { return true; }

  @Output()
  protected next: EventEmitter<string> = new EventEmitter();
  protected agents$: Observable<AccountProfile[]>;
  protected _onDestroy = new Subject<void>();
  protected walletState$: Observable<boolean>;
  protected lastCardIdState$: Observable<boolean>;
  protected cardState$: Observable<boolean>;
  protected approveKycState$: Observable<boolean>;
  @Input()
  public account: any;

  @Select(WalletsState.mainWallet)
  public wallet$: Observable<Wallet>;

  @Input()
  public approvalStatus: any;
  public agentId: any;
  public agents: any;
  public filteredAgents: any;
  public walletState: boolean;
  public cardState: any;
  public approveKycState: any;
  public disableToggle: boolean;
  public searchAgent: FormControl = new FormControl('');
  public cardType: FormControl = new FormControl('');
  public transactionId: any;
  public secondUUID: any;
  public verificationStatus$: Subject<any>;
  public verificationStatus: any;
  private kycDone:boolean;
  private greyOut:boolean;
  public selected:boolean = false;
  public _wallet : Wallet;
  public isIqdWallet:boolean
  public selectedCardType:string

  private s1: Subscription = new Subscription();
  private s2: Subscription = new Subscription();
  private s3: Subscription = new Subscription();
  private s4: Subscription = new Subscription();
  private s5: Subscription = new Subscription();
  private s6: Subscription = new Subscription();

  constructor(
    private _store: Store,
    private _snackbar: MatSnackBar,
    private _nc: NotificationCenter,
  ) {
  this.verificationStatus$ = new BehaviorSubject('');
  this.kycDone=false;
  this.greyOut=false;
  this.transactionId = faker.random.uuid();
  this.secondUUID = faker.random.uuid();
}

  public ngOnInit(): void {
    this.disableToggle = true;

    this.verificationStatus$.subscribe((verificationStatus) => {
      this.verificationStatus = verificationStatus;
    })
     //this.searchAgent.patchValue(this.account.kyc2Agent);
      this.filterAgents();
   }

   public agentSelected(event: any): void {

    let accountId = event.value.accountId;
     this.agentId = accountId;
     let userId = this.account.id;
     this._store.dispatch(new LoadWalletsFromAgentDetailsPage(this.agentId)).subscribe((agentWallet)=>{
       this.wallet$.subscribe((wallet) => this._wallet = wallet)
        if(this.cardType.value=='dinar' && !this._wallet.IqdBallance){
          this._nc.open("Agent don't have Iqd wallet")
          this.isIqdWallet=false;
        }else if(this.cardType.value=='dinar' && this._wallet.IqdBallance.balances[0].amount<1500){
          this._nc.open("Agent wallet don't have enough amount")
          this.isIqdWallet=false;
        }else if(this.cardType.value=='business' && this._wallet.balances[0].amount<35){
          this._nc.open("Agent wallet don't have enough amount")
          this.isIqdWallet =  false;
  
         }else if(this.cardType.value=='travel' && this._wallet.balances[0].amount<15){
          this._nc.open("Agent wallet don't have enough amount")
          this.isIqdWallet =  false;
         } 
      else{
     let walletId = this.cardType.value=='dinar' ?  this._wallet.IqdBallance.internalId : this._wallet.internalId;
     let defaultCard = this.cardType.value ? this.cardType.value:'travel'
     this._store.dispatch(new CheckAgentWalletForKycApproval(accountId, userId, walletId, this.transactionId,this.secondUUID, true,defaultCard)).subscribe((checkWallet)=>{
          this.wallet$.subscribe((wallet) => this._wallet = wallet)
      if(this.cardType.value=='dinar' && !this._wallet.IqdBallance){
         this._nc.open("Cardholder don't have Iqd wallet")
          this.isIqdWallet=false;
       }else{
        this.isIqdWallet=true;
        walletId = this.cardType.value=='dinar' ?  this._wallet.IqdBallance.internalId : this._wallet.internalId;
        this._store.dispatch(new CheckTravelCardIssuanceForKycApproval(userId, walletId, this.secondUUID, true, defaultCard)).subscribe((cardCheck)=>{
        this.greyOut=true;
        this.walletState$ = this._store.select(AccountsAgentKycState.walletState);
        this.s1=this.walletState$.subscribe((walletState) => {
        this.walletState = walletState;
          if (this.walletState) {
             this.cardState$ = this._store.select(AccountsAgentKycState.cardState);
             this.s2=this.cardState$.subscribe((disableToggle) => {
             this.disableToggle = !disableToggle;
              if(this.greyOut)
                this.greyOut=false;
                this.s1.unsubscribe();
                this.s2.unsubscribe();
            });
          }
       else
         if (this.walletState=== false)
        {
            this.greyOut=false;
            this.s1.unsubscribe();
            this.s2.unsubscribe();
       }
         });
        });
       }
     });
    }
    this._store.dispatch(new LoadWalletsFromUserDetailsPage(this.account.id))
    })

   }

   public approvekyc(event: any): void {
     this.kycDone=true;
     this.greyOut=true;
     let userId = this.account.id;
     if(this.agentId){
       this._store.dispatch(new UserKycApproval(userId, this.agentId, this.cardType.value));
    }
  }

  protected changeDropdown(event){
  if(this.agentId){
    this._store.dispatch(new LoadWalletsFromAgentDetailsPage(this.agentId)).subscribe((agentWallet)=>{
      this.wallet$.subscribe((wallet) => this._wallet = wallet)
       if(event=='dinar'){
        if(!this._wallet.IqdBallance){
          this.isIqdWallet = false;
          this._nc.open("Agent don't have Iqd wallet")
        }else{
           if(this._wallet.IqdBallance.balances[0].amount<1500){
             this._nc.open("Agent wallet don't have enough amount")
             this.isIqdWallet = false;
            }else{
               this.isIqdWallet = true;
             }
        }
       }else if(event=='business' && this._wallet.balances[0].amount<35){
        this._nc.open("Agent wallet don't have enough amount")
        this.isIqdWallet =  false;

       }else if(event=='travel' && this._wallet.balances[0].amount<15){
        this._nc.open("Agent wallet don't have enough amount")
        this.isIqdWallet =  false;
       }else{
        this.isIqdWallet = this.agentId ? true : false;
       }
    })
  }else{
    this._nc.open("Please select Agent first")
  }
      this._store.dispatch(new LoadWalletsFromUserDetailsPage(this.account.id))
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.verificationStatus$.next(this.account.verificationStatus);
  }

   public disableField(): boolean {
    return this.verificationStatus === UserAccountVerificationStatus.Kyc2Approved || 
    this.verificationStatus ===  UserAccountVerificationStatus.Kyc3Approved  || 
    this.verificationStatus ===  UserAccountVerificationStatus.Kyc3Rejected ||
    this.verificationStatus ===  UserAccountVerificationStatus.Kyc3Pending;
  }

  protected filterAgents():void {

    this.resolveAgents(this.searchAgent.value);

    // if (!this.agents) {
    //   return;
    // }
    // // get the search keyword
    // let search = this.searchAgent.value;

    // if (!search && !this.agents) {
    //   this.agents= JSON.stringify(JSON.parse(this.agents));
    //   return;
    // } else {
    //   search = search.toLowerCase();
    // }
    // filter the banks

      //this.filteredAgents= this.agents.filter(ag => (ag.fullName+ag.mobile).toLowerCase().indexOf(search) > -1);
     //  this.filteredAgents= this.agents;

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public resolveAgents(searchString):void {

    this._store.dispatch(new GetAgentListForKycApproval(state,searchString));
    this.agents$ = this._store.select(AccountsAgentKycState.agents);

    this.agents$.subscribe((data: any) => {
      //this.agents = data.agents;
       this.filteredAgents= data.agents;
    });
  }



}
