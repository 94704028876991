import {
    Gender,
} from '@michel.freiha/ng-sdk';

export class AccountProfile {

    public fullName: string;
    public email: string;
    public mobile: string;
    public arabicName?: string;
    public gender?: Gender;
    public nationality?: string;
    public dateOfBirth?: Date;
    public placeOfBirth?: string;
    public phoneNumber?: string;

    constructor(ob?: Partial<AccountProfile>) {

        this.fullName = undefined;
        this.email = undefined;
        this.mobile = undefined;
        this.arabicName = undefined;
        this.gender = undefined;
        this.nationality = undefined;
        this.dateOfBirth = undefined;
        this.placeOfBirth = undefined;
        this.phoneNumber = undefined;

        if (!ob) { return; }

        if (ob.fullName !== undefined) { this.fullName = ob.fullName; }
        if (ob.email !== undefined) { this.email = ob.email; }
        if (ob.mobile !== undefined) { this.mobile = ob.mobile; }
        if (ob.arabicName !== undefined) { this.arabicName = ob.arabicName; }
        if (ob.gender !== undefined) { this.gender = ob.gender; }
        if (ob.nationality !== undefined) { this.nationality = ob.nationality; }
        if (ob.dateOfBirth !== undefined) { this.dateOfBirth = ob.dateOfBirth; }
        if (ob.placeOfBirth !== undefined) { this.placeOfBirth = ob.placeOfBirth; }
        if (ob.phoneNumber !== undefined) { this.phoneNumber = ob.phoneNumber; }

    }
}

