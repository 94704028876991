import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  AdminSession,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  AuthState,
} from '../../../store/auth/auth.state';

import {
  ConfirmPasscode,
} from '../../../store/auth/auth.actions';


@Component({
  selector: 'nym-mobile-verification',
  templateUrl: './mobile-verification.component.html',
  styleUrls: ['./mobile-verification.component.scss'],
  // animations: ANIMATIONS,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class MobileVerificationComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;

  private _session: AdminSession;
  private _username: string;

  @Select(AuthState.problem)
  public _problem$: Observable<Problem>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  public get username(): string {
    return this._username;
  }

  public form: FormGroup;

  public get code(): AbstractControl {
    return this.form.get('code');
  }

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _ph: ProblemHandler,
  ) {
    this._session = this._store.selectSnapshot(AuthState.session);
    this._username = this._store.selectSnapshot(AuthState.username);

    this.form = this._fb.group({
      code: [
        '',
        [
          Validators.required,
          Validators.maxLength(6),
          Validators.minLength(6),
          Validators.pattern('[0-9]*'),
        ],
      ],
    });
  }

  public ngOnInit(): void {

    this._subscription = this._problem$.subscribe((problem) => {
      this._ph.handle(problem, this.form, ['code']);
    });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public submit(): void {
    if (!this.form.valid)
      return;

    let id = this._session.id;
    let code = this.code.value;

    this._store.dispatch(new ConfirmPasscode({ id: id, code: code }));
  }
}
