import {
    Injectable,
} from '@angular/core';

import {
    DomSanitizer,
} from '@angular/platform-browser';

import {
    MatIconRegistry,
} from '@angular/material/icon';


const ASSETS_FOLDER = 'assets/accounts/';
const CARD_ACTIVITY_CATEGORY_FOLDER = ASSETS_FOLDER + 'card-activity/';
const WALLET_ACTIVITY_CATEGORY_FOLDER = ASSETS_FOLDER + 'wallet-activity/';
const SORT_CATEGORY_FOLDER = ASSETS_FOLDER + 'sort/';

@Injectable({
    providedIn: 'root',
})
export class AccountsAssets {

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        iconRegistry.addSvgIconInNamespace('user', 'home', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'user-search.svg'));
        iconRegistry.addSvgIconInNamespace('user', 'empty', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'user-empty.svg'));
        iconRegistry.addSvgIconInNamespace('card', 'empty', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'user-empty.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'empty', sanitizer.bypassSecurityTrustResourceUrl(ASSETS_FOLDER + 'user-empty.svg'));

        iconRegistry.addSvgIconInNamespace('card-activity', 'bills', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'bills.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'dining', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'dining.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'entertainment', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'entertainment.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'expenses', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'expenses.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'finances', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'finances.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'general', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'general.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'groceries', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'groceries.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'holidays', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'holidays.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'home', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'home.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'lifestyle', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'lifestyle.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'wellness', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'wellness.svg'));
        iconRegistry.addSvgIconInNamespace('card-activity', 'transportation', sanitizer.bypassSecurityTrustResourceUrl(CARD_ACTIVITY_CATEGORY_FOLDER + 'transportation.svg'));

        iconRegistry.addSvgIconInNamespace('wallet-activity', 'credit', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'top-up.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'debit', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'card-issuance.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'transfer', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'card-issuance.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'card_to_card', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'transfer.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'card_issuance', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'card-issuance.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'card_reload', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'card-issuance.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'card_top_up', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'cardreload.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'debit_fee', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'withdraw.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'refund', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'refund.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'card_refund', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'cardrefund.svg'));
        iconRegistry.addSvgIconInNamespace('wallet-activity', 'mobile_verified', sanitizer.bypassSecurityTrustResourceUrl(WALLET_ACTIVITY_CATEGORY_FOLDER + 'mobile-verified.svg'));

        iconRegistry.addSvgIconInNamespace('sort', 'sort-alp', sanitizer.bypassSecurityTrustResourceUrl(SORT_CATEGORY_FOLDER + 'sort-alp.svg'));
        iconRegistry.addSvgIconInNamespace('sort', 'sort-24-px', sanitizer.bypassSecurityTrustResourceUrl(SORT_CATEGORY_FOLDER + 'sort-24-px.svg'));

    }

}
