import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Admin,
} from '@michel.freiha/ng-sdk';

import {
  WalletActivity,
} from '@nymos/accounts/core';


@Component({
  selector: 'nym-wallet-activity-listing',
  templateUrl: './wallet-activity-listing.component.html',
  styleUrls: ['./wallet-activity-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletActivityListingComponent implements OnInit {

  @HostBinding('class.nym-wallet-activity-listing')
  protected get classes(): boolean { return true; }

  @Output()
  protected loadMore: EventEmitter<any> = new EventEmitter();

  @Output()
  protected manageWalletActivity: EventEmitter<any> = new EventEmitter();

  @Input()
  public title: string;

  @Input()
  public activities: WalletActivity[];

  @Input()
  public loading: boolean;

  @Input()
  public hasMore: boolean;

  @Input()
  public columns: string[];

  @Input()
  public walletActivities: WalletActivity[];

  @Input()
  public walletActivitiesLoading: boolean;

  constructor() { }

  public ngOnInit(): void { }


  protected get firstWalletActivities(): WalletActivity[] {
    let copyWalletActivities = this.activities;
    let usdWallet = copyWalletActivities.filter((res)=>{return res.currency=='USD'});
    return this.walletActivitiesLoading ? usdWallet : usdWallet;
  }

  protected get secondWalletActivities(): WalletActivity[] {
    let copyWalletActivities = this.activities;
    let iqdWallet = copyWalletActivities.filter((res)=>{return res.currency=='IQD'});
    return this.walletActivitiesLoading ? iqdWallet : iqdWallet;
  }



  protected loadMore$$(): void {
    this.loadMore.emit();
  }

  protected manageWalletActivity$$(event: any): void {
    this.manageWalletActivity.emit(event);
  }

}
