import * as faker from 'faker';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import {
  Actions,
  ofActionSuccessful,
  Store
} from '@ngxs/store';
import {
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';
import {
  IssueFromHomePage,
  AccountProfile,
} from '@nymos/bulkcards/core';
import { Observable } from 'rxjs';
import { CardHandler } from '../../../services/card-handler.service';
import { Account, AccountsAgentsState, Card } from '@nymos/accounts/core';

@Component({
  selector: 'nym-issue-card',
  templateUrl: './issue-card.component.html',
  styleUrls: ['./issue-card.component.scss']
})
export class IssueCardComponent extends ModalComponent implements OnInit, OnDestroy {

  private _id: string;
  private _account: Account;
  public _card: Card[];
  public dinar:boolean = true;
  public business: boolean = true;
  public travel: boolean = true;

  @HostBinding('class.nym-issue-card')
  protected get classes(): boolean { return true; }

  protected form: FormGroup;
  protected agents$: Observable<AccountProfile[]>;
  protected account$: Observable<Account>;
  protected accountId: string;

  public get type(): AbstractControl { return this.form.get('type'); }
  public get currency(): AbstractControl { return this.form.get('currency'); }
  public get amount(): AbstractControl { return this.form.get('amount'); }
  public selectAmount: FormControl = new FormControl('');
  private card_currency;
  cardSelected = '';

  constructor(
    _fb: FormBuilder,
    _ref: DialogRef<any, any>,
    _cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) locale: string,
    @Inject(DIALOG_DATA) data: any,
    protected _store: Store,
    protected _actions$: Actions,
    protected cardSrv: CardHandler
  ) {
    super(_fb, _ref, _cd, locale, data);

    const router = this._store.selectSnapshot((state) => state.router.state);

    this._id = faker.random.uuid();
    this.accountId = router.params.id;
    this.account$ = this._store.select(AccountsAgentsState.account(this.accountId));
    this.account$.subscribe((account) => {
      this._account = account;
    });

    

    this.initForm(data);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._actions$.pipe(ofActionSuccessful(IssueFromHomePage)).subscribe(() => this._ref.close());
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.data.cards.subscribe((card)=>{
      this._card = card;
      this._card.forEach(element => {
        if(element.name === 'Dinar Card' && element.status!=='terminated'){
          this.dinar = false;
        }if(element.name === 'Business Card' && element.status!=='terminated'){
          this.business = false;
        }if(element.name === 'NEO Prepaid Travel' && element.status!=='terminated'){
          this.travel = false;
        }
      });
    })

    this.type.setValidators([Validators.required]);
    this.amount.disable();
    this.type.updateValueAndValidity();
  }

  // @Override
  protected initForm(data: any): void {

    this.form = this._fb.group({
      type: ['', [
        Validators.required,
      ]],
      currency: ['usd', [
        Validators.required,
      ]],
      amount: [0],
    });
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    let wallet_id = ''
    if(this.type.value=='dinar'){
      wallet_id = this.data.wallet.IqdBallance.internalId 
     }else{
       wallet_id = this.data.wallet.internalId 
     }
    this.cardSrv.issueCard(this.accountId,wallet_id, this.type.value, '0');
    this.close();
  }

  public cardChange$$(): void {
    this.amount.patchValue(0);
  }

  isNumber(evt: any) {
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 
      && (charCode < 48 || charCode > 57))
       return false;

    return true;
  }

  selectCardType(card_type) {
    this.cardSelected = card_type;
    if(card_type == 'dinar'){
      this.card_currency = 'IQD'
    }else{
      this.card_currency = 'USD'
    }
  }
}