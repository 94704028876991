import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  AdminSession,
  PasswordChange,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  PasswordVisibility,
} from '../../../models/password-visibility.model';

import {
  CustomValidators,
} from '../../../validators/form.validator';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  AuthState,
} from '../../../store/auth/auth.state';

import {
  ChangePassword,
} from '../../../store/auth/auth.actions';


@Component({
  selector: 'nym-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // animations: ANIMATIONS,
  providers: [ProblemHandler],
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;

  private _session: AdminSession;
  private _username: string;
  private _password: string;

  @Select(AuthState.problem)
  public _problem$: Observable<Problem>;

  @Select(AuthState.authenticating)
  public authenticating$: Observable<boolean>;

  public get username(): string {
    return this._username;
  }

  public passwordMode: PasswordVisibility = PasswordVisibility.Hidden;

  public form: FormGroup;

  public get password(): AbstractControl {
    return this.form.get('password');
  }

  public get confirm(): AbstractControl {
    return this.form.get('confirm');
  }

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _ph: ProblemHandler,
  ) {
    this._session = this._store.selectSnapshot(AuthState.session);
    this._username = this._store.selectSnapshot(AuthState.username);
    this._password = this._store.selectSnapshot(AuthState.password);

    this.form = this._fb.group(
      {
        password: ['', Validators.required],
        confirm: ['', Validators.required],
      },
      {
        validator: CustomValidators.match('password', 'confirm'),
      },
    );
  }

  public ngOnInit(): void {
    this._subscription = this._problem$.subscribe((problem) => {
      this._ph.handle(problem, this.form, [{ from: 'newPassword', to: 'password' }]);
    });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public submit(): void {
    if (!this.form.valid)
      return;

    const passwordChange = new PasswordChange({
      oldPassword: this._password,
      newPassword: this.form.controls['password'].value,
    });

    this._store.dispatch(
      new ChangePassword({
        id: this._session.id,
        passwordChange: passwordChange,
      }),
    );
  }

  public togglePassword(): void {
    this.passwordMode.toggle();
  }
}
