import {
    NgModule,
} from '@angular/core';

import {
    RouterModule,
    Routes,
} from '@angular/router';

import {
    ScopesGuard,
} from '@nymos/auth';

import {
    SearchGuard,
} from '@nymos/dashboard/shared';

import {
    AgentExistsGuard,
} from './guards/agent-exists.guard';

import {
    UserExistsGuard,
} from './guards/user-exists.guard';

import {
    SearchHomepageComponent,
} from './ui/containers/search-homepage/search-homepage.component';

import {
    ContentComponent,
} from './ui/layout/content/content.component';

import {
    ChannelComponent,
} from './ui/layout/channel/channel.component';

import {
    SearchResultsComponent,
} from './ui/containers/search-results/search-results.component';

import {
    ChannelResultsComponent,
} from './ui/containers/channel-results/channel-results.component';

import {
    AgentCreationComponent,
} from './ui/containers/agent-creation/agent-creation.component';

import {
    AgentDetailsComponent,
} from './ui/containers/agent-details/agent-details.component';

import {
    UserDetailsComponent,
} from './ui/containers/user-details/user-details.component';

import {
    UserEditComponent,
} from './ui/containers/user-edit/user-edit.component';
import { UserViewReportComponent } from './ui/containers/user-view-report/user-view-report.component';


export const routes: Routes = [
    {
        path: '',
        component: SearchHomepageComponent,
        canActivate: [ScopesGuard],
        data: {
            scopes: {
                forwardTo: {
                    'p:channels:read': '/accounts/channel/active',
                },
            },
        },
    },
    {
        path: '',
        component: ContentComponent,
        children: [

            { path: 'search', component: SearchResultsComponent, runGuardsAndResolvers: 'paramsOrQueryParamsChange', canActivate: [SearchGuard] },
            { path: 'agent/new', component: AgentCreationComponent },
            { path: ':id/agent/details', component: AgentDetailsComponent, canActivate: [AgentExistsGuard] },
            { path: ':id/user/details', component: UserDetailsComponent, canActivate: [UserExistsGuard] },
            { path: ':id/user/edit', component: UserEditComponent, canActivate: [UserExistsGuard] },
            { path: ':id/user/report', component: UserEditComponent, canActivate: [UserExistsGuard] },
            {
                path: 'channel',
                component: ChannelComponent,
                children: [
                    { path: ':channel', component: ChannelResultsComponent, runGuardsAndResolvers: 'paramsOrQueryParamsChange', },
                ],
                canActivate: [ScopesGuard],
                data: {
                    scopes: {
                        only: ['p:channels:read'],
                        redirectTo: '/accounts',
                    },
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AccountsRoutingModule { }
