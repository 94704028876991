/* tslint:disable:member-ordering */

import {
    Inject,
} from '@angular/core';

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    forkJoin,
} from 'rxjs';

import {
    catchError,
    finalize,
    tap,
} from 'rxjs/operators';

import {
    AccountChannelIndex,
    NymcardAccountLimitsInternal,
    PasscodeResetRequest,
    UserAccountUpdateRequest,
    Problem,
    UserAccountInternal,
    NymcardAccountCardsLimitInternal,
    NymcardAccountCreditsLimitInternal,
    IdvDocuments,
    IdvChecksPublic,
    IdvChecks,
    IdvVerificationReports,
    IdvDocumentsDownload,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    AccountBuilder,
} from '../../builders/account.builder';

import {
    AccountsOperations,
} from '../accounts.operations';

import {
    FailFromApi,
    BlockAccountFromUserDetailsPage,
    GetAccountFromUserDetailsPage,
    GetAccountUserLimit,
    GetAccountFromUserExistsGuard,
    RefreshAccountFromUserApi,
    RefreshAccountFromUserDetailsPage,
    RejectAccountFromUserDetailsPage,
    ResetPasscodeFromUserDetailsPage,
    UnblockAccountFromUserDetailsPage,
    VerifyAccountFromUserDetailsPage,
    UpdateAccountFromUserEditDetailsPage,
    SetStatusLoadingFromStatusMenu,
    GetUserIdvDocumentStatus,
    GetUserIdvCheck,
    GetIdvReport,
    DownloadUserIdvDocument
} from './accounts-users.actions';

import {
    Notifications,
} from './accounts-users.notifications';
import produce from 'immer';


export interface AccountsUsersStateModel {
    limits: { [id: string]: NymcardAccountLimitsInternal };
    cardLimits: { [id: string]: NymcardAccountCardsLimitInternal };
    cardMultiLimits: { [id: string]: [] };
    internals: { [id: string]: UserAccountInternal };
    indexes: { [id: string]: AccountChannelIndex };
    idvDocument: IdvDocuments,
    idvCheck:IdvChecks
    idvReportResult:IdvVerificationReports
    loading: boolean;
    saving: boolean;
    problem: Problem;
    statusLoading:boolean;
}

const stateDefaults: AccountsUsersStateModel = {
    limits: {},
    cardLimits: {},
    cardMultiLimits: {},
    internals: {},
    indexes: {},
    idvDocument:undefined,
    idvCheck:undefined,
    idvReportResult:undefined,
    loading: undefined,
    saving: undefined,
    problem: undefined,
    statusLoading:undefined
};

@State<AccountsUsersStateModel>({
    name: 'users',
    defaults: stateDefaults,
})
export class AccountsUsersState extends AccountsOperations<any> {

    public static account(id: string): any {
        return createSelector([AccountsUsersState], (state: AccountsUsersStateModel) => {
            // TODO(@leandro): Return specific view model for user accounts
            return new AccountBuilder(this.uploadOptions)
                .withIndex(state.indexes[id])
                .withUser(state.internals[id])
                .withLimits(state.limits[id])
                .withCardLimits(state.cardLimits[id])
                .withCardMultiLimits(state.cardMultiLimits[id])
                .build();
        });
    }
    
    @Selector()
    public static problem(state: AccountsUsersStateModel): Problem {
        return state.problem;
    }

     @Action(FailFromApi)
    public fail(ctx: StateContext<AccountsUsersStateModel>, { payload: { problem } }: FailFromApi): void {
        ctx.patchState({ problem: problem });
        this.nc.show(Notifications.Failure);
    }

    @Selector()
    public static loading(state: AccountsUsersStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static statusLoading(state: AccountsUsersStateModel): boolean {
        return state.statusLoading;
    }

    @Selector()
    public static AccountIdvChecks(state: AccountsUsersStateModel): IdvChecks {
        return state.idvCheck;
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsUsersStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(GetAccountFromUserDetailsPage)
    @Action(GetAccountFromUserExistsGuard)
    public loadUser(ctx: StateContext<AccountsUsersStateModel>, { id }: any): any {

        const update = this.updateItem(ctx, id, Notifications.Loading);

        ctx.patchState({ loading: true });
        return this._loadUser(ctx, id).pipe(update).pipe(
            finalize(() => ctx.patchState({ loading: false })),
        );
    }


   
    @Action(GetAccountUserLimit)
    public loadUserLimit(ctx: StateContext<AccountsUsersStateModel>, { id }: any): any {
         ctx.patchState({ loading: true });
         return this.loadLimitInternal(ctx, id).pipe(
            finalize(() => ctx.patchState({ loading: false })),
        );
       }  
       
       @Action(GetUserIdvDocumentStatus)
       public getUserIdvDocumentStatus(ctx: StateContext<AccountsUsersStateModel>, { accountId }: any): any {
            ctx.patchState({ loading: true });
            return this.getUserIdvDocument(ctx, accountId).pipe(
                tap((idvDocumentStatus: IdvDocuments) => {
                  ctx.setState(produce((state) => {
                    state.idvDocument = idvDocumentStatus;
                  }));
                }),
          
                catchError((problem) => {
                  return ctx.dispatch(new FailFromApi(problem));
                }),
          
                finalize(() => {
                  ctx.patchState({ loading: false });
                }),
              );
          } 
          
          @Action(GetIdvReport)
          public getIdvReport(ctx: StateContext<AccountsUsersStateModel>, { reportId,accountId }: any): any {
               ctx.patchState({ loading: true });
               return this.getUserIdvReport(ctx, reportId,accountId).pipe(
                   tap((idvReportResult: IdvVerificationReports) => {
                     ctx.setState(produce((state) => {
                       state.idvReportResult = idvReportResult;
                     }));
                   }),
             
                   catchError((problem) => {
                     return ctx.dispatch(new FailFromApi(problem));
                   }),
             
                   finalize(() => {
                     ctx.patchState({ loading: false });
                   }),
                 );
             } 

          @Action(GetUserIdvCheck)
          public getUserIdvCheck(ctx: StateContext<AccountsUsersStateModel>, { userId,isAgent }: any): any {
               ctx.patchState({ loading: true });
               return this.getIdvCheck(ctx, userId, isAgent).pipe(
                   tap((idevCheck: IdvChecks) => {
                     ctx.setState(produce((state) => {
                       state.idvCheck = idevCheck;
                     }));
                   }),
             
                   catchError((problem) => {
                     return ctx.dispatch(new FailFromApi(problem));
                   }),
             
                   finalize(() => {
                     ctx.patchState({ loading: false });
                   }),
                 );
             } 
             
             
          @Action(DownloadUserIdvDocument)
          public downloadUserIdvDocument(ctx: StateContext<AccountsUsersStateModel>, { userId, documentId }: any): any {
               ctx.patchState({ loading: true });
               return this.downloadUserIdvDoc(ctx, userId, documentId).pipe(
                   tap((downloadUserIdvDocumentResult: IdvDocumentsDownload) => {
                     ctx.setState(produce((state) => {
                       state.downloadUserIdvDocumentResult = downloadUserIdvDocumentResult;
                     }));
                   }),
             
                   catchError((problem) => {
                     ctx.setState(produce((state) => {
                        state.downloadUserIdvDocumentResult = [];
                      }));
                     return ctx.dispatch(new FailFromApi(problem));
                   }),
             
                   finalize(() => {
                     ctx.patchState({ loading: false });
                   }),
                 );
             } 

    @Action(RefreshAccountFromUserDetailsPage)
    @Action(RefreshAccountFromUserApi)
    public refreshUser(ctx: StateContext<AccountsUsersStateModel>, { id }: any): any {
        const refresh = this.refreshItem(ctx, id);
        return this._loadUser(ctx, id).pipe(refresh);
    }

    @Action(ResetPasscodeFromUserDetailsPage)
    public resetPasscode(ctx: StateContext<AccountsUsersStateModel>, { id, note }: any): any {

        this.nc.show(Notifications.PasscodeReset);

        return this.passcodeService.resetPasscode(id, new PasscodeResetRequest({ note: note })).pipe(
            finalize(() => this.nc.show(Notifications.PasscodeReseted)),
        );
    }

    @Action(VerifyAccountFromUserDetailsPage)
    public verifyUser(ctx: StateContext<AccountsUsersStateModel>, {id ,docType}: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel !== 'pending', RefreshAccountFromUserApi);
        if(docType=='KYC1')
           return this.userService.approveUserAccount(id).pipe(update);
        else if(docType=='KYC3')   
            return this.userService.approveKyc3(id).pipe(update);
        else
          return this.userService.approveKyc2(id).pipe(
             catchError((problem) => {
                  return ctx.dispatch(new FailFromApi({ problem: problem }));
              }),
              finalize(() => {
               ctx.patchState({ loading: false})})
              
            );
    }

    @Action(UpdateAccountFromUserEditDetailsPage)
    public editUser(ctx: StateContext<AccountsUsersStateModel>,  {id, user}: any): any {
        return this.userService.
        update(id,new UserAccountUpdateRequest(user));
    }

    @Action(RejectAccountFromUserDetailsPage)
    public rejectUser(ctx: StateContext<AccountsUsersStateModel>, { id, note ,docType}: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel !== 'pending', RefreshAccountFromUserApi);
         if(docType=='KYC1')
            return this.userService.rejectUserAccount(id, note).pipe(update);
        else if(docType=='KYC3')
            return this.userService.rejectKyc3(id, note).pipe(update);
        else
           return this.userService.rejectKyc2(id,note).pipe(update);
        
    }

    @Action(BlockAccountFromUserDetailsPage)
    public blockAccount(ctx: StateContext<AccountsUsersStateModel>, { id , note}: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel === 'blocked', RefreshAccountFromUserApi);
        return this.accountService.blockAccount(id, note).pipe(update);
    }

    @Action(UnblockAccountFromUserDetailsPage)
    public unblockAccount(ctx: StateContext<AccountsUsersStateModel>, { id, note }: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel !== 'blocked', RefreshAccountFromUserApi);
        return this.accountService.unblockAccount(id,note).pipe(update);
    }

    @Action(SetStatusLoadingFromStatusMenu)
    public setLoadingStatus(ctx: StateContext<AccountsUsersStateModel>,  flag: boolean): any {
        ctx.patchState({ statusLoading: flag["flag"]});
    }

    private _loadUser(ctx: StateContext<AccountsUsersStateModel>, id: string): any {
        return forkJoin({
            index: this.loadChannelIndex(ctx, id),
            internal: this._loadUserInternal(ctx, id),
            limits: this.loadLimitInternal(ctx, id),
            cardLimit: this.loadCardsLimitInternal(ctx,id),
            cardMultiLimit: this.loadCardsMultiLimitInternal(ctx,id),
        });
    }

    private _loadUserInternal(ctx: StateContext<AccountsUsersStateModel>, id: any): any {
        return this.userService.loadUserAccount(id);
    }

}
