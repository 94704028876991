import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  SimpleChanges,
  Inject,
  OnInit,
  OnDestroy,
  OnChanges
} from '@angular/core';

import {
  Subscription,
  Observable,
  interval,
} from 'rxjs';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  Dialog,
  DIALOG_DATA,
} from '@nymos/theme';

import {
  SearchCriteria,
  SearchFilter,
  SearchOperator,
} from '@michel.freiha/ng-sdk';

import {
  AddNoteFromPage,
  LoadCardNotesFromPage,
  Note,
  NoteConfirmComponent,
  NotesState,
} from '@nymos/notes';

import {
  Account,
  Card,
  CardAction,
  CardActivity,
  Texts,
  Wallet,
} from '@nymos/accounts/core';

import {
  CardHandler,
} from '../../../services/card-handler.service';

import {
  CardsState,
} from '../../../core/store/cards/cards.state';

import {
  AccountsState,
} from '../../../core/store/accounts/accounts.state';

import {
  ActivateFromCardDetailsPage,
  ResumeFromCardDetailsPage,
  SuspendFromCardDetailsPage,
  TerminateFromCardDetailsPage,
  LoadCardFromUserDetailsPage,
} from '../../../core/store/cards/cards.actions';

import {
  CardActivitiesState,
} from '../../../core/store/card-activities/card-activities.state';

import {
  LoadMoreCardActivitiesFromCardDetailsPage,
} from '../../../core/store/card-activities/card-activities.actions';

import {
    DryRunTransferBetweenWalletFromUserDetailsPage,
    TransferBetweenWalletFromUserDetailsPage
 } from '../../../core/store/wallet-activities/wallet-activities.actions';
import { ChargebackCreateComponent } from '../chargeback-create/chargeback-create.component';
import { WalletsState } from '../../../core/store/wallets/wallets.state';

@Component({
  selector: 'nym-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardDetailsComponent implements OnInit,OnDestroy,OnChanges {

  private _data: Card;
  private _subscription: Subscription = new Subscription();
  protected cardNotes$: Observable<Note[]>;

  @HostBinding('class.nym-card-details')
  protected get classes(): boolean { return true; }

  protected account$: Observable<Account>;
  protected card$: Observable<Card>;
  private _wallet: Wallet;

  protected cardActivities$: Observable<CardActivity[]>;
  
  @Select(WalletsState.mainWallet)
  public wallet$: Observable<Wallet>;

  @Select(CardActivitiesState.loading)
  public loading$: Observable<boolean>;

  @Select(CardActivitiesState.hasMore)
  public hasMore$: Observable<boolean>;

  constructor(
    private _store: Store,
    protected _dialog: Dialog,
    private _cardHandler: CardHandler,
    @Inject(DIALOG_DATA) data: Card,
  ) {


    const criteria = new SearchCriteria({
      filters: [new SearchFilter({ field: 'account_id', operator: SearchOperator.AnyOf, values: [data.accountId] })],
    });

    this._data = data;
    this.card$ = this._store.select(CardsState.card(data.id));
    this.account$ = this._store.select(AccountsState.account(data.accountId));
    this.cardActivities$ = this._store.select(CardActivitiesState.cardActivities(data.id));

    

      this._subscription.add(interval(5000).subscribe(() => {
       this._store.dispatch([
             new LoadCardNotesFromPage(criteria),
         ]);
     }));

    this.cardNotes$ = this._store.select(NotesState.cardNotes(data.accountId));
    this._subscription.add(this.wallet$.subscribe((wallet) => this._wallet = wallet));

   
  }

  public ngOnInit(): void  {

  }

   public ngOnChanges(changes: SimpleChanges): void {
    this.cardNotes$ = this._store.select(NotesState.cardNotes(this._data.accountId));
  }

   public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected changeStatus(status: CardAction): any {
    // tslint:disable-next-line: switch-default
    switch (status) {
      case 'activate':
        return this._cardHandler.activate(this._data, ActivateFromCardDetailsPage);

      case 'resume':
        return this._cardHandler.resume(this._data, ResumeFromCardDetailsPage);

      case 'suspend':
        return this._cardHandler.suspend(this._data, SuspendFromCardDetailsPage);

      case 'terminate':
        return this._cardHandler.terminate(this._data,this._wallet, DryRunTransferBetweenWalletFromUserDetailsPage,TransferBetweenWalletFromUserDetailsPage,TerminateFromCardDetailsPage);

      case 'refund':   
       return this._cardHandler.refund(this._data,this._wallet,DryRunTransferBetweenWalletFromUserDetailsPage,TransferBetweenWalletFromUserDetailsPage);
    }
  }

   protected loadMore(): void {
    this._store.dispatch(new LoadMoreCardActivitiesFromCardDetailsPage());
  }

  protected dispute(transactionData): any {
    const text = 'Create Chargeback';
    this._dialog.openAsModal(ChargebackCreateComponent, { width:'400px',data: {
      text,
      transactionData,
      confirm: { label: 'Submit' },
      cancel: { label: 'Cancel' },
    }});
  }


}
