import * as faker from 'faker';

import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  AbstractControl, FormControl, FormBuilder, FormGroup, Validators,
} from '@angular/forms';



import {
  Actions,
  ofActionSuccessful,
  Store,
} from '@ngxs/store';

// import {
//   NymcardCardCreateBulkDetails as BulkDetails,
// } from '@michel.freiha/ng-sdk';

import { state } from '@angular/animations';



import {
  DIALOG_DATA,
  DialogRef,
  ModalComponent,
} from '@nymos/theme';

import {
  ProblemHandler,
} from '@nymos/problems';

// import {
//   IssueFromHomePage,
//   GetAgentListForKycApproval,
//   IssuancesState,
//   AccountProfile,
// } from '@nymos/bulkcards/core';

//import { AccountProfile } from '../../../core/core';

//import { AccountsAgentKycState } from '../../../core/store/accounts-agent-kyc/
//accounts-agent-kyc.state';

import { CreateDispute, LoadCardActivitiesFromUserDetailsPage } from '../../../core/store/card-activities/card-activities.actions';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'nym-chargeback-create',
  templateUrl: './chargeback-create.component.html',
  styleUrls: ['./chargeback-create.component.scss']
})
export class ChargebackCreateComponent extends ModalComponent implements OnInit, OnDestroy {

  private _id: string;

  @HostBinding('class.nym-chargeback-create')
  protected get classes(): boolean { return true; }

  protected disputForm: FormGroup;
  public transactionData: any;

  // protected agents$: Observable<AccountProfile[]>;

  public get type(): AbstractControl { return this.disputForm.get('type'); }
  public get currency(): AbstractControl { return this.disputForm.get('currency'); }
  public get amount(): AbstractControl { return this.disputForm.get('amount'); }
  public get note(): AbstractControl { return this.disputForm.get('note'); }

  public selectAmount: FormControl = new FormControl('');

  accountId: string;

  constructor(
    fb: FormBuilder,
    ref: DialogRef<any, any>,
    cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) locale: string,
    @Inject(DIALOG_DATA) data: any,
    protected _store: Store,
    protected _actions$: Actions,
  ) {
    super(fb, ref, cd, locale, data);

    this._id = faker.random.uuid();
    this.transactionData = data.transactionData.transaction;
    this.initForm(data);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    //this._actions$.pipe(ofActionSuccessful(IssueFromHomePage)).subscribe(() => this._ref.close());
    //this.filterAgents();

    const router = this._store.selectSnapshot((state) => state.router.state);
    this.accountId = router.params.id;
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.type.setValidators([Validators.required]);
    //this.amount.setValidators([Validators.required]);
    this.type.updateValueAndValidity();
    //this.amount.updateValueAndValidity();
  }

  // @Override
  protected initForm(data: any): void {
    this.disputForm = this._fb.group({

      type: ['', [
        Validators.required,
      ]],
      note: ['',],

      currency: [{ value: 'usd', disabled: false }, [
        Validators.required,
      ]],

      amount: [{ value: '', disabled: false }, [
        Validators.required,
        Validators.min(1), Validators.max(this.transactionData.amount),
        //this.validateSequence,
      ]],

    });
  }

  // @Override
  protected close(): void {
    this._ref.close();
  }

  // @Override
  protected submit(): void {
    if (!this.disputForm.valid)
      return;

    const chargebackDetails = {
      transactionId: this.transactionData.id,
      notes: this.note.value,
      amount: +this.amount.value,
    };

    this.data.transactionData.disputeBtn.target.parentElement.classList.add('disabled-dispute');
    this._ref.close();

    this._store.dispatch(new CreateDispute(chargebackDetails)).pipe(delay(3000)).subscribe(
      resp => {
        this._store.dispatch(
          new LoadCardActivitiesFromUserDetailsPage(this.accountId)
        );
      }
    );
  }

  public changeAmountType$$(amountType: string): void {
    if (amountType == 'full') {
      this.amount.patchValue(this.transactionData.amount)
      this.disputForm.get('amount').updateValueAndValidity();
    }
    
    if (amountType == 'partial') {
      this.amount.patchValue('1')
      this.disputForm.get('amount').updateValueAndValidity();
    }
    // if(card=='006'){
    //   this.amount.patchValue('0')
    //   this.form.get('agent').setValidators([Validators.required])
    //   this.form.get('agent').updateValueAndValidity()
    //   this.form.get('amount').updateValueAndValidity()
    // }
    // if(card=='005'){
    //   this.amount.patchValue('')
    //   this.form.get('amount').setValidators([Validators.required])
    //   this.form.get('agent').setValidators([])
    //   this.form.get('agent').updateValueAndValidity()
    //   this.form.get('amount').updateValueAndValidity()
    // }

  }

  public resolveAgents(searchString): void {

    //this._store.dispatch(new GetAgentListForKycApproval(state,searchString));
    // this.agents$ = this._store.select(IssuancesState.agents);

    // this.agents$.subscribe((data: any) => {
    //    this.filteredAgents= data.agents;
    //    for(var i=0;i<this.filteredAgents.length;i++){
    //      if(this.filteredAgents[i].fullName==this.agent.value.fullName){
    //         this.agent.patchValue(this.filteredAgents[i])
    //         this.form.get('agent').updateValueAndValidity();
    //         break;
    //       }
    //     }
    // });
  }

  validateSequence(control: AbstractControl) {
    let value = control.value;
    if (value != null) {
      let digits = value.toString().split('');

      // Iterate through pairs of values
      // As long as one comparison is not consecutive, the PIN is valid
      for (var i = 0; i < digits.length - 1; i++) {
        if (Math.abs(parseInt(digits[i]) - parseInt(digits[i + 1])) > 1) {
          return null;
        }
      }
      return { 'expPatteren': true };
    }
  }

}
