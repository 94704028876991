import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  Observable,
} from 'rxjs';

import {
  Store,
} from '@ngxs/store';


import {
  Note,
} from '@nymos/notes';

import {
  Account,
  ChannelStatus,
  StatusMenu,
} from '@nymos/accounts/core';

import {
  AccountsChannelsState,
} from '../../../../core/store/accounts-channels/accounts-channels.state';
import { IdvDocuments } from '@michel.freiha/ng-sdk';

@Component({
  selector: 'nym-agent-details-aside',
  templateUrl: './agent-details-aside.component.html',
  styleUrls: ['./agent-details-aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentDetailsAsideComponent implements OnInit, OnChanges {
  public isUserType:any;
  @HostBinding('class.nym-agent-details-aside')
  protected get classes(): boolean { return true; }

  @Output('changeChannel') // tslint:disable-line:no-output-rename
  protected changeEmitter: EventEmitter<ChannelStatus> = new EventEmitter();

  @Output('addNote') // tslint:disable-line:no-output-rename
  protected addNoteEmitter: EventEmitter<ChannelStatus> = new EventEmitter();

  @Output('viewAttachments') // tslint:disable-line:no-output-rename
  protected viewAttachmentsEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  protected resetPasscode: EventEmitter<any> = new EventEmitter();

  protected statusMenu$: Observable<StatusMenu>;
  protected statusesMenu$: Observable<StatusMenu[]>;
  @Input()
  public account: Account;

  @Input()
  public idvDocument: IdvDocuments;

  @Input()
  public notes: Note[];

  protected get channel(): string { return this.account && this.account.channel.channel; }

  constructor(
    private _store: Store,
  ) {
    this.statusesMenu$ = this._store.select(AccountsChannelsState.channels);

  }

  public ngOnInit(): void {  this.isUserType = {"name":'agent'} } 

  public ngOnChanges(changes: SimpleChanges): void {
    const accountChanges = changes['account'];

    if (accountChanges) {
      this.statusMenu$ = this._store.select(AccountsChannelsState.channel(this.channel));
    }
  }

  public resetPasscode$$(): void {
    this.resetPasscode.emit();
  }

  protected addNote(): any {
    this.addNoteEmitter.emit();
  }

  protected changeChannel(channel: ChannelStatus): any {
    this.changeEmitter.emit(channel);
  }

  protected viewAttachments$$(event: any): void {
    this.viewAttachmentsEmitter.emit(event);
  }

}
