import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  FileUploadRef,
  IdvDocumentsDownload,
  IdvVerificationReports,
  UserProfile,
} from '@michel.freiha/ng-sdk';

import {
  Account, Attachment,
} from '@nymos/accounts/core';
import { ImageService } from '../../../services/image.service';

@Component({
  selector: 'nym-user-idv-report-result',
  templateUrl: './user-idv-report-result.component.html',
  styleUrls: ['./user-idv-report-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIdvReportResultComponent {

  @HostBinding('class.nym-user-personal-info')
  protected get classes(): boolean { return true; }

  @Output('viewAttachments') // tslint:disable-line:no-output-rename
  protected viewAttachments: EventEmitter<any> = new EventEmitter();

  @Input()
  public account: Account;

  @Input()
  public idvReportResult: IdvVerificationReports

  @Input()
  public downloadUserIdvDocumentResult: IdvDocumentsDownload[] = [];

  protected selfie: FileUploadRef;
  protected selfieImageUrl: string

  public reportResult: any;
  public isPropertiesExpand: boolean = false;
  public isBreakdownExpand: boolean = false;

  isBreakdownSubExpand = true;
  expandedBreakdownStates: { [key: string]: boolean } = {};
  expandedPropertiesStates: { [key: string]: boolean } = {};

  constructor(private _cd: ChangeDetectorRef,
    private _imageService: ImageService) {
  }

  protected get profile(): UserProfile { return this.account && this.account.profile; }

  getKeys(obj: any): string[] {
    return obj ? Object.keys(obj).filter(key => obj[key] !== undefined) : [];
  }

  toggleExpandBreakdown(key: string): void {
    this.expandedBreakdownStates[key] = !this.expandedBreakdownStates[key];
  }

  isBreakdownExpanded(key: string): boolean {
    return !!this.expandedBreakdownStates[key];
  }

  toggleExpandProperties(key: string): void {
    this.expandedPropertiesStates[key] = !this.expandedPropertiesStates[key];
  }

  isPropertiesExpanded(key: string): boolean {
    return !!this.expandedPropertiesStates[key];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.profile) {
      const selfieImage = this.profile.selfie

      if (selfieImage) {
        this._setSelfieUrl(selfieImage[0])
      }
    }
  }

  public expandProperties() {
    this.isPropertiesExpand = !this.isPropertiesExpand
    this.isBreakdownExpand = false
  }
  public expandBreakdown() {
    this.isPropertiesExpand = false;
    this.isBreakdownExpand = !this.isBreakdownExpand
  }

  private _setSelfieUrl(ref: any): void {
    if (!this.account || !ref)
      return;
    ref.fileName = ref.fileName ? ref.fileName : ref.name;
    this.selfie = ref;
    this.selfieImageUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  protected viewSelfie$$(): void {
    if (this.selfie) {
      let attachments: Attachment[] = [{
        id: this.selfie.id,
        name: this.selfie.fileName,
        size: this.selfie.size,
        contentType: this.selfie.contentType,
        url: this.selfieImageUrl
      }];
      let index: number = 1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

  protected get attachments(): IdvDocumentsDownload[] {
    return this.downloadUserIdvDocumentResult
  }

  protected get urls() {
    return this.attachments.map((a) => `data:${a.contentType};base64,${a.file}`) || [];
  }

  protected get contentType() {
    return this.attachments.map((a) => `${a.contentType}`) || [];
  }

  protected viewAttachments$$(index: number): void {
    const selectedAttachment = this.attachments[index];

    let attachments = [{
      id: selectedAttachment.id,
      contentType: selectedAttachment.contentType,
      url: `data:${selectedAttachment.contentType};base64,${selectedAttachment.file}`,
    }];

    this.viewAttachments.emit({ attachments, index });
  }
}
