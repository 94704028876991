// tslint:disable:variable-name

import { Notification } from '@nymos/dashboard/shared';
import { Texts } from '@nymos/accounts/core';

export type StringFn = (name: string) => Notification;

export class Notifications {
    public static readonly Crediting: StringFn = ((money) => new Notification(Texts.TopupAction.Crediting(money)));
    public static readonly Debiting: StringFn = ((money) => new Notification(Texts.TopupAction.Debiting(money)));
    public static readonly DebitingWallet: StringFn = ((money) => new Notification(Texts.TopupAction.DebitingWallet(money)));
    public static readonly Transfering: StringFn = ((money) => new Notification(Texts.TopupAction.Transfering(money)));
    public static readonly CardTransfering: StringFn = ((money) => new Notification(Texts.TopupAction.CardTransfer(money)));
    public static readonly Refunding: StringFn = ((money) => new Notification(Texts.TopupAction.Refunding(money)));
    public static readonly Approving: Notification = new Notification(Texts.TopupAction.Approving);
    public static readonly Declining: Notification = new Notification(Texts.TopupAction.Declining);
    public static readonly Cancelling: Notification = new Notification(Texts.TopupAction.Cancelling);
    public static readonly Retrying: Notification = new Notification(Texts.TopupAction.Retrying);
    public static readonly Failure: Notification = new Notification(Texts.Action.Failure);
    public static readonly TerminateCardTransfer: Notification = new Notification(Texts.Action.TerminateCardTransfer);
    public static readonly GiftCardTransfer: Notification = new Notification(Texts.Action.GiftCardTransfer);
    public static readonly InvalidCardTransfer: Notification = new Notification(Texts.Action.InvalidCardTransfer);
    public static readonly NotFoundCardTransfer: Notification = new Notification(Texts.Action.NotFoundCardTransfer);
    public static readonly SameCardTransfer: Notification = new Notification(Texts.Action.SameCardTransfer);
    public static readonly ExceedCardTransfer: Notification = new Notification(Texts.Action.ExceedCardTransfer);
    public static readonly ExceedVirtualCardTransfer: Notification = new Notification(Texts.Action.ExceedVirtualCardTransfer);
    public static readonly ExceedTravelCardTransfer: Notification = new Notification(Texts.Action.ExceedTravelCardTransfer);
    public static readonly ActiveCardTransfer: Notification = new Notification(Texts.Action.ActiveCardTransfer);
    public static readonly LimitExceedCardTransfer: Notification = new Notification(Texts.Action.LimitExceedCardTransfer);

}


