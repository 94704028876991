
import {
  NgModule,
} from '@angular/core';

import {
  CommonModule,
} from '@angular/common';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  FlexLayoutModule,
} from '@angular/flex-layout';

import {
  OverlayModule,
} from '@angular/cdk/overlay';

import {
  PortalModule,
} from '@angular/cdk/portal';

import {
  MatButtonModule,
} from '@angular/material/button';

import {
  MatCommonModule,
} from '@angular/material/core';

import {
  MatDialogModule,
} from '@angular/material/dialog';

import {
  MatFormFieldModule,
} from '@angular/material/form-field';

import {
  MatIconModule,
} from '@angular/material/icon';

import {
  MatInputModule,
} from '@angular/material/input';

import {
  MatProgressBarModule,
} from '@angular/material/progress-bar';

import {
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';

import {
  MatSelectModule,
} from '@angular/material/select';

import {
  MatElevationModule,
} from '@michel.freiha/material';

import {
  ThemePipesModule,
} from '../../../pipes/pipes.module';

import {
  Dialog,
} from './dialog';

import {
  DialogService,
} from './services/dialog.service';

import {
  DialogContainer,
} from './outlets/dialog.container';

import {
  ModalContainer,
} from './outlets/modal/modal.container';

import {
  FullwidthContainer,
} from './outlets/fullwidth/fullwidth.container';

import {
  PreviewContainer,
} from './outlets/preview/preview.container';

import {
  ConfirmContainer,
} from './outlets/confirm/confirm.container';

import {
  ConfirmComponent,
} from './contents/confirm/confirm.component';

import {
  ModalComponent,
} from './contents/modal/modal.component';

import {
  ModalFactory,
} from './factories/modal.factory';

import {
  PreviewFactory,
} from './factories/preview.factory';



export const MATERIAL_MODULES = [
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatElevationModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
];

@NgModule({
  declarations: [
    ConfirmComponent,
    ModalComponent,
    DialogContainer,
    ConfirmContainer,
    ModalContainer,
    FullwidthContainer,
    PreviewContainer,
  ],

  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FlexLayoutModule,
    OverlayModule,
    PortalModule,
    MatCommonModule,
    ReactiveFormsModule,
    ThemePipesModule,
  ],

  entryComponents: [
    ConfirmComponent,
    ModalComponent,
    ConfirmContainer,
    ModalContainer,
    FullwidthContainer,
    PreviewContainer,
  ],

  providers: [
    Dialog,
    DialogService,
    ModalFactory,
    PreviewFactory,
  ],
})
export class ThemeComponentsDialogModule { }
