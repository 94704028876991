import { ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output, } from '@angular/core';

  import {
    Account,
    Wallet,
  } from '@nymos/accounts/core';

@Component({
  selector: 'nym-user-wallet-iqd-info',
  templateUrl: './user-wallet-iqd-info.component.html',
  styleUrls: ['./user-wallet-iqd-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserWalletIqdInfoComponent implements OnInit {

  @HostBinding('class.nym-user-wallet-info')
  protected get classes(): boolean { return true; }

  @Output()
  protected topup: EventEmitter<any> = new EventEmitter();

  @Output()
  protected topuprefund: EventEmitter<any> = new EventEmitter();

  @Input()
  public account: Account;

  @Input()
  public wallet: Wallet;

  @Input() isUserType:any;

  @Input() refundPermission:boolean;

  @Input() currencyInfo:string;

  @Input() walletStatus:boolean;

  @Input()
  public loading: boolean;

  constructor() { }

  public ngOnInit(): void { }

  protected topup$$(): void {
    localStorage.setItem("wallet_type", "iqd");
    this.topup.emit(this.currencyInfo);
  }

  protected topuprefund$$(): void {
    localStorage.setItem("wallet_type", "iqd");
    this.topuprefund.emit();
  }

}
