import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  FileUploadRef,
  IdvChecks,
  UserProfile,
} from '@michel.freiha/ng-sdk';

import {
  Account, Attachment,
} from '@nymos/accounts/core';
import { ImageService } from '../../../services/image.service';

@Component({
  selector: 'nym-agent-idv-sdk-result',
  templateUrl: './agent-idv-sdk-result.component.html',
  styleUrls: ['./agent-idv-sdk-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentIdvSdkResultComponent implements OnInit {

  @HostBinding('class.nym-agent-idv-sdk-result')
  protected get classes(): boolean { return true; }

  @Output('viewAttachments') // tslint:disable-line:no-output-rename
  protected viewAttachments: EventEmitter<any> = new EventEmitter();

  @Output() showReportResult = new EventEmitter<any>();

  @Input()
  public account: Account;

  @Input()
  public idvCheckResult:IdvChecks

  protected selfie: FileUploadRef;
  protected selfieImageUrl :string
  

  constructor(private _cd: ChangeDetectorRef,
    private _imageService: ImageService,) { }

  protected get profile(): UserProfile { return this.account && this.account.profile; }


  public ngOnInit(): void {
   }

  public ngOnChanges(changes: SimpleChanges): void {
    if(this.profile){
      const selfieImage = this.profile.selfie
  
      if(selfieImage){
         this._setSelfieUrl(selfieImage[0])
       }
     } 
  }


  
   private _setSelfieUrl(ref: any): void {
    if (!this.account || !ref)
      return;
    ref.fileName=ref.fileName?ref.fileName: ref.name ;
    this.selfie = ref;
    this.selfieImageUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  } 

  public showReportClicked(event: any): void {
    this.showReportResult.emit(event);
}

  protected viewSelfie$$(): void {
    if(this.selfie){
    let attachments: Attachment[] = [{
                                  id: this.selfie.id,
                                 name: this.selfie.fileName,
                                 size: this.selfie.size,
                                 contentType: this.selfie.contentType,
                                 url:this.selfieImageUrl
                                  }];
      let index:number=1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

}

