/* tslint:disable:member-ordering */

import produce from 'immer';

import {
    finalize,
    tap,
} from 'rxjs/operators';

import {
    Action,
    createSelector,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    SignIn,
    SignOut,
} from '@nymos/auth';

import {
    AccountsInternalInMemory,
} from '@nymos/accounts/data';

import {
    NewProvince,
} from '../../models/new-province.model';

import {
    LoadAccountProvincesNewFromApi,
} from './accounts-provinces-new.actions';


export interface AccountsProvincesNewStateModel {
    loading: boolean;
    newprovinces: string[];
    newcities: { [newprovince: string]: string[] };
}

const stateDefaults: AccountsProvincesNewStateModel = {
    loading: undefined,
    newprovinces: [],
    newcities: {},
};

@State<AccountsProvincesNewStateModel>({
    name: 'newprovinces',
    defaults: stateDefaults,
})
export class AccountsProvincesNewState implements NgxsOnInit {

    @Selector()
    public static loading(state: AccountsProvincesNewStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static newprovinces(state: AccountsProvincesNewStateModel): string[] {
        return state.newprovinces;
    }

    public static newcities(newprovince: string): any {
        return createSelector([AccountsProvincesNewState], (state: AccountsProvincesNewStateModel) => {
            return state.newcities[newprovince];
        });
    }
    constructor(
        private _accountService: AccountsInternalInMemory,
    ) { }

    public ngxsOnInit(ctx: StateContext<AccountsProvincesNewStateModel>): void {
        ctx.dispatch(new LoadAccountProvincesNewFromApi());
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsProvincesNewStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(SignIn)
    @Action(LoadAccountProvincesNewFromApi)
    public load(ctx: StateContext<AccountsProvincesNewStateModel>): any {

        ctx.setState({ ...stateDefaults, loading: true });
        return this._accountService.getAccountPronvincesNew().pipe(
            tap((newprovinces: NewProvince[]) => {
                ctx.setState(produce((draft) => {
                    draft.newprovinces = newprovinces;
                    newprovinces.sort(function(a, b){
                        if(a.en < b.en) { return -1; }
                        if(a.en > b.en) { return 1; }
                        return 0;
                    })
                    newprovinces.forEach((p) =>
                        {
                            draft.newcities[p.en] = p.cities;
                            p.cities.sort(function(a, b){
                                if(a.en < b.en) { return -1; }
                                if(a.en > b.en) { return 1; }
                                return 0;
                            })
                        });
                }));
            }),
            finalize(() => {
              ctx.patchState({ loading: false });
            }),
        );
    }
}

