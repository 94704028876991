import {
    Pipe,
    PipeTransform,
} from '@angular/core';


@Pipe({ name: 'word' })
export class StringToWordPipe implements PipeTransform {

    public transform(input: string, condition?: boolean): any {
        if (!input) return '--';

        if (condition) return '--';

        // Convert camelCase to space-separated words and capitalize the first letter of each word
        const result = input.replace(/([A-Z])/g, ' $1')
                            .replace(/^./, str => str.toUpperCase());

        return result;
    }
}
