import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  AnimationEvent,
} from '@angular/animations';

import {
  coerceBooleanProperty,
} from '@angular/cdk/coercion';

import {
  animations,
} from '../../animations/filter.animations';

import {
  Navigate,
} from '@ngxs/router-plugin';

type expandedState = 'expanded' | 'collapsed';

@Component({
  selector: 'thm-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    animations.expansion,
  ],
})
export class FilterComponent implements OnInit, OnChanges {

  private _expanded: boolean = false;
  private _autofocus: boolean;
  private _togglable: boolean = true;

  private _value: string = '';

  @HostBinding('class.thm-filter')
  protected get classes(): boolean { return true; }

  @Input()
  public icon: string = 'filter_list';

  @Input()
  public placeholder: string = 'Filter';

  @Input()
  public parent: string;

  @Input()
  public get value(): string { return this._value; }
  public set value(value: string) { this._value = value; }

  @Input()
  public get autofocus(): boolean { return this._autofocus; }
  public set autofocus(value: boolean) { this._autofocus = coerceBooleanProperty(value); }

  @Input()
  public get expanded(): boolean { return this._expanded; }
  public set expanded(value: boolean) { this._expanded = coerceBooleanProperty(value); }

  @Input()
  public get togglable(): boolean { return this._togglable; }
  public set togglable(value: boolean) { this._togglable = coerceBooleanProperty(value); }

  @Output('query') // tslint:disable-line:no-output-rename
  public queryEmitter: EventEmitter<string> = new EventEmitter();

  @ViewChild('input', { static: true })
  public input: ElementRef;

  @HostBinding('class.thm-filter-expanded')
  public get expandedClass(): boolean { return this.expanded; }

  @HostBinding('@expansion')
  public get expandedState(): expandedState { return this.expanded ? 'expanded' : 'collapsed'; }

  @HostBinding('class.no-icon')
  public get noIcon(): boolean { return !this.icon || this.icon === 'none'; }

  constructor(private _store: Store) { }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {

    const valueChange = changes['value'];
    const togglableChange = changes['togglable'];
    const autofocusChange = changes['autofocus'];

    if (togglableChange && !this._togglable)
      this.expanded = true;

    if (valueChange && valueChange.currentValue)
      this.expanded = true;

    if (autofocusChange && this._autofocus)
      this.input.nativeElement.focus();
  }

  @HostListener('@expansion.done', ['$event'])
  protected onExpansion(event: AnimationEvent): void {
    if (event.toState !== 'collapsed')
      return;

    if (this._value !== '')
      this._submit('');
  }

  protected onEscape(event: Event): void {
    if (!this.togglable)
      return;

    this.expanded = false;
  }

  protected onEnter(event: Event): void {
    this._submit(this._value);
  }

  protected toggle(): void {
    if (this.togglable) {
      this.expanded = !this.expanded;
    }
    if (this.expanded) {
      this.input.nativeElement.focus();
    }
  }

  protected onClear(): void {
    this._submit('');
    this.input.nativeElement.focus();

    if(this.parent && this.parent === "channelsearch")
    this._store.dispatch(new Navigate(['accounts/channel/active']));
  }

  private _submit(value: string): void {
    this._value = value.trim();
    this.queryEmitter.emit(this._value);
    this.input.nativeElement.blur();
  }

}
