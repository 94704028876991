import {
    CoreAccountRef,
    NymcardCmsWalletTransactionType
} from '@michel.freiha/ng-sdk';

export type Status =
    | 'pending'
    | 'rejected'
    | 'processing'
    | 'cancelling'
    | 'cancelled'
    | 'settled'
    | 'declined'
    | 'error'
    | undefined;

export type Category =
| 'credit'
| 'debit'
| 'card_issuance'
| 'card_reload'
| 'transfer'
| undefined;

export class WalletActivity {

    public id: string;
    public title: string;
    public category: string;
    public accountId: string;
    public walletId: string;
    public created: Date;
    public createdBy: CoreAccountRef;
    public modified: Date;
    public paymentMethod: string;
    public currency: string;
    public amount: number;
    public fees: number;
    public total: number;
    public status: Status;
    public source: string;
    public reason: string;
    public receiverNumber: string;
    public reviewer: CoreAccountRef;

    constructor(ob?: Partial<WalletActivity>) {

        this.id = undefined;
        this.title = undefined;
        this.category = undefined;
        this.accountId = undefined;
        this.walletId = undefined;
        this.created = undefined;
        this.createdBy = undefined;
        this.modified = undefined;
        this.paymentMethod = undefined;
        this.currency = undefined;
        this.amount = undefined;
        this.fees = undefined;
        this.total = undefined;
        this.status = undefined;
        this.source = undefined;
        this.reason = undefined;
        this.receiverNumber = undefined;
        this.reviewer = undefined;

        if (!ob) { return; }
           console.log()
        if (ob.id !== undefined) { this.id = ob.id; }
        if (ob.title !== undefined) { this.title = ob.title; }
        if (ob.category !== undefined) { this.category = ob.category; }
        if (ob.accountId !== undefined) { this.accountId = ob.accountId; }
        if (ob.walletId !== undefined) { this.walletId = ob.walletId; }
        if (ob.created !== undefined) { this.created = new Date(ob.created); }
        if (ob.createdBy !== undefined) { this.createdBy = new CoreAccountRef(ob.createdBy); }
        if (ob.modified !== undefined) { this.modified = new Date(ob.modified); }
        if (ob.paymentMethod !== undefined) { this.paymentMethod = ob.paymentMethod; }
        if (ob.currency !== undefined) { this.currency = ob.currency; }
        if (ob.amount !== undefined) { this.amount = ob.amount; }
        if (ob.fees !== undefined) { this.fees = ob.fees; }
        if (ob.total !== undefined) { this.total = ob.total; }
        if (ob.status !== undefined) { this.status = ob.status; }
        if (ob.source !== undefined) { this.source = ob.source; }
        if (ob.reason !== undefined) { this.reason = ob.reason; }
        if (ob.receiverNumber !== undefined) { this.receiverNumber = ob.receiverNumber; }
        if (ob.reviewer !== undefined) { this.reviewer = new CoreAccountRef(ob.reviewer); }
    }
}

