import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  MenuItem,
} from '../../models/menu-item.model';


@Component({
  selector: 'thm-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent implements OnInit {

  @HostBinding('class.thm-menu-item')
  protected get classes(): boolean { return true; }

  @Input()
  public item: MenuItem;

  @Output('navigation') // tslint:disable-line:no-output-rename
  public navigationEmitter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public ngOnInit(): void { }

  public onClick(navigation: any): void {
    this.navigationEmitter.emit(navigation);
  }

}
