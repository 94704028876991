/* tslint:disable: component-class-suffix */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    OnInit,
    Optional,
    ViewEncapsulation,
} from '@angular/core';

import {
    DOCUMENT,
} from '@angular/common';

import {
    FocusTrapFactory,
} from '@angular/cdk/a11y';

import {
    MatDialogConfig,
} from '@angular/material/dialog';

import {
    Observable,
} from 'rxjs';

import {
    ImageFile,
    SecureImageService,
} from '@nymos/theme/core';

import {
    thmDialogAnimation as thmAnimation,
} from '../../animations/dialog.animations';

import {
    DialogContainer,
} from '../dialog.container';


@Component({
    selector: 'thm-preview-dialog',
    templateUrl: './preview.container.html',
    styleUrls: ['./preview.container.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        thmAnimation.dialogContainer,
        thmAnimation.downloadingImage,
        thmAnimation.slideDown,
    ],
})
export class PreviewContainer extends DialogContainer implements OnInit {

    private _index: number = 0;

    @HostBinding('class.thm-preview-dialog')
    protected get classes(): boolean { return true; }

    protected downloading: boolean = true;

    protected url$: Observable<ImageFile>;

    protected get name(): string { return this.data.urls[this._index].name; }

    public constructor(
        _er: ElementRef,
        _ft: FocusTrapFactory,
        _conf: MatDialogConfig,
        _cd: ChangeDetectorRef,
        @Optional() @Inject(DOCUMENT) _doc: any,
        private _secureService: SecureImageService,
    ) {
        super(_er, _ft, _conf, _cd, _doc);
    }

    public ngOnInit(): void {
        this._setIndex(this.data.index);
    }

    protected loaded(event: Event): void {
        this.downloading = false;
    }

    protected isFirst(): boolean {
        return this._index === 0;
    }

    protected isLast(): boolean {
        return this._index === this.data.urls.length - 1;
    }

    protected back(): any {
        this._setIndex(this._index - 1);
    }

    protected next(): any {
        this._setIndex(this._index + 1);
    }

    private _setIndex(index: number): void {
        this.downloading = true;

        this._index = index;
        this._index = Math.max(this._index, 0);
        this._index = Math.min(this._index, this.data.urls.length - 1);
        this.url$ = this._secureService.get(this.data.urls[this._index].url);
    }
}
