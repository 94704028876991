import {
    Gender,
} from '@michel.freiha/ng-sdk';

import {
    Attachment,
} from './attachment.model';



export class AccountProfile {

    public fullName: string;
    public motherName?: string;
    public motherNameArabic?: string;
    public email: string;
    public mobile: string;
    public arabicName?: string;
    public gender?: Gender;
    public nationality?: string;
    public dateOfBirth?: Date;
    public placeOfBirth?: string;
    public phoneNumber?: string;
    public selfie?: any;

    constructor(ob?: Partial<AccountProfile>) {

        this.fullName = undefined;
        this.motherName = undefined;
        this.motherNameArabic = undefined;
        this.email = undefined;
        this.mobile = undefined;
        this.arabicName = undefined;
        this.gender = undefined;
        this.nationality = undefined;
        this.dateOfBirth = undefined;
        this.placeOfBirth = undefined;
        this.phoneNumber = undefined;
        this.selfie = [];

        if (!ob) { return; }

        if (ob.fullName !== undefined) { this.fullName = ob.fullName; }
        if (ob.motherName !== undefined) { this.motherName = ob.motherName; }
        if (ob.motherNameArabic !== undefined) { this.motherNameArabic = ob.motherNameArabic; }
        if (ob.email !== undefined) { this.email = ob.email; }
        if (ob.mobile !== undefined) { this.mobile = ob.mobile; }
        if (ob.arabicName !== undefined) { this.arabicName = ob.arabicName; }
        if (ob.gender !== undefined) { this.gender = ob.gender; }
        if (ob.nationality !== undefined) { this.nationality = ob.nationality; }
        if (ob.dateOfBirth !== undefined) { this.dateOfBirth = ob.dateOfBirth; }
        if (ob.placeOfBirth !== undefined) { this.placeOfBirth = ob.placeOfBirth; }
        if (ob.phoneNumber !== undefined) { this.phoneNumber = ob.phoneNumber; }
        if (ob.selfie !== undefined) { ob.selfie.forEach((o) => this.selfie.push(new Attachment(o))); }

    }
}

