import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Admin,
  FileUploadRef,
  IdvChecks,
  IdvDocumentsDownload,
  IdvVerificationReports,
  Problem,
} from '@michel.freiha/ng-sdk';

import {
  Account,
  Wallet,
  WalletActivity,
} from '@nymos/accounts/core';

@Component({
  selector: 'nym-agent-details-content',
  templateUrl: './agent-details-content.component.html',
  styleUrls: ['./agent-details-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentDetailsContentComponent implements OnInit {
  public isUserType:any;

  @HostBinding('class.nym-agent-details-content')
  protected get classes(): boolean { return true; }

  @Output('manageWalletActivity') // tslint:disable-line:no-output-rename
  protected manageWalletActivityEmitter: EventEmitter<any> = new EventEmitter();

  @Output('viewWalletActivities') // tslint:disable-line:no-output-rename
  protected viewWalletActivitiesEmitter: EventEmitter<any> = new EventEmitter();

  @Output('createWalletIqd') // tslint:disable-line:no-output-rename
  protected createWalletIqdEmitter: EventEmitter<any> = new EventEmitter();

  @Output('viewAttachments') // tslint:disable-line:no-output-rename
  protected viewAttachmentsEmitter: EventEmitter<FileUploadRef[]> = new EventEmitter();

  @Output('viewTopup') // tslint:disable-line:no-output-rename
  protected viewTopupEmitter: EventEmitter<any> = new EventEmitter();

  @Output('viewTopupRefund') // tslint:disable-line:no-output-rename
  protected viewTopupRefundEmitter: EventEmitter<any> = new EventEmitter();

  @Output('loadWallet') // tslint:disable-line:no-output-rename
  protected loadWalletEmitter: EventEmitter<any> = new EventEmitter();

  @Output('editAgent') // tslint:disable-line:no-output-rename
  protected editAgentEmitter: EventEmitter<any> = new EventEmitter();

  @Input()
  public account: Account;

  @Input()
  public idvCheckResult :IdvChecks;

  @Input()
  public idvReportResult :IdvVerificationReports;

  @Input()
  public accountLoading: boolean;

  @Input()
  public wallet: Wallet;

  @Input()
  public refundPermission:boolean;

  @Input()
  public walletStatus:boolean;

  @Input()
  public walletLoading: boolean;

  @Input()
  public walletProblem: Problem;

  @Input()
  public walletActivities: WalletActivity[];

  @Input()
  public walletActivitiesLoading: boolean;

  @Input()
  public downloadUserIdvDocumentResult: IdvDocumentsDownload[];

  protected get firstWalletActivities(): WalletActivity[] {
    let copyWalletActivities = this.walletActivities;
    let iqdWallet = copyWalletActivities.filter((res)=>{return res.currency=='USD'});
    return this.walletActivitiesLoading ? iqdWallet : iqdWallet.slice(0, 10);
    //return this.walletActivitiesLoading ? this.walletActivities : this.walletActivities.slice(0, 10);
  }

  protected get secondWalletActivities(): WalletActivity[] {
    let copyWalletActivities = this.walletActivities;
    let iqdWallet = copyWalletActivities.filter((res)=>{return res.currency=='IQD'});
    return this.walletActivitiesLoading ? iqdWallet : iqdWallet.slice(0, 10);
  }

  protected get status(): string { return this.account && this.account.channel.channel; }

  constructor() { }

  public ngOnInit(): void {
   this.isUserType = {"name":'agent'}
    }

  protected manageWalletActivity(event: any): void {
    this.manageWalletActivityEmitter.emit(event);
  }

  protected viewWalletActivities(): void {
    this.viewWalletActivitiesEmitter.emit();
  }

  protected viewAttachments(event: any): void {
    this.viewAttachmentsEmitter.emit(event);
  }

  protected createWalletIqd(): void {
    this.createWalletIqdEmitter.emit();
  }

  protected viewTopup(event: any): void {
    this.viewTopupEmitter.emit(event);
  }
  protected viewTopupRefund(event: any): void {
    this.viewTopupRefundEmitter.emit(event);
  }

  protected loadWallet(): void {
    this.loadWalletEmitter.emit();
  }

  protected editAgent(): void {
    this.editAgentEmitter.emit();
  }

}
