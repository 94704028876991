import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import {
  CardActivity,
} from '@nymos/accounts/core';
import { Dialog } from 'libs/theme/src/lib/ui/components/dialog/dialog';
import { ChargebackCreateComponent } from '../../containers/chargeback-create/chargeback-create.component';


const DEFAULT_COLUMNS = ['category', 'merchant', 'amount', 'payment', 'datetime', 'location', 'transaction_type', 'status', 'action'];

@Component({
  selector: 'nym-card-activity-listing-table',
  templateUrl: './card-activity-listing-table.component.html',
  styleUrls: ['./card-activity-listing-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardActivityListingTableComponent implements OnInit {

  @HostBinding('class.nym-card-activity-full-table')
  protected get classes(): boolean { return true; }

  @Input()
  public activities: CardActivity[];

  @Input()
  public loading: boolean;

  @Input()
  public columns: string[] = DEFAULT_COLUMNS;

  protected get shimmer(): boolean { return this.loading && !(this.activities && this.activities.length); }

  disputeNotAllowedStatuses = ['pending', 'approved', 'declined'];

  constructor(protected _dialog: Dialog,) { }

  public ngOnInit(): void { }

  protected dispute$$(transaction$: CardActivity, event): void {
    if (transaction$.chargebackStatus && this.disputeNotAllowedStatuses.includes(transaction$.chargebackStatus))
      return;

    let transactionData = { transaction: transaction$, disputeBtn: event }
    const text = 'Create Chargeback';

    this._dialog.openAsModal(ChargebackCreateComponent, {
      width: '400px', data: {
        text,
        transactionData,
        confirm: { label: 'Submit' },
        cancel: { label: 'Cancel' },
      }
    });
  }
}
