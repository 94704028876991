/* tslint:disable:member-ordering */

import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';

import {
    forkJoin,
} from 'rxjs';

import {
    catchError,
    finalize, tap,
} from 'rxjs/operators';

import {
    AccountChannelIndex,
    AgentAccountInternal,
    IdvChecks,
    IdvDocuments,
    IdvVerificationReports,
    NymcardAccountCardsLimitInternal,
    NymcardAccountLimitsInternal,
    PasscodeResetRequest,
    Problem,
} from '@michel.freiha/ng-sdk';

import {
    SignOut,
} from '@nymos/auth';

import {
    AccountBuilder,
} from '../../builders/account.builder';

import {
    AccountsOperations,
} from '../accounts.operations';

import {
    BlockAccountFromAgentDetailsPage,
    GetAccountFromAgentDetailsPage,
    GetAccountFromAgentExistsGuard,
    GetAgentIdvCheck,
    GetAgentIdvDocumentStatus,
    GetAgentIdvReport,
    RefreshAccountFromAgentApi,
    RefreshAccountFromAgentDetailsPage,
    RejectAccountFromAgentDetailsPage,
    ResetPasscodeFromAgentDetailsPage,
    UnblockAccountFromAgentDetailsPage,
    VerifyAccountFromAgentDetailsPage,
} from './accounts-agents.actions';

import {
    Notifications,
} from './accounts-agents.notifications';
import produce from 'immer';


export interface AccountsAgentsStateModel {
    limits: { [id: string]: NymcardAccountLimitsInternal };
    cardLimits: { [id: string]: NymcardAccountCardsLimitInternal };
    cardMultiLimits: { [id: string]: [] };
    internals: { [id: string]: AgentAccountInternal };
    indexes: { [id: string]: AccountChannelIndex };
    idvDocument: IdvDocuments;
    idvCheck:IdvChecks;
    idvReportResult:IdvVerificationReports;
    loading: boolean;
    saving: boolean;
    problem: Problem;
}

const stateDefaults: AccountsAgentsStateModel = {
    limits: {},
    cardLimits: {},
    cardMultiLimits: {},
    internals: {},
    indexes: {},
    idvDocument: undefined,
    idvCheck:undefined,
    idvReportResult:undefined,
    loading: undefined,
    saving: undefined,
    problem: undefined,
};

@State<AccountsAgentsStateModel>({
    name: 'agents',
    defaults: stateDefaults,
})
export class AccountsAgentsState extends AccountsOperations<any> {

    public static account(id: string): any {
        return createSelector([AccountsAgentsState], (state: AccountsAgentsStateModel) => {
            // TODO(@leandro): Return specific view model for agent accounts

            const result = new AccountBuilder(this.uploadOptions)
                .withIndex(state.indexes[id])
                .withAgent(state.internals[id])
                .withLimits(state.limits[id])
                .withCardLimits(state.cardLimits[id])
                .withCardMultiLimits(state.cardMultiLimits[id])
                .build();

            return result;
        });
    }

    @Selector()
    public static problem(state: AccountsAgentsStateModel): Problem {
        return state.problem;
    }

    @Selector()
    public static loading(state: AccountsAgentsStateModel): boolean {
        return state.loading;
    }

    @Action(SignOut)
    public reset(ctx: StateContext<AccountsAgentsStateModel>): any {
        ctx.setState(stateDefaults);
    }

    @Action(GetAccountFromAgentDetailsPage)
    @Action(GetAccountFromAgentExistsGuard)
    public loadAgent(ctx: StateContext<AccountsAgentsStateModel>, { id }: any): any {

        const update = this.updateItem(ctx, id, Notifications.Loading);

        ctx.patchState({ loading: true });
        return this._loadAgent(ctx, id).pipe(update).pipe(
            finalize(() => ctx.patchState({ loading: false })),
        );
    }

    @Action(GetAgentIdvDocumentStatus)
    public getUserIdvDocumentStatus(ctx: StateContext<AccountsAgentsStateModel>, { id }: any): any {
         ctx.patchState({ loading: true });
         return this.getUserIdvDocument(ctx, id).pipe(
             tap((idvDocumentStatus: IdvDocuments) => {
               ctx.setState(produce((state) => {
                 state.idvDocument = idvDocumentStatus;
               }));
             }),
       
             catchError((problem) => {
               return ctx.dispatch({ loading: false });
             }),
       
             finalize(() => {
               ctx.patchState({ loading: false });
             }),
           );
       } 

       @Action(GetAgentIdvCheck)
       public getUserIdvCheck(ctx: StateContext<AccountsAgentsStateModel>, { id,isAgent }: any): any {
            ctx.patchState({ loading: true });
            return this.getIdvCheck(ctx, id, isAgent).pipe(
                tap((idevCheck: IdvChecks) => {
                  ctx.setState(produce((state) => {
                    state.idvCheck = idevCheck;
                  }));
                }),
          
                catchError((problem) => {
                  return ctx.dispatch({ loading: false });
                }),
          
                finalize(() => {
                  ctx.patchState({ loading: false });
                }),
              );
          }
          
          @Action(GetAgentIdvReport)
          public getIdvReport(ctx: StateContext<AccountsAgentsStateModel>, { reportId,id }: any): any {
               ctx.patchState({ loading: true });
               return this.getUserIdvReport(ctx, reportId,id).pipe(
                   tap((idvReportResult: IdvVerificationReports) => {
                     ctx.setState(produce((state) => {
                       state.idvReportResult = idvReportResult;
                     }));
                   }),
             
                   catchError((problem) => {
                     return ctx.dispatch({ loading: false });
                   }),
             
                   finalize(() => {
                     ctx.patchState({ loading: false });
                   }),
                 );
             } 
    

    @Action(RefreshAccountFromAgentDetailsPage)
    @Action(RefreshAccountFromAgentApi)
    public refreshAgent(ctx: StateContext<AccountsAgentsStateModel>, { id }: any): any {
        const refresh = this.refreshItem(ctx, id);
        return this._loadAgent(ctx, id).pipe(refresh);
    }

    @Action(ResetPasscodeFromAgentDetailsPage)
    public resetPasscode(ctx: StateContext<AccountsAgentsStateModel>, { id, note }: any): any {

        this.nc.show(Notifications.PasscodeReset);

        return this.passcodeService.resetPasscode(id, new PasscodeResetRequest({ note: note })).pipe(
            finalize(() => this.nc.show(Notifications.PasscodeReseted)),
        );
    }

    @Action(VerifyAccountFromAgentDetailsPage)
    public verifyAgent(ctx: StateContext<AccountsAgentsStateModel>, { id }: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel !== 'pending', RefreshAccountFromAgentApi);
        return this.agentService.approveAgentAccount(id).pipe(update);
    }

    @Action(RejectAccountFromAgentDetailsPage)
    public rejectAgent(ctx: StateContext<AccountsAgentsStateModel>, { id, note }: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel !== 'pending', RefreshAccountFromAgentApi);
        return this.agentService.rejectAgentAccount(id, note).pipe(update);
    }

    @Action(BlockAccountFromAgentDetailsPage)
    public blockAccount(ctx: StateContext<AccountsAgentsStateModel>, { id, note }: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel === 'blocked', RefreshAccountFromAgentApi);
        return this.accountService.blockAccount(id, note).pipe(update);
    }

    @Action(UnblockAccountFromAgentDetailsPage)
    public unblockAccount(ctx: StateContext<AccountsAgentsStateModel>, { id, note }: any): any {
        const update = this.updateChannel(ctx, id, (index) => index.channel !== 'blocked', RefreshAccountFromAgentApi);
        return this.accountService.unblockAccount(id,note).pipe(update);
    }

    private _loadAgent(ctx: StateContext<AccountsAgentsStateModel>, id: string): any {
        return forkJoin({
            index: this.loadChannelIndex(ctx, id),
            internal: this._loadAgentInternal(ctx, id),
            limits: this.loadLimitInternal(ctx, id),
            cardLimit: this.loadCardsLimitInternal(ctx,id),
            cardMultiLimit: this.loadCardsMultiLimitInternal(ctx,id),
        });
    }

    private _loadAgentInternal(ctx: StateContext<AccountsAgentsStateModel>, id: any): any {
        return this.agentService.loadAgentAccount(id);
    }

}
