import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  Menu,
} from '../../models/menu.model';

@Component({
  selector: 'thm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {

  @HostBinding('class.thm-menu')
  protected get classes(): boolean { return true; }

  @Input()
  public menu: Menu;

  @Output('navigation') // tslint:disable-line:no-output-rename
  public navigationEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  public ngOnInit(): void { }

  public onNavigation(action: any): void {
    this.navigationEmitter.emit(action);
  }
}
