import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TooltipPosition } from '@angular/material';

import {
  NymcardAccountLimit as AccountLimit, UserAccountVerificationStatus,
} from '@michel.freiha/ng-sdk';
import { Dialog } from '@nymos/theme';
import { Account, AccountsUsersState, LimitRemainingPipe } from '../../../core/core';
import {
  Card,
} from '@nymos/accounts/core';
import { Select, Store } from '@ngxs/store';
import { CardsState } from '../../../core/store/cards/cards.state';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'nym-card-listing-header',
  templateUrl: './card-listing-header.component.html',
  styleUrls: ['./card-listing-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardListingHeaderComponent implements OnInit, OnDestroy, OnChanges {

  @HostBinding('class.nym-card-listing-header')
  protected get classes(): boolean { return true; }

  @Input()
  public limit: AccountLimit;

  @Output()
  protected issueCardEmitter: EventEmitter<any> = new EventEmitter();

  public tooltipPosition: TooltipPosition[] = ['above'];

  @Select(CardsState.cards)
  public cards$: Observable<Card[]>

  public cards: Card[];

  public canIssueCard: boolean;

  public account$: Observable<Account>;

  public _account: Account;

  public accountId: string;

  public subscription: Subscription = new Subscription();

  constructor(
    public _store: Store,
    private _cd: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    this.canIssueCard = false;
    const router = this._store.selectSnapshot((state) => state.router.state);
    this.accountId = router.params.id;

    this.account$ = this._store.select(AccountsUsersState.account(this.accountId));
    this.subscription.add(this.account$.subscribe((account) => this._account = account));

    this.toggleIssueCardBtn();

    this.subscription.add(
      this.cards$.subscribe((stateCards) => {
        this.cards = stateCards;
        this.toggleIssueCardBtn();
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.account$ = this._store.select(AccountsUsersState.account(this.accountId));
    this.subscription.add(this.account$.subscribe((account) => this._account = account));

    this.toggleIssueCardBtn();
  }

  protected issueCard(event: any): void {
    if (this.canIssueCard) {
      this.issueCardEmitter.emit(event);
    }
  }

  toggleIssueCardBtn(): void {
    if (
      this.areAllTerminatedCards() &&
      this.isCardLimitAvailable() &&
      this.isKYC2Approved()
    ) {
      this.canIssueCard = true;
    }
    else {
      this.canIssueCard = false;
    }
    this._cd.markForCheck()
  }

  isCardLimitAvailable(): boolean {
    if (this.limit && this.limit.cards) {
      const CardLimitPipe = new LimitRemainingPipe();
      const remainingCards = CardLimitPipe.transform(this.limit.cards);
      return remainingCards === 0 ? false : true;
    }
    return true;
  }

  areAllTerminatedCards(): boolean {
    if (!this.cards) return false;
    if (this.cards.length === 0) return true;

    const allCards = this.cards.filter(card => { return (card.productId === '006' || card.productId === '007' || card.productId === '008')});
    if (allCards.length > 2){
           return allCards.filter((cards)=>{ return cards.status.toLowerCase() !== 'terminated'}).length>2 ? false :true;
    }

    return true;
  }

  isKYC2Approved(): boolean {
    return this._account.verificationStatus === UserAccountVerificationStatus.Kyc2Approved ||
    this._account.verificationStatus ===  UserAccountVerificationStatus.Kyc3Approved  || 
    this._account.verificationStatus===  UserAccountVerificationStatus.Kyc3Rejected ||
    this._account.verificationStatus===  UserAccountVerificationStatus.Kyc3Pending;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
