/* tslint:disable:member-ordering */

import produce from 'immer';

import {
  Inject,
} from '@angular/core';

import {
  Action,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  EMPTY,
  Observable,
} from 'rxjs';

import {
  catchError,
  finalize,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  AgentAccount,
  AgentAccountInternal,
  AgentProfile,
  AgentsInternalService,
  AccountchannelsSearchService,
  AgentsSearchService,
  ClientOptions,
  //KYC2ApprovalRequest,
  KYC2SubmitRequest,
  NotFoundProblem,
  NymcardCmsWalletTransactionDebitDetails,
  NymcardCmsWalletTransactionDebitFeeDetails,
  NymcardsWalletInternalService,
  Problem,
  SearchCriteria,
  SearchOperator,
  UPLOADS_OPTIONS,
  SearchFilter,
  UsersInternalService,
  NymcardCmsWalletTransactionCardIssuanceDetails,
  NymcardCmsProductType,
  NymcardCmsCardProductType,
} from '@michel.freiha/ng-sdk';

import {
  SignOut,
} from '@nymos/auth';

import {
    Store,
} from '@ngxs/store';

import { SetStatusLoadingFromStatusMenu } from '../accounts-users/accounts-users.actions';

import {
  Account,
} from '../../models/account.model';

import {
  AgentKycFlow,
  FlowStep,
} from '../../models/agent-kyc-flow.model';

import {
  Texts,
} from '../../texts/accounts.texts';

import {
  AccountBuilder,
} from '../../builders/account.builder';

import {
  GetAccountFromApi,
} from '../accounts/accounts.actions';

import {
  CheckAgentWalletForKycApproval,
  CheckTravelCardIssuanceForKycApproval,
  FailFromApi,
  GetAgentListForKycApproval,
  InitKycFromResultsPage,
  LoadAccountFromMobile,
  NavigateKycFromAgentCreationPage,
  SaveAddressFromAgentCreationPage,
  SaveBusinessFromAgentCreationPage,
  SaveDocumentsFromAgentCreationPage,
  SaveProfileFromAgentCreationPage,
  SetupNewAgentForKycFromApi,
  SubmitKycFromAgentCreationPage,
  CardIssuance,
  UserKycApproval,
  UserKyc3Approval,
} from './accounts-agent-kyc.actions';

import {
  NotificationCenter, Notification,
} from '@nymos/dashboard/shared';
import { AccountProfile } from '../../models/account-profile.model';

export interface AccountsAgentKycStateModel {
  agent: AgentAccountInternal;
  walletState: boolean;
  cardState: boolean;
  approveKycState: boolean;
  lastCardIdState: boolean;
  flow: AgentKycFlow;
  loading: boolean;
  saving: boolean;
  problem: Problem;
}

const stateDefaults: AccountsAgentKycStateModel = {
  agent: undefined,
  flow: new AgentKycFlow({ current: 'personal' }),
  walletState: undefined,
  cardState: undefined,
  approveKycState: undefined,
  lastCardIdState:undefined,
  loading: undefined,
  saving: undefined,
  problem: undefined,
};

@State<AccountsAgentKycStateModel>({
  name: 'agent',
  defaults: stateDefaults,
})
export class AccountsAgentKycState {

  protected static uploadOptions: ClientOptions;
  protected lastCardId:any;

  @Selector()
  public static summary(state: AccountsAgentKycStateModel): Account {
    return new AccountBuilder(this.uploadOptions).withAgent(state.agent).build();
  }

  @Selector()
  public static account(state: AccountsAgentKycStateModel): AgentAccount {
    return state.agent.agentAccount;
  }

  @Selector()
  public static agents(state: any): AccountProfile[] {
    return state;
  }

  @Selector()
  public static flow(state: AccountsAgentKycStateModel): AgentKycFlow {
    return state.flow;
  }

  @Selector()
  public static loading(state: AccountsAgentKycStateModel): boolean {
    return state.loading;
  }

  @Selector()
  public static walletState(state: AccountsAgentKycStateModel): boolean {
    return state.walletState;
  }

  @Selector()
  public static lastCardIdState(state: AccountsAgentKycStateModel): boolean {
    return state.lastCardIdState;
  }

  @Selector()
  public static cardState(state: AccountsAgentKycStateModel): boolean {
    return state.cardState;
  }

  @Selector()
  public static approveKycState(state: AccountsAgentKycStateModel): boolean {
    return state.approveKycState;
  }

  @Selector()
  public static saving(state: AccountsAgentKycStateModel): boolean {
    return state.saving;
  }

  @Selector()
  public static problem(state: AccountsAgentKycStateModel): Problem {
    return state.problem;
  }

  constructor(
    private _nc: NotificationCenter,
    private _store: Store,
    private _agentService: AgentsInternalService,
    private agentSearchService: AgentsSearchService,
    private nymcardWalletService: NymcardsWalletInternalService,
    private channelService: AccountchannelsSearchService,
    private usersService: UsersInternalService,
    @Inject(UPLOADS_OPTIONS) options: ClientOptions,
  ) {
    AccountsAgentKycState.uploadOptions = options;
    this.lastCardId=null;
  }

  @Action(SignOut)
  public reset(ctx: StateContext<AccountsAgentKycStateModel>): any {
    ctx.setState(stateDefaults);
  }

  @Action(FailFromApi)
  public failure(ctx: StateContext<AccountsAgentKycStateModel>, { problem }: FailFromApi): void {
    ctx.patchState({ problem: problem });
  }

  @Action(InitKycFromResultsPage)
  public initKyc(ctx: StateContext<AccountsAgentKycStateModel>, { mobile }: any): any {
    ctx.patchState({ loading: true, problem: undefined });

    return this._agentService.loadAccountByMobile(mobile).pipe(

      tap((internal: AgentAccountInternal) => {
        const status = internal.agentAccount.status;
        if (status.approvalStatus === 'approved' && status.accountStatus === 'active') {
          throw new Problem({
            title: Texts.AgentAction.ActiveAccountTitle,
            detail: Texts.AgentAction.ActiveAccountDetail,
          });
        } else if ((status.accountStatus !== 'pending' || status.approvalStatus !== 'none') && status.approvalStatus !== 'rejected') {
          throw new Problem({
            title: Texts.AgentAction.PendingAccountTitle,
            detail: Texts.AgentAction.PendingAccountDetail,
          });
        }
      }),

      tap((internal: AgentAccountInternal) => {
        const flow = this._checkFlow(internal, 'personal');

        ctx.setState(produce((draft: AccountsAgentKycStateModel) => {
          draft.agent = internal;
          draft.flow = flow;
        }));
      }),

      catchError((problem) => {
        if (problem instanceof NotFoundProblem) {
          return ctx.dispatch(new SetupNewAgentForKycFromApi(mobile));
        }

        ctx.dispatch(new FailFromApi(problem));
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }


  @Action(LoadAccountFromMobile)
  public loadAccountMobile(ctx: StateContext<AccountsAgentKycStateModel>, { mobile }: any): any {
    ctx.patchState({ loading: true, problem: undefined });

    return this._agentService.loadAccountByMobile(mobile).pipe(
      tap((internal: AgentAccountInternal) => {
        ctx.setState(produce((draft: AccountsAgentKycStateModel) => {
          draft.agent = internal;
        }));
      }),
      catchError((problem) => {
        return ctx.dispatch(new FailFromApi(problem));
        //return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ loading: false });
      }),
    );
  }


  @Action(GetAgentListForKycApproval)
  public getAgentListForKycApproval(ctx: StateContext<any>, {searchString}:any ): any { 
    const state = ctx.getState();
    // const agent = new AgentAccount({ ...state.agent.agentAccount});
    const cri= new SearchCriteria({filters: [new SearchFilter({ field: 'channel', operator: SearchOperator.AnyOf, values: ['active'] }),
                      new SearchFilter({ field: 'account_type', operator: SearchOperator.AnyOf, values: ['agent'] })],query: searchString});

    return this.channelService.search(cri).pipe(
      tap((internal) => {
        ctx.setState({ ...state, agents: internal.data });
      }),
      catchError((problem) => {
        ctx.dispatch(new FailFromApi(problem));
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ saving: false });
      }),
    );
  }

  @Action(CheckAgentWalletForKycApproval)
  public checkAgentWalletForKycApproval(ctx: StateContext<AccountsAgentKycStateModel>, {accountId,userId, walletId, transactionId, referenceId, dryRun, cardType}: any): any {
    const state = ctx.getState();
    // const agent = new AgentAccount({ ...state.agent.agentAccount});
    ctx.setState({ ...state, walletState: undefined });
    if(dryRun)
    this._nc.show(new Notification('Checking Agent Wallet..'));

    return this.nymcardWalletService.debitFeeWallet(accountId, walletId, transactionId, new NymcardCmsWalletTransactionDebitFeeDetails({
      currency: cardType == 'dinar' ? 'iqd' : 'usd',
      amount: 0.00,
      description: cardType == 'dinar' ? 'DINAR card issuance' : cardType == 'business' ? 'BUSINESS card issuance' : 'TRAVEL card issuance',
      cardProduct: cardType == 'dinar' ? NymcardCmsCardProductType.Dinar : cardType == 'business' ? NymcardCmsCardProductType.Business : NymcardCmsCardProductType.Travel,
      dryRun: dryRun,
      referenceAccountId: userId,
      referenceCardId: referenceId,
      }))
    .pipe(


      tap((internal) => {

        if(internal.status.toUpperCase()==="DECLINED"){
          this._nc.show(new Notification('There is no enough cash in this agent\'s wallet'));
          this._store.dispatch(new SetStatusLoadingFromStatusMenu(false));

          throw new Problem({
            title: Texts.AgentAction.NotEnoughFunds,
            detail: Texts.AgentAction.NotEnoughFunds,
          });

           ctx.setState({ ...state, walletState: false });
        }
      }),

       tap((internal) => {
        if(internal.status.toUpperCase()!=="DECLINED"){
           ctx.setState({ ...state, walletState: true });
        }
      }),

      catchError((problem) => {
        ctx.dispatch(new FailFromApi(problem));
        ctx.setState({ ...state, walletState: false });
        //this._nc.show(new Notification('There is no enough cash in this agent\'s wallet'));
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ saving: false });
        this.lastCardId=null;
        ctx.setState({ ...state, lastCardIdState: false });
      }),
    );
  }

  @Action(CheckTravelCardIssuanceForKycApproval)
  public checkTravelCardIssuanceForKycApproval(ctx: StateContext<AccountsAgentKycStateModel>, {accountId, walletId, transactionId, dryRun,cardType}: any): any {
    const state = ctx.getState();
    // const agent = new AgentAccount({ ...state.agent.agentAccount});
    return this.nymcardWalletService.issueCard(accountId, walletId, transactionId, new NymcardCmsWalletTransactionCardIssuanceDetails({
      currency: cardType == 'dinar' ? 'iqd' : 'usd',
      amount: 0.00,
      description: cardType == 'dinar' ? 'DINAR card issuance' : cardType == 'business' ? 'BUSINESS card issuance' : 'TRAVEL card issuance',
      cardProduct: cardType == 'dinar' ? NymcardCmsCardProductType.Dinar : cardType == 'business' ? NymcardCmsCardProductType.Business : NymcardCmsCardProductType.Travel,
      dryRun: dryRun,
  }))
    .pipe(
      tap((internal) => {
        ctx.setState({ ...state, cardState: true });
        if(!dryRun){
        this._nc.show(new Notification(cardType.toUpperCase() +' Card will be loaded shortly..'));
        this.lastCardId=internal.internalId;
        ctx.setState({ ...state, lastCardIdState: true });
      }
      }),
      catchError((problem) => {
        ctx.dispatch(new FailFromApi(problem));
        ctx.setState({ ...state, cardState: false });
        cardType = cardType=='dinar' ? 'dinar' : cardType =='business' ? 'business' :'travel'
        this._nc.show(new Notification('Error occured during '+ cardType +' card issuance'));
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ saving: false });
      }),
    );
  }

  @Action(CardIssuance)
  public travelCardIssuance(ctx: StateContext<AccountsAgentKycStateModel>, {accountId, walletId, amount, cardType, transactionId, dryRun}: any): any {
    const state = ctx.getState();

    return this.nymcardWalletService.issueCard(accountId, walletId, transactionId, new NymcardCmsWalletTransactionCardIssuanceDetails({
      currency: cardType == 'dinar' ? 'iqd' : 'usd',
      amount: amount,
      description: `${cardType} card issuance`,
      cardProduct: cardType,
      dryRun: dryRun,
  }))
    .pipe(
      tap((internal) => {
        if (internal.status.toLowerCase() === 'declined') {
          ctx.setState({ ...state, cardState: false });        
          throw new Problem({
            title: internal.walletTransaction.statusReason,
            detail: internal.walletTransaction.statusReason,
            type: 'card_declined'
          });
        }
      }),
      tap((internal) => {
        if (internal.status.toLowerCase() !== 'declined') {
          ctx.setState({ ...state, cardState: true });

          if(!dryRun){
            this._nc.show(new Notification(`${cardType} card will be loaded shortly..`));
            this.lastCardId=internal.internalId;
            ctx.setState({ ...state, lastCardIdState: true });
          }
        }
      }),
      catchError((problem) => {
        ctx.dispatch(new FailFromApi(problem));
        ctx.setState({ ...state, cardState: false });
        if (problem.type === 'card_declined') {
          this._nc.show(new Notification(`Error occured due to ${problem.detail}`));
        }
        else if (problem.detail) {
          this._nc.show(new Notification(problem.detail));
        }
        else { this._nc.show(new Notification(`Error occured during ${cardType} card issuance`)); }
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ saving: false });
      }),
    );
  }

  @Action(UserKycApproval)
  public userKycApproval(ctx: StateContext<AccountsAgentKycStateModel>, {accountId, agentId, cardType}: any): any {
    const state = ctx.getState();
    // const agent = new AgentAccount({ ...state.agent.agentAccount});
    /*return this.usersService.approveKyc2(accountId, new KYC2ApprovalRequest({
      agentId: agentId,
    }))*/
    return this.usersService.submitForKyc2(accountId, new KYC2SubmitRequest({
      agentId: agentId,
      initialCardProduct : cardType
    }))
    .pipe(
      tap((internal) => {
        ctx.setState({ ...state, approveKycState: true });
        this._nc.show(new Notification('KYC2 is getting approved..'));
      }),
      catchError((problem) => {
        ctx.dispatch(new FailFromApi(problem));
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ saving: false });
      }),
    );
  }


  @Action(UserKyc3Approval)
  public userKyc3Approval(ctx: StateContext<AccountsAgentKycStateModel>, {accountId}: any): any {
    const state = ctx.getState();
    // const agent = new AgentAccount({ ...state.agent.agentAccount});
    /*return this.usersService.approveKyc2(accountId, new KYC2ApprovalRequest({
      agentId: agentId,
    }))*/
    return this.usersService.submitForKyc3(accountId)
    .pipe(
      tap((internal) => {
        ctx.setState({ ...state, approveKycState: true });
        this._nc.show(new Notification('KYC3 is getting approved..'));
      }),
      catchError((problem) => {
        ctx.dispatch(new FailFromApi(problem));
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ saving: false });
      }),
    );
  }

  @Action(SetupNewAgentForKycFromApi)
  public newKyc(ctx: StateContext<AccountsAgentKycStateModel>, { mobile }: any): any {
    const internal = new AgentAccountInternal({
      agentAccount: new AgentAccount({
        profile: new AgentProfile({ mobile: mobile }),
      }),
    });

    ctx.setState({ ...stateDefaults, agent: internal });
  }

  @Action(NavigateKycFromAgentCreationPage)
  public navigateKyc(ctx: StateContext<AccountsAgentKycStateModel>, { current }: any): any {
    const state = ctx.getState();
    if (!state.agent || !state.agent.agentAccount.profile) {
      current = 'personal';
    }

    ctx.setState({ ...state, flow: { ...state.flow, current: current } });
  }

  @Action(SaveProfileFromAgentCreationPage)
  public saveProfile(ctx: StateContext<AccountsAgentKycStateModel>, { profile }: any): any {
    const state = ctx.getState();
    const agent = new AgentAccount({ ...state.agent.agentAccount, profile: profile });

    return this._saveAgentAccount(ctx, agent, 'documents');
  }

  @Action(SaveDocumentsFromAgentCreationPage)
  public saveDocuments(ctx: StateContext<AccountsAgentKycStateModel>, { documents }: any): any {
    const state = ctx.getState();
    const agent = new AgentAccount({ ...state.agent.agentAccount, documents: documents });

    return this._saveAgentAccount(ctx, agent, 'address');
  }

  @Action(SaveAddressFromAgentCreationPage)
  public saveAddress(ctx: StateContext<AccountsAgentKycStateModel>, { address }: any): any {
    const state = ctx.getState();
    const agent = new AgentAccount({ ...state.agent.agentAccount, address: address });

    return this._saveAgentAccount(ctx, agent, 'business');
  }

  @Action(SaveBusinessFromAgentCreationPage)
  public saveBusiness(ctx: StateContext<AccountsAgentKycStateModel>, { business }: any): any {
    const state = ctx.getState();
    const agent = new AgentAccount({ ...state.agent.agentAccount, business: business });

    return this._saveAgentAccount(ctx, agent, 'submit');
  }

  @Action(SubmitKycFromAgentCreationPage)
  public submitKyc(ctx: StateContext<AccountsAgentKycStateModel>): any {
    const state = ctx.getState();
    const id = state.agent.id;

    ctx.patchState({ saving: true });
    return this._agentService.submitForKyc(id).pipe(

      switchMap(() => ctx.dispatch(new GetAccountFromApi(id))),
      switchMap(() => ctx.dispatch(new Navigate([`accounts/${id}/agent/details`]))),
      tap(() => ctx.setState(stateDefaults)),

      catchError((problem) => {
        return ctx.dispatch(new FailFromApi(problem));
      }),

      finalize(() => {
        window.location.reload();
        ctx.patchState({ saving: false });
      }),
    );
  }

  private _saveAgentAccount(
    ctx: StateContext<AccountsAgentKycStateModel>,
    agent: AgentAccount,
    next?: FlowStep,
  ): Observable<AgentAccountInternal> {
    const state = ctx.getState();
    const action = state.agent && state.agent.id
      ? this._agentService.updateAgentAccount(state.agent.id, agent)
      : this._agentService.createAgentAccount(agent);

    ctx.patchState({ saving: true });
    return action.pipe(
      tap((internal) => {
        const flow = this._checkFlow(internal, next);

        ctx.setState(produce((draft: AccountsAgentKycStateModel) => {
          draft.agent = internal;
          draft.flow = flow;
        }));
      }),

      catchError((problem) => {
        ctx.dispatch(new FailFromApi(problem));
        return EMPTY;
      }),

      finalize(() => {
        ctx.patchState({ saving: false });
      }),
    );
  }

  private _checkFlow(account: AgentAccountInternal, next?: FlowStep): AgentKycFlow {

    let flow: AgentKycFlow = new AgentKycFlow();

    flow.current = 'personal';

    if (account.agentAccount.profile) {
      flow.personal.completed = true;
      flow.current = 'documents';
    }

    if (account.agentAccount.documents) {
       flow.documents.completed = true;
       flow.current = 'address';
    }

    if (account.agentAccount.address) {
      flow.address.completed = true;
      flow.current = 'business';
    }

    if (account.agentAccount.business) {
      flow.business.completed = true;
      flow.current = 'submit';
    }

    if (next) {
      flow.current = next;
    }

    return flow;
  }
}
