// tslint:disable:no-bitwise

import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';

import {
  DomSanitizer,
} from '@angular/platform-browser';

import {
  Observable,
  Observer,
} from 'rxjs';

import {
  map,
  switchMap,
} from 'rxjs/operators';

import {
  ImageFile,
} from '../models/image-file.model';


const ROTATION = {
  1: '',
  3: 'rotate(180deg)',
  6: '', //'rotate(90deg)',
  8: '',//'rotate(270deg)',
};

export class SecureImageService {

  constructor(
    private _http: HttpClient,
    private _sanitizer: DomSanitizer,
  ) { }

  public get(url: string): Observable<ImageFile> {
    let contentType= url ? url.split(';')[0] : '' ;
    if(contentType && contentType.includes('data:video/webm'))contentType= 'data:video/webm'
    else contentType= 'data:image/JPEG'
    return this._objectURLToBlob(url,contentType);
  }

  private _objectURLToBlob(url: string,contentType:string): Observable<ImageFile> {
    return this._http.get(url, { headers: this._getAuthorizationHeaders(), responseType: 'blob' }).pipe(
      switchMap((content: Blob) => this._getImageOrientation(content)),
      switchMap((imageFile) => this._getImageBase64(imageFile,contentType)),
      switchMap((imageFile) => this._getImageRotation(imageFile)),
    );
  }

  private _getAuthorizationHeaders(): HttpHeaders {
    let headers = new HttpHeaders();

    const accessToken = JSON.parse(localStorage.getItem('auth.token')).accessToken;
    if (accessToken) {
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    return headers;
  }

  private _getImageOrientation(img: Blob): Observable<ImageFile> {
    return new Observable((observer: Observer<ImageFile>) => {

      const fileReader = new FileReader();

      fileReader.onload = function (event: any): void {
        const view = new DataView(event.target.result);

        if (view.getUint16(0, false) !== 0xFFD8) {
          observer.next(new ImageFile({ raw: fileReader.result, orientation: -2 }));
          observer.complete();
        }

        const length = view.byteLength;
        let offset = 2;

        while (offset < length) {
          let marker = view.getUint16(offset, false);
          offset += 2;

          if (marker === 0xFFE1) {
            if (view.getUint32(offset += 2, false) !== 0x45786966) {
              observer.next(new ImageFile({ raw: fileReader.result, orientation: -1 }));
              observer.complete();
            }

            const little = view.getUint16(offset += 6, false) === 0x4949;
            offset += view.getUint32(offset + 4, little);

            const tags = view.getUint16(offset, little);
            offset += 2;

            for (let i = 0; i < tags; i++)
              if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                observer.next(new ImageFile({ raw: fileReader.result, orientation: view.getUint16(offset + (i * 12) + 8, little) }));
                observer.complete();
              }

          } else if ((marker & 0xFF00) !== 0xFF00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }

        observer.next(new ImageFile({ raw: fileReader.result, orientation: -1 }));
        observer.complete();
      };

      fileReader.readAsArrayBuffer(img);
    });
  }

  private _getImageBase64(imageFile: ImageFile,contentType:string): Observable<ImageFile> {
    return new Observable((observer: Observer<ImageFile>) => {
      const bytes = new Uint8Array(imageFile.raw);

      let binary = '';
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
     //const base64 = `data:image/JPEG;base64,${window.btoa(binary)}`;
      const base64 = `${contentType};base64,${window.btoa(binary)}`;

      observer.next(new ImageFile({...imageFile, base64 }));
      observer.complete();
    });
  }

  private _getImageRotation(imageFile: ImageFile): Observable<ImageFile> {
    return new Observable((observer: Observer<ImageFile>) => {
      const rotation = this._sanitizer.bypassSecurityTrustStyle(
        'transform: ' + ROTATION[imageFile.orientation <= 1 ? 1 : imageFile.orientation],
      );

      observer.next(new ImageFile({...imageFile, rotation }));
      observer.complete();
    });
  }

}
