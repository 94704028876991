import {
    Pipe,
    PipeTransform,
} from '@angular/core';


@Pipe({ name: 'snakeCase' })
export class StringSnakeCasePipe implements PipeTransform {

    public transform(input: string, condition?: boolean): any {
        if (!input) return '--';

        if (condition) return '--';

        // Convert snake_case to space-separated words and capitalize the first letter of each word
        const result = input.split('_')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ');

        return result;
    }
}
