import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  Account,
  Attachment,
} from '@nymos/accounts/core';

import {
FileUploadRef, IdvDocuments
} from '@michel.freiha/ng-sdk';

import {
  ImageService,
} from '../../../services/image.service';

@Component({
  selector: 'nym-agent-idv-documents-info',
  templateUrl: './agent-idv-documents-info.component.html',
  styleUrls: ['./agent-idv-documents-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentIdvDocumentsInfoComponent implements OnInit {

  @HostBinding('class.nym-agent-documents-info')
  protected get classes(): boolean { return true; }

  @Output()
  protected viewAttachments: EventEmitter<any> = new EventEmitter();

  @Input()
  public account: Account;
  @Input()
  public idvDocument: IdvDocuments;

  protected imageBack: FileUploadRef;
  
  protected imageBackUrl: string;

  protected imageFront: FileUploadRef;
  protected imageFrontUrl: string;

 
  protected get attachments(): Attachment[] {
    return this.account && this.account.document && this.account.document.attachments || [];
  }

  protected get additionalAttachments(): Attachment[] {
    return this.account && this.account.additionalDocument && this.account.additionalDocument.attachments || [];
  }

  protected get urls(): string[] {
    return this.attachments.map((a) => a.url) || [];
  }

  protected get additionalUrls(): string[] {
    return this.additionalAttachments.map((a) => a.url) || [];
  }

  constructor(private _imageService: ImageService) { }

  public ngOnInit(): void {}

  protected viewAttachments$$(index: number): void {
    const attachments = this.attachments;
    this.viewAttachments.emit({ attachments, index });
  }

  protected viewAdditionalAttachments$$(index: number): void {
    const attachments = this.additionalAttachments;
    this.viewAttachments.emit({ attachments, index });
  }

}
