
import {
    NgModule,
} from '@angular/core';

import {
    CommonModule,
} from '@angular/common';

import {
    CreditCardPipe,
} from './pipes/creditcard-number.pipe';

import {
    MapToIterablePipe,
} from './pipes/map-to-iterable.pipe';

import {
    NameInitialsPipe,
} from './pipes/name-initials.pipe';

import {
    StringHyphenPipe,
} from './pipes/string-hyphen.pipe';

import {
    StringIntoPipe,
} from './pipes/string-into.pipe';

import {
    StringLastPipe,
} from './pipes/string-last.pipe';

import {
    StringTextCasePipe,
} from './pipes/string-text-case.pipe';

import {
    TimeAgoPipe,
} from './pipes/time-ago.pipe';
import { StringToWordPipe } from './pipes/string-to-word.pipe';
import { StringSnakeCasePipe } from './pipes/string-snake-case.pipe';


export const PIPES = [
    CreditCardPipe,
    NameInitialsPipe,
    MapToIterablePipe,
    StringHyphenPipe,
    StringIntoPipe,
    StringLastPipe,
    StringTextCasePipe,
    TimeAgoPipe,
    StringToWordPipe,
    StringSnakeCasePipe,
];


@NgModule({
    declarations: [
        ...PIPES,
    ],

    imports: [
        CommonModule,
    ],

    exports: [
        ...PIPES,
    ],
})
export class ThemePipesModule { }
