import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input, Output, EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';

import {
  CustomValidators,
} from 'ngx-custom-validators';

import { TitleCasePipe } from '@angular/common';

import {
  Subscription,
} from 'rxjs';

import * as i18nIsoCountries from 'i18n-iso-countries';

import {
  Store,
} from '@ngxs/store';

import {
  NewProvince,
} from '../../../../lib/core/models/new-province.model';

import {
  NewCity,
} from '../../../../lib/core/models/new-city.model';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  UserProfile,
  UserAddress,
  Problem,
  UserDocuments,
  ProofOfResidence,
  UserAccountUpdateRequest,
  NationalId,
  Passport,
  Visa,
  FileUploadRef,
  CertificateOfNationality,
  CivilStatus,
  ResidencyCard,
  CoreUploadsService,
  UserAccountDocumentType,
  IdvChecks
} from '@michel.freiha/ng-sdk';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import {
  Account, AccountAddress, Attachment,
  AccountDocument,
} from '@nymos/accounts/core';

import {
  IdvDocumentsDownload,
  IdvVerificationReports,
} from '@michel.freiha/ng-sdk';

import {
  ProblemHandler,
} from '@nymos/problems';

import {
  ImageService,
} from '../../../services/image.service';

import { FormControl } from '@angular/forms';
import { Dialog } from '@nymos/theme';

@Component({
  selector: 'nym-user-edit-details',
  templateUrl: './user-edit-details.component.html',
  styleUrls: ['./user-edit-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProblemHandler],
})
export class UserEditDetailsComponent implements OnChanges, OnDestroy {

  @HostBinding('class.nym-user-edit-details')
  protected get classes(): boolean { return true; }

  @Input()
  public account: Account;

  @Input()
  public problem: Problem;

  @Input()
  public idvChecks: IdvChecks[];

  @Output()
  protected provinceNewChange: EventEmitter<string> = new EventEmitter();

  @Input()
  public provincesnew: NewProvince[];

  public tempProvince: NewProvince;

  public tempCity: NewCity;

  @Input()
  public newcities: NewCity[];

  @Input()
  public countries: string[];

  @Input()
  public reportId: string;

  @Output('viewAttachments') // tslint:disable-line:no-output-rename
  protected viewAttachments: EventEmitter<any> = new EventEmitter();

  @Output('editUser') // tslint:disable-line:no-output-rename
  protected editUserData: EventEmitter<any> = new EventEmitter();

  protected get form(): FormGroup { return this._form; }
  public get valid(): boolean { return this._form.valid; }

  private _form: FormGroup;
  private _subscription: Subscription = new Subscription();
  protected image1: FileUploadRef;

  protected image2: FileUploadRef;
  protected image2Url: string;

  protected image1Url: string;
  protected selfie: FileUploadRef;
  protected selfieUrl: string;

  protected imageBack: FileUploadRef;
  protected imageBackUrl: string;

  protected imageFront: FileUploadRef;
  protected imageFrontUrl: string;

  protected imageAdd1: FileUploadRef;
  protected imageAdd1Url: string;

  protected imageAdd2: FileUploadRef;
  protected imageAdd2Url: string;

  protected type: string;
  protected type1: string;

  protected doc1Error: boolean;
  protected doc2Error: boolean;
  protected doc3Error: boolean;
  protected doc4Error: boolean;

  protected isIdvUser: boolean;

  public get district(): AbstractControl { return this.form.get('district'); }
  public get city(): AbstractControl { return this.form.get('city'); }
  public get street(): AbstractControl { return this.form.get('street'); }
  public get building(): AbstractControl { return this.form.get('building'); }
  public get province(): AbstractControl { return this.form.get('province'); }
  public get fullName(): AbstractControl { return this.form.get('fullName'); }
  public get motherName(): AbstractControl { return this.form.get('motherName'); }
  public get email(): AbstractControl { return this.form.get('email'); }
  public get dateOfBirth(): AbstractControl { return this.form.get('dateOfBirth'); }
  public get nationality(): AbstractControl { return this.form.get('nationality'); }
  public get gender(): AbstractControl { return this.form.get('gender'); }
  public get placeOfBirth(): AbstractControl { return this.form.get('placeOfBirth'); }
  public get mobile(): AbstractControl { return this.form.get('mobile'); }
  public get number(): AbstractControl { return this.form.get('number'); }
  public get authority(): AbstractControl { return this.form.get('authority'); }
  public get issuingCountry(): AbstractControl { return this.form.get('issuingCountry'); }
  public get dateOfIssue(): AbstractControl { return this.form.get('dateOfIssue'); }
  public get dateOfExpiry(): AbstractControl { return this.form.get('dateOfExpiry'); }
  public get motherFullName(): AbstractControl { return this.form.get('motherFullName'); }

  public get userAddress(): UserAddress {
    return new UserAddress({
      building: this.building.value,
      district: this.district.value,
      street: this.street.value,
      city: this.getCityKey(this.city.value),
      state: this.getProvinceKey(this.province.value),
      proofOfResidence: new ProofOfResidence({
        images: [this.image1, this.image2].filter((i) => i !== undefined),
      }),
    });
  }

  public get userProfile(): UserProfile {
    return new UserProfile({
      fullName: this.fullName.value,
      motherName: this.motherName.value,
      selfie: this.selfie,
      email: this.email.value,
      dateOfBirth: this.dateOfBirth.value,
      nationality: i18nIsoCountries.getAlpha2Code(this.nationality.value, 'en'),
      gender: this.gender.value,
      placeOfBirth: this.placeOfBirth.value,
      mobile: this.mobile.value
    });
  }

  public get userDocuments(): UserDocuments {

    let v: Visa = {};
    let r: ResidencyCard = {};

    if (this.nationality.value.toUpperCase() !== "IRAQ") {

      if (this.type1 === "visa") {

        v = {
          imageFront: this.imageAdd1,
          imageBack: this.imageAdd2
        }
        r = {};
      }

      if (this.type1 === "residencyCard") {

        r = {
          imageFront: this.imageAdd1,
          imageBack: this.imageAdd2
        }
        v = {};
      }
    }

    if (this.type === "nationalId") {

      const nId: NationalId = {
        number: this.number.value,
        authority: this.nationality.value.toUpperCase() === "IRAQ" ? this.authority.value : '',
        dateOfIssue: this.dateOfIssue.value,
        imageFront: this.imageFront,
        imageBack: this.imageBack
      }

      return new UserDocuments({
        nationalId: nId,
        last: UserAccountDocumentType.NationalId,
        visa: v,
        residencyCard: r
      });
    }
    else
      if (this.type === "passport") {

        const p: Passport = {
          number: this.number.value,
          authority: this.nationality.value.toUpperCase() === "IRAQ" ? this.authority.value : '',
          dateOfIssue: this.dateOfIssue.value,
          dateOfExpiry: this.dateOfExpiry.value,
          imageFront: this.imageFront,
          imageBack: this.imageBack,
          issuingCountry: this.nationality.value.toUpperCase() === "IRAQ" ? '' : i18nIsoCountries.getAlpha2Code(this.issuingCountry.value, 'en')
        }
        this.nationality.value.toUpperCase() === "IRAQ" ? delete p.issuingCountry : delete p.authority;
        return new UserDocuments({
          passport: p,
          last: UserAccountDocumentType.Passport,
          visa: v,
          residencyCard: r
        });
      }
      else
        if (this.type === "civilStatus") {

          const cts: CivilStatus = {
            number: this.number.value,
            authority: this.nationality.value.toUpperCase() === "IRAQ" ? this.authority.value : '',
            dateOfIssue: this.dateOfIssue.value,
            motherFullName: this.motherFullName.value,
            imageFront: this.imageFront,
            imageBack: this.imageBack
          }

          return new UserDocuments({
            civilStatus: cts,
            last: UserAccountDocumentType.CivilStatus,
            visa: v,
            residencyCard: r
          });
        }
        else
          if (this.type === "certificateOfNationality") {

            const cn: CertificateOfNationality = {
              number: this.number.value,
              authority: this.nationality.value.toUpperCase() === "IRAQ" ? this.authority.value : '',
              imageFront: this.imageFront,
              imageBack: this.imageBack
            }

            return new UserDocuments({
              certificateOfNationality: cn,
              last: UserAccountDocumentType.CertificateOfNationality,
              visa: v,
              residencyCard: r
            });
          }

  }


  protected uploadImage1$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImage1(ref)));
  }

  protected uploadSelfie$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setSelfieUrl(ref)));
  }

  protected uploadImage2$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImage2(ref)));
  }

  protected uploadImageBack$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImageBack(ref)));
  }

  protected uploadImageFront$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImageFront(ref)));
  }

  protected uploadImageAdd1$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImageAdd1(ref)));
  }

  protected uploadImageAdd2$$(file: File): void {
    if (!file)
      return;

    this._subscription.add(this._service.upload(this.account.id, file).subscribe((ref) => this._setImageAdd2(ref)));
  }

  protected clearImage1$$(): void {
    this.image1 = null;
  }

  protected clearSelfie$$(): void {
    this.selfie = null;
  }

  protected clearImage2$$(): void {
    this.image2 = null;
  }

  protected clearImageFront$$(): void {
    this.imageFront = null;
  }

  protected clearImageBack$$(): void {
    this.imageBack = null;
  }

  protected clearImageAdd1$$(): void {
    this.imageAdd1 = null;
  }

  protected clearImageAdd2$$(): void {
    this.imageAdd2 = null;
  }

  public provinceNewChange$$(provincenew: string): void {
    this.provinceNewChange.emit(provincenew);

    this.fixProvinceCase(provincenew);
  }

  protected get address(): AccountAddress { return this.account && this.account.address; }
  protected get document(): AccountDocument { return this.account && this.account.document; }
  protected get documents(): UserDocuments { return this.account && this.account.documents; }

  protected get attachments(): Attachment[] {
    return this.account && this.account.address && this.account.address.attachments || [];
  }

  protected get info(): any {
    return this.account && this.account.document && this.account.document.info;
  }

  protected get urls(): string[] {
    return this.attachments.map((a) => a.url) || [];
  }

  protected get profile(): UserProfile { return this.account && this.account.profile; }

  constructor(private _fb: FormBuilder,
    private titlecasePipe: TitleCasePipe,
    private _ph: ProblemHandler,
    private _cd: ChangeDetectorRef,
    private _service: CoreUploadsService,
    private _imageService: ImageService,
    private _store: Store,
    private _dialog: Dialog,
  ) {

    this._form = this._fb.group({
      province: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(16),
      ]],
      district: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Zء-ي0-9 ]*$/),
        Validators.minLength(1),
        Validators.maxLength(32),
      ]],
      city: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(32),
      ]],
      street: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(4),
        Validators.pattern(/^[0-9]+$/),
      ]],
      building: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(32),
        this.noWhitespaceValidator,
        Validators.pattern(/^(?=.*)[0-9_ !"\#$%&'()*+,\-./:;<=>?@\[\\\]^_‘{|}~]*$/)]],
      email: ['', [
        Validators.required,
        CustomValidators.email,
        Validators.maxLength(32)
      ]],
      dateOfBirth: ['', [
        Validators.required,
      ]],
      nationality: [],
      placeOfBirth: [],
      mobile: ['', [
        Validators.required,
        Validators.pattern(/^(\+964)?(7[3-9])[0-9]{8}$/),
      ]],
      fullName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
        Validators.pattern(/^[a-zA-Z ]*$/),
        this.noWhitespaceValidator,

      ]],
      motherName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32),
        Validators.pattern(/^[a-zA-Z ]*$/),
        this.noWhitespaceValidator,

      ]],
      gender: [],
      number: [''],
      authority: [''],
      issuingCountry: [''],
      dateOfIssue: [''],
      dateOfExpiry: [],
      motherFullName: [''],

    });

    this.doc1Error = false;
    this.doc2Error = false;
    this.doc3Error = false;
    this.doc4Error = false;

    const authority = this.authority;
    const issuingCountry = this.issuingCountry;
    const placeOfBirth = this.placeOfBirth;

    this._form.get('nationality').valueChanges
      .subscribe(nationality => {

        if (nationality.toUpperCase() === 'IRAQ') {
          authority.setValidators([Validators.required]);
          issuingCountry.setValidators(null);
          placeOfBirth.setValidators(null);
        }

        if (nationality.toUpperCase() !== 'IRAQ') {
          authority.setValidators(null);
          issuingCountry.setValidators([Validators.required]);
          placeOfBirth.setValidators([Validators.required,
          Validators.minLength(2),
          Validators.maxLength(32),
          this.noWhitespaceValidator,
          Validators.pattern(/^[a-zA-Zء-ي ]*$/)])
        }

        if (this.isIdvUser) {
          authority.setValidators(null);
          issuingCountry.setValidators(null);
        }
        authority.updateValueAndValidity();
        placeOfBirth.updateValueAndValidity();
        issuingCountry.updateValueAndValidity();
      });
  }

  noWhitespaceValidator(control: AbstractControl): any {
    var regex = /^(?!\s)(?!.*\s$)/;
    return !regex.test(control.value) ? { 'whitespace': true } : null;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this._form.controls['province'].valueChanges.subscribe(value => {
      this.provinceNewChange$$(value)
    });

    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    const accountChanges = changes['account'];

    if (this.problem) {
      this._ph.handle(this.problem, this.form);
    }

    if (accountChanges) {
      if (!accountChanges.currentValue.document) this.isIdvUser = true;
      
      this._setForm(accountChanges.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _setForm(account: Account): void {
    const address = account && account.address;
    const profile = account && account.profile;
    const doc = account && account.document;
    const docs = account && account.documents;

    if (address) {

      var arabic = /[\u0600-\u06FF]/;
      if (arabic.test(address.state)) {
        address.state = this.fixProvince(address.state);
      }
      else {
        address.state = this.fixProvinceCase(address.state);
      }

      if (arabic.test(address.city)) {
        address.city = this.fixCity(address.city);
      }
      else {
        address.city = this.fixCityCase(address.city);
      }

      this._form.patchValue({
        province: address.state || '',
        district: address.district || '',
        city: address.city || '',
        street: address.street || '',
        building: address.building || ''
      });
    }

    if (profile) {

      this._form.patchValue({
        fullName: profile.fullName || '',
        motherName: profile.motherName || '',
        email: profile.email || '',
        dateOfBirth: profile.dateOfBirth || '',
        nationality: i18nIsoCountries.getName(profile.nationality.toUpperCase(), 'en') || '',
        gender: profile.gender || '',
        placeOfBirth: profile.placeOfBirth || '',
        mobile: profile.mobile || ''
      });
    }
  
    if (doc) {
      this._form.patchValue({

        dateOfIssue: doc.info.dateOfIssue || '',
        dateOfExpiry: doc.info.dateOfExpiry || '',
        authority: doc.info.authority || '',
        number: doc.info.number || '',
        certificateOfNationality: doc.info.certificateOfNationality || '',
        visa: doc.info.visa || '',
        residencyCard: doc.info.residencyCard || '',
        motherFullName: doc.info.motherFullName || '',
        issuingCountry: i18nIsoCountries.getName(doc.info.issuingCountry, 'en') || ''
      });

      if (profile.nationality.toUpperCase() !== "IQ") {

        if (docs) {
          let i1: FileUploadRef;
          let i2: FileUploadRef;

          if (docs.residencyCard && docs.residencyCard.imageFront) {
            this.type1 = "residencyCard";
            i2 = this.documents.residencyCard.imageBack;
            i1 = this.documents.residencyCard.imageFront;
          }

          else
            if (docs.visa && docs.visa.imageFront) {
              this.type1 = "visa";
              i2 = this.documents.visa.imageBack;
              i1 = this.documents.visa.imageFront;
            }

          if (i1) {
            this._setImageAdd1(i1);
          }

          if (i2) {
            this._setImageAdd2(i2);
          }
        }

      }

      this.type = doc.type;
      this.changeType(this.type, 1);
      this.provinceNewChange$$(address.state);

      if (profile.nationality.toUpperCase() !== "IQ") {
        const index: number = this.countries.indexOf("Iraq");
        if (index !== -1) {
          this.countries.splice(index, 1);
        }
      }
    }

    const images1 = this.address.attachments;

    if (this.document) {
      const imageBack = this.document.info.imageBack;
      const imageFront = this.document.info.imageFront;

      if (imageBack) {
        this._setImageBack(imageBack);
      }

      if (imageFront) {
        this._setImageFront(imageFront);
      }
    }

    if (images1) {
      this._setImage1(images1[0]);
      this._setImage2(images1[1]);
    }

    if (this.profile) {
      const selfieImage = this.profile.selfie

      if (selfieImage) {
        this._setSelfieUrl(selfieImage[0])
      }
    }

  }

  private _setSelfieUrl(ref: any): void {
    if (!this.account || !ref)
      return;
    ref.fileName = ref.fileName ? ref.fileName : ref.name;
    this.selfie = ref;
    this.selfieUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  private _setImage1(ref: any): void {
    if (!this.account || !ref)
      return;
    ref.fileName = ref.fileName ? ref.fileName : ref.name;
    this.image1 = ref;
    this.image1Url = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  private changeType(a: string, flag: number): void {

    const number = this.number;
    const dateOfIssue = this.dateOfIssue;
    const motherFullName = this.motherFullName;
    const dateOfExpiry = this.dateOfExpiry;
    this.type = a;

    if (flag == 0) {
      this.form.patchValue({
        number: '',
        authority: '',
        issuingCountry: '',
        dateOfIssue: '',
        motherFullName: '',
        dateOfExpiry: ''
      });

      number.setValidators(null);
      dateOfIssue.setValidators(null);
      dateOfExpiry.setValidators(null);
      motherFullName.setValidators(null);
    }

    if (this.type === "nationalId") {

      number.setValidators([Validators.required,
      Validators.pattern(/^[0-9]{12}$/)]);
      dateOfIssue.setValidators([Validators.required]);
    }
    else
      if (this.type === "passport") {
        if (this.nationality.value.toUpperCase() === "IRAQ") {
          number.setValidators([Validators.required, Validators.pattern(/^[A,B,E,D]{1}[0-9]{7,8}$/)]);
        } else
          if (this.nationality.value.toUpperCase() !== "IRAQ") {
            number.setValidators([Validators.required, Validators.minLength(1),
            Validators.maxLength(32)]);
          }
        dateOfExpiry.setValidators([Validators.required]);
        dateOfIssue.setValidators([Validators.required]);
      }
      else
        if (this.type === "civilStatus") {
          number.setValidators([Validators.required,
          Validators.pattern(/^[0-9]{8}$/)]);
          motherFullName.setValidators([Validators.required,
          Validators.pattern(/^[a-zA-Zء-ي ]*$/),
          this.noWhitespaceValidator,
          Validators.minLength(2),
          Validators.maxLength(32)]);
          dateOfIssue.setValidators([Validators.required]);
        }
        else
          if (this.type === "certificateOfNationality") {
            number.setValidators([Validators.required]);
          }
          else
            if (this.type === "visa") {
              number.setValidators(null);
            }
            else
              if (this.type === "residencyCard") {
                number.setValidators(null);
              }
    number.updateValueAndValidity();
    motherFullName.updateValueAndValidity();
    dateOfExpiry.updateValueAndValidity();
    dateOfIssue.updateValueAndValidity();
  }

  private changeType1(a: string): void {
    this.type1 = a;
  }

  private _setImage2(ref: any): void {
    if (!this.account || !ref)
      return;
    ref.fileName = ref.fileName ? ref.fileName : ref.name;
    this.image2 = ref;
    this.image2Url = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  private _setImageBack(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.imageBack = ref;
    this.imageBackUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  private _setImageFront(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.imageFront = ref;
    this.imageFrontUrl = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  private _setImageAdd1(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.imageAdd1 = ref;
    this.imageAdd1Url = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  private _setImageAdd2(ref: FileUploadRef): void {
    if (!this.account || !ref)
      return;

    this.imageAdd2 = ref;
    this.imageAdd2Url = this._imageService.getImageUploadUrl(this.account.id, ref.id);
    this._cd.markForCheck();
  }

  protected editUser$$(): void {
    if (this.isIdvUser) {
      this.authority.setValidators(null);
      this.issuingCountry.setValidators(null);
    }

    if (!this.selfie && !this.isIdvUser) {
      this.doc4Error = true;
      return;
    }
    else {
      this.doc4Error = false;
    }

    if ((!this.imageFront || !this.imageBack) && !this.isIdvUser) {
      this.doc1Error = true;
      return;
    }
    else {
      this.doc1Error = false;
    }

    if (this.userProfile.nationality.toUpperCase() !== "IQ") {

      if ((!this.imageAdd1 || !this.imageAdd2) && !this.isIdvUser) {
        this.doc3Error = true;
        return;
      }
      else {
        this.doc3Error = false;
      }
    }
    else {
      if (!this.image1 || !this.image2) {
        this.doc2Error = true;
        return;
      }
      else {
        this.doc2Error = false;
      }

    }

    let ur: UserAccountUpdateRequest =
    {
      profile: this.userProfile,
      documents: !this.isIdvUser ? this.userDocuments : this.documents,
      address: this.userAddress
    }

    ur.profile.arabicName = this.account.profile.arabicName;

    if (this.userProfile.nationality.toUpperCase() === "IQ") {
      delete ur.profile.placeOfBirth;
      delete ur.documents.visa;
      delete ur.documents.residencyCard;
    }

    if (this.userProfile.nationality.toUpperCase() !== "IQ") {
      if (this.userDocuments && this.userDocuments.visa && this.userDocuments.visa.imageFront) {
        ur.documents.last = UserAccountDocumentType.Visa;
        delete ur.documents.residencyCard;
      }
      else {
        ur.documents.last = UserAccountDocumentType.ResidencyCard;
        delete ur.documents.visa;
      }
    }
    
    this.editUserData.emit(ur);
  }

  protected back(): void {
    this._store.dispatch(new Navigate(['accounts/' + this.account.id + '/user/details']));
  }

  protected viewImage1$$(): void {
    if (this.image1) {
      let attachments: Attachment[] = [{
        id: this.image1.id,
        name: this.image1.fileName,
        size: this.image1.size,
        contentType: this.image1.contentType,
        url: this.image1Url
      }];
      let index: number = 1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

  protected viewSelfie$$(): void {
    if (this.selfie) {
      let attachments: Attachment[] = [{
        id: this.selfie.id,
        name: this.selfie.fileName,
        size: this.selfie.size,
        contentType: this.selfie.contentType,
        url: this.selfieUrl
      }];
      let index: number = 1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

  protected viewImage2$$(): void {
    if (this.image2) {
      let attachments: Attachment[] = [{
        id: this.image2.id,
        name: this.image2.fileName,
        size: this.image2.size,
        contentType: this.image2.contentType,
        url: this.image2Url
      }];
      let index: number = 1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

  protected viewimageFront$$(): void {
    if (this.imageFront) {
      let attachments: Attachment[] = [{
        id: this.imageFront.id,
        name: this.imageFront.fileName,
        size: this.imageFront.size,
        contentType: this.imageFront.contentType,
        url: this.imageFrontUrl
      }];
      let index: number = 1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

  protected viewImageBack$$(): void {
    if (this.imageBack) {
      let attachments: Attachment[] = [{
        id: this.imageBack.id,
        name: this.imageBack.fileName,
        size: this.imageBack.size,
        contentType: this.imageBack.contentType,
        url: this.imageBackUrl
      }];
      let index: number = 1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

  protected viewImageAdd1$$(): void {
    if (this.imageAdd1) {
      let attachments: Attachment[] = [{
        id: this.imageAdd1.id,
        name: this.imageAdd1.fileName,
        size: this.imageAdd1.size,
        contentType: this.imageAdd1.contentType,
        url: this.imageAdd1Url
      }];
      let index: number = 1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

  protected viewImageAdd2$$(): void {
    if (this.imageAdd2) {
      let attachments: Attachment[] = [{
        id: this.imageAdd2.id,
        name: this.imageAdd2.fileName,
        size: this.imageAdd2.size,
        contentType: this.imageAdd2.contentType,
        url: this.imageAdd2Url
      }];
      let index: number = 1;
      this.viewAttachments.emit({ attachments, index });
    }
  }

  private fixProvinceCase(word: string): string {

    this.provincesnew.forEach(obj => {
      if (obj["key"] == word) {
        this.tempProvince = obj;
      }
    });

    if (this.tempProvince) {
      return this.tempProvince['en'];
    }
    else {
      return null;
    }
  }

  private fixCityCase(word: string): string {

    let cities = [];

    if (this.newcities != undefined) {
      cities = this.newcities;
    }
    else {
      cities = this.tempProvince.cities;
    }

    cities.forEach(obj => {

      if (obj.key == word) {
        this.tempCity = obj;
      }
    });

    if (this.tempCity) {
      return this.tempCity['en'];
    }
    else {
      return null;
    }
  }

  private fixProvince(word: string): string {

    this.provincesnew.forEach(obj => {
      if (obj["ar"] == word) {
        this.tempProvince = obj;
      }
    });

    if (this.tempProvince) {
      return this.tempProvince['en'];
    }
    else {
      return null;
    }
  }

  private fixCity(word: string): string {

    this.newcities.forEach(obj => {
      if (obj["ar"] == word) {
        this.tempCity = obj;
      }
    });

    if (this.tempCity) {
      return this.tempCity['en'];
    }
    else {
      return null;
    }
  }

  private getProvinceKey(word: string): string {

    this.provincesnew.forEach(obj => {
      if (obj["en"] == word) {
        this.tempProvince = obj;
      }
    });

    if (this.tempProvince) {
      return this.tempProvince['key'];
    }
    else {
      return null;
    }

  }

  private getCityKey(word: string): string {
    let h = '';

    this.newcities.forEach(obj => {
      if (obj["en"] == word) {
        this.tempCity = obj;
      }
    });

    if (this.tempCity) {
      return this.tempCity['key'];
    }
    else {
      return null;
    }
  }
}
