import {
    Params,
} from '@angular/router';

import {
    CoreNote,
    Problem,
    SearchCriteria,
} from '@michel.freiha/ng-sdk';


export class ActivateFromCardDetailsPage {
    public static readonly type: string = '[Card Details Page] Activate card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string) { }
}

export class ActivateFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Activate card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string) { }
}

export class ActivateFromCardSelectionPage {
    public static readonly type: string = '[Card Selection Page] Activate card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string) { }
}

export class SuspendFromCardDetailsPage {
    public static readonly type: string = '[Card Details Page] Suspend card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string,public note: CoreNote) { }
}

export class AllowInternationalTranscationFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Allow International Transaction';
    constructor(public accountId: string, public userId: string, public isAllowedInternationalTransaction: boolean) { }
}

export class GetInternationalTranscationStatusFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Get International Transaction Status';
    constructor(public accountId: string, public userId: string) { }
}

export class SuspendFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Suspend card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string,public note: CoreNote) { }
}

export class SuspendFromCardSelectionPage {
    public static readonly type: string = '[Card Selection Page] Suspend card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string,public note: CoreNote) { }
}

export class ResumeFromCardDetailsPage {
    public static readonly type: string = '[Card Details Page] Resume card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string,public note: CoreNote) { }
}

export class ResumeFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Resume card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string,public note: CoreNote) { }
}

export class ResumeFromCardSelectionPage {
    public static readonly type: string = '[Card Selection Page] Resume card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string,public note: CoreNote) { }
}

export class TerminateFromCardDetailsPage {
    public static readonly type: string = '[Card Details Page] Terminate card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string, public note: CoreNote) { }
}

export class TerminateFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Terminate card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string, public note: CoreNote) { }
}

export class TerminateFromCardSelectionPage {
    public static readonly type: string = '[Card Selection Page] Terminate card';
    constructor(public accountId: string, public cardId: string, public cardLast4: string, public note: CoreNote) { }
}

export class LoadCardsFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Load cards';
    constructor(public accountId: string) { }
}

export class LoadCardFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Load card';
    constructor(public accountId: string, public cardId: string) { }
}

export class RefreshCardsFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Refresh cards';
    constructor(public accountId: string) { }
}

export class SearchCardsFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Search cards';
    constructor(public criteria?: SearchCriteria) { }
}

export class LoadMoreCardsFromUserDetailsPage {
    public static readonly type: string = '[User Details Page] Load more cards';
    constructor(public criteria?: SearchCriteria) { }
}

export class SearchCardsFromCardSelectionPage {
    public static readonly type: string = '[Card Selection Page] Search cards';
    constructor(public criteria?: SearchCriteria) { }
}

export class LoadMoreCardsFromCardSelectionPage {
    public static readonly type: string = '[Card Selection Page] Load more cards';
    constructor(public criteria?: SearchCriteria) { }
}

export class FailFromApi {
    public static readonly type: string = '[Card API] Fail';
    constructor(public payload: { problem: Problem }) { }
}
